import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

enum Languages {
    DE = 'de',
    EN = 'en'
}

i18n.use(Backend)
    .use(LanguageDetector)
    .init({
        fallbackLng: Languages.EN,
        whitelist: Object.values(Languages),
        debug: true,
        interpolation: {
            escapeValue: false
        },
        react: {
            wait: true,
            useSuspense: false
        }
    })
    .catch(() => console.error('Failed to initialize i18n.'));

const getCurrentLanguage = () => i18n.language || window.localStorage.i18nextLng || '';

export {i18n, getCurrentLanguage, Languages};
