import React from 'react';
import {withStyles, Button, Typography, List, ListItem, ListItemIcon, ListItemText, Theme} from '@material-ui/core';
import {Property} from 'csstype';
import {getSecuredBlob} from '../../libs/api/user/user';
import {IFile} from '../../store/claimants/claimants.types';
import {IAuthState} from '../../store/auth/auth.types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import OpenInNew from '@material-ui/icons/OpenInNew';
import Attachment from '@material-ui/icons/Attachment';
// import {saveAs} from 'file-saver';
import {withTranslation as withNamespaces, WithTranslation as WithNamespaces} from 'react-i18next';

const styles = (theme: Theme) => ({
    root: {
        color: theme.palette.error.contrastText,
        backgroundColor: theme.palette.error.dark,
        '&:hover': {
            backgroundColor: theme.palette.error.light
        }
    },
    icon: {
        position: 'absolute' as Property.Position,
        top: 52,
        right: '8%',
        zIndex: 1000
    }
});

export interface IFileProps extends WithNamespaces {
    classes: {
        root: string;
        icon: string;
    };
    file: IFile;
    folder: string;
    auth: IAuthState;
    onDelete?(): void;
}

export interface IFileState {
    open: boolean;
    armed: boolean;
    fileBlob: any;
    error: string | null;
}

class FileComponent extends React.Component<IFileProps, IFileState> {
    state = {
        open: false,
        armed: false,
        fileBlob: null,
        error: null
    };

    componentDidMount = () => {
        this.loadFile().catch(() => this.setState({error: this.props.t('FileComponent.loadFileFailed').toString()}));
    };

    async loadFile() {
        const {file, folder, t} = this.props;

        try {
            if (file) {
                const blob = await getSecuredBlob(`${folder}/${file.fileNameHashed}`, file.mimeType, this.props.auth.token);

                if (!blob) {
                    throw new Error(t('FileComponent.loadFileFailed').toString());
                }

                this.setState({fileBlob: blob});
            }
        } catch (error) {
            // @TODO: implement error handling
            console.log(error);
            throw error;
        }
    }

    handleClickOpen = () => {
        this.setState({open: true});
    };

    handleClose = () => {
        this.setState({open: false, armed: false});
    };

    handleDelete = () => {
        const {armed} = this.state;
        const {onDelete} = this.props;

        // tslint:disable-next-line: early-exit
        if (!armed) {
            this.setState({armed: true});
        } else {
            if (onDelete) {
                onDelete();
            }
            this.setState({open: false, armed: false});
        }
    };

    renderContent() {
        const {error, fileBlob} = this.state;
        const {file, t} = this.props;

        if (error) {
            return <Typography color="error">{error}</Typography>;
        }

        if (fileBlob) {
            let content = <Attachment />;

            const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
            const imageWidth = (screenWidth / 100) * 80;

            if (file.mimeType === 'application/pdf') {
                content = (
                    <object width="100%" height="100%" type="application/pdf" data={`${URL.createObjectURL(fileBlob)}`}>
                        <p>{t('FileComponent.pdfDisplayError')}</p>
                    </object>
                );
            }

            if (
                file.mimeType === 'image/png' ||
                file.mimeType === 'image/jpg' ||
                file.mimeType === 'image/jpeg' ||
                file.mimeType === 'image/gif' ||
                file.mimeType === 'image/tiff'
            ) {
                content = (
                    <img width={imageWidth} style={{marginLeft: 'auto', marginRight: 'auto', display: 'block'}} src={URL.createObjectURL(fileBlob)} alt="" />
                );
            }

            // saveAs(blob, files[0].fileNameOriginal);
            // window.open(`${claimant.id}/${claimant.powerOfAttorneyLetter.fileNameHashed}`, '_blank');

            return content;
        }

        return <Typography>{t('FileComponent.loading')}</Typography>;
    }

    render() {
        const {open, armed} = this.state;
        const {file, classes, t, onDelete} = this.props;

        return (
            <div>
                <List component="nav" aria-label="item">
                    <ListItem button onClick={this.handleClickOpen}>
                        <ListItemIcon>
                            <OpenInNew />
                        </ListItemIcon>
                        <ListItemText primary={file.fileNameOriginal} />
                    </ListItem>
                </List>

                <Dialog fullScreen open={open} onClose={this.handleClose} aria-labelledby="responsive-dialog-title">
                    <DialogTitle id="responsive-dialog-title">{t('FileComponent.preview')}</DialogTitle>
                    <DialogContent>{this.renderContent()}</DialogContent>
                    <DialogActions>
                        {onDelete ? (
                            <Button className={classes.root} onClick={this.handleDelete} color="secondary">
                                {armed ? t('FileComponent.confirmDeletion') : t('FileComponent.delete')}
                            </Button>
                        ) : null}
                        <Button onClick={this.handleClose} color="primary">
                            {t('FileComponent.close')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

const StyledFileComponent = withStyles(styles)(FileComponent);
const I18nFileComponent = withNamespaces()(StyledFileComponent);

export const File = I18nFileComponent;
