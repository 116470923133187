import React, {Component} from 'react';
import {Helmet} from 'react-helmet';
import {Formik, Form, Field, FormikProps, FormikValues} from 'formik';
import * as Yup from 'yup';
import {AxiosError} from 'axios';
import {formikSubmitHandler} from '../../utils';
import {Dispatch} from 'redux';
import {connect} from 'react-redux';
import {authenticated as authenticatedAction} from '../../store/auth/auth.actions';
import {login} from '../../libs/auth';
import {Theme} from '@material-ui/core/styles/createMuiTheme';
import {withStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import {Link} from '../../components/Link';
import {TextField} from '../../components/TextField';
import {Grid} from '@material-ui/core';
import {withTranslation as withNamespaces, WithTranslation as WithNamespaces} from 'react-i18next';
import getConfig from '../../config';
import CardMedia from '@material-ui/core/CardMedia';
import logo from '../../images/logo-cut.png';
import {getCurrentLanguage} from '../../i18n';

const styles = (theme: Theme) => ({
    card: {
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: '500px',
        [theme.breakpoints.up('sm')]: {
            marginTop: '5vh'
        }
    },
    media: {
        height: 80,
        backgroundSize: 'auto 50px',
        marginTop: 20
    },
    textField: {
        width: '100%'
    },
    linkContainer: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    cardActionLink: {
        display: 'block',
        marginTop: '7px',
        marginBottom: '8px'
    },
    announcement: {
        color: theme.palette.primary.dark,
        marginBottom: 10
    }
});

export interface ILoginComponentProps extends WithNamespaces {
    classes: {
        card: string;
        media: string;
        textField: string;
        linkContainer: string;
        cardActionLink: string;
        announcement: string;
    };
    authenticated(token: string, expiresAt: number, loadUserProperties?: boolean): void;
}

export interface ILoginComponentState {
    error: string | null;
}
interface ILoginFormValues {
    username: string;
    password: string;
}

class LoginComponent extends Component<ILoginComponentProps, ILoginComponentState> {
    state = {
        error: null
    };

    validationSchema = Yup.object().shape({
        username: Yup.string()
            .email(this.props.t('LoginComponent.validation.email').toString())
            .required(this.props.t('LoginComponent.validation.required').toString()),
        password: Yup.string().required(this.props.t('LoginComponent.validation.password').toString())
    });

    onSubmit = async (values: FormikValues) => {
        const {authenticated} = this.props;
        const {token, expiresAt} = await login(values.username, values.password);
        authenticated(token, expiresAt, true);
    };

    onSubmitFailedWithBadRequest = (error: AxiosError) => {
        if (error.response && error.response.data && error.response.data.message) {
            return this.setState({
                error: error.response.data.message
            });
        }
        return this.setState({
            error: this.props.t('LoginComponent.error').toString()
        });
    };

    renderForm = (props: FormikProps<ILoginFormValues>) => {
        const {classes, t} = this.props;
        const {error} = this.state;
        const link = getCurrentLanguage() === 'en' ? 'https://www.envion-konkurs.ch/en/' : 'https://www.envion-konkurs.ch';
        const linkText = getCurrentLanguage() === 'en' ? 'www.envion-konkurs.ch/en/' : 'www.envion-konkurs.ch';

        return (
            <Form>
                <Card className={classes.card}>
                    <CardMedia className={classes.media} image={logo} />
                    <CardHeader title={t('LoginComponent.title')} titleTypographyProps={{align: 'center'}} />
                    <CardContent>
                        {!getConfig.APP_READONLY_MODE ? (
                            <Typography align="center" variant="body2">
                                {t('LoginComponent.info')}
                            </Typography>
                        ) : null}

                        {error ? <Typography color="error">{error}</Typography> : null}

                        <Field
                            name="username"
                            label={t('LoginComponent.username')}
                            type="email"
                            className={classes.textField}
                            margin="normal"
                            component={TextField}
                        />
                        <Field
                            name="password"
                            label={t('LoginComponent.password')}
                            type="password"
                            className={classes.textField}
                            margin="normal"
                            component={TextField}
                        />

                        <div className={classes.linkContainer}>
                            <Link to="/forgot-password">{t('LoginComponent.forgotPassword')}</Link>
                        </div>

                        <div style={{marginTop: 20}}>
                            <Typography variant="body2" style={{marginBottom: 10}}>
                                {t('Maintenance.p1')}
                            </Typography>
                            {/* <Typography variant="body2" style={{marginBottom: 10}}>
                                <span>{t('Maintenance.p2')} </span>
                                <a rel="noopener noreferrer" target="_blank" href={link}>
                                    {linkText}
                                </a>
                            </Typography> */}
                            {/* <Typography variant="body2" style={{marginBottom: 10}}>
                                {t('Maintenance.p3')} <b>{t('Maintenance.p4')}</b> {t('Maintenance.p5')}
                            </Typography>
                            <Typography variant="body2">
                                <b>{t('Maintenance.p6')}</b>
                            </Typography> */}

                            {getConfig.APP_READONLY_MODE && !getConfig.APP_PORTAL_CLOSED ? (
                                <Typography variant="body2">
                                    <b>
                                        {/* tslint:disable-next-line: react-no-dangerous-html */}
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: t('ReadonlyMode.announcement', {
                                                    mail:
                                                        '<a target="_blank" href="mailto:envion.konkurs@wenger-plattner.ch">envion.konkurs@wenger-plattner.ch</a>',
                                                    interpolation: {escapeValue: false}
                                                })
                                            }}
                                        />
                                    </b>
                                </Typography>
                            ) : null}
                        </div>

                        {getConfig.APP_PORTAL_CLOSED ? (
                            <div style={{marginTop: 20}}>
                                <Typography variant="body2" className={classes.announcement}>
                                    {/* {t('AnnouncementPortalClosed.p1')} <b>{t('AnnouncementPortalClosed.p2')}</b> {t('AnnouncementPortalClosed.p3')} */}
                                    {/* <br />
                                    <br />
                                    {t('AnnouncementPortalClosed.p4')}{' '}
                                    <br />
                                    <br /> */}
                                    {/* tslint:disable-next-line: react-no-dangerous-html */}
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: t('AnnouncementPortalClosed.p5', {
                                                mail:
                                                    '<a target="_blank" href="mailto:envion.konkurs@wenger-plattner.ch">envion.konkurs@wenger-plattner.ch</a>',
                                                interpolation: {escapeValue: false}
                                            })
                                        }}
                                    />
                                    <br />
                                    <br />
                                    {t('AnnouncementPortalClosed.p6')}{' '}
                                    <a rel="noopener noreferrer" target="_blank" href={link}>
                                        {linkText}.
                                    </a>
                                </Typography>
                            </div>
                        ) : (
                            <div style={{marginTop: 20}}>
                                <Typography variant="body2" className={classes.announcement}>
                                    {t('Announcement.p1')} <b>{t('Announcement.p2')}</b> {t('Announcement.p3')}
                                    <br />
                                    <br />
                                    {t('Announcement.p4')}
                                    <br />
                                    <br />
                                    <u>
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: t('Announcement.p5', {
                                                    mail: '<a target="_blank" href="mailto:envion.konkurs@wenger-plattner.ch">envion.konkurs@wenger-plattner.ch</a>',
                                                    interpolation: {escapeValue: false}
                                                })
                                            }}
                                        />                                    
                                    </u> {t('Announcement.p6')}
                                    <br />
                                    <br />
                                    {t('Announcement.p7')}{' '}
                                    <a rel="noopener noreferrer" target="_blank" href={link}>
                                        {linkText}.
                                    </a>
                                </Typography>
                            </div>
                        )}
                    </CardContent>
                    <CardActions>
                        <Grid container direction="row-reverse" justify="space-between" alignItems="flex-end">
                            <Grid item>
                                <Button type="submit" variant="contained" color="primary" disabled={props.isSubmitting}>
                                    {props.isSubmitting ? t('LoginComponent.sending') : t('LoginComponent.login')}
                                </Button>
                            </Grid>

                            {!getConfig.APP_READONLY_MODE ? (
                                <Grid item>
                                    <Link to="/register" className={classes.cardActionLink}>
                                        {t('LoginComponent.createAccount')}
                                    </Link>
                                </Grid>
                            ) : null}
                        </Grid>
                    </CardActions>
                </Card>
            </Form>
        );
    };

    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>{this.props.t('LoginComponent.title')}</title>
                </Helmet>
                <Formik
                    initialValues={{username: '', password: ''}}
                    validationSchema={this.validationSchema}
                    onSubmit={formikSubmitHandler(this.onSubmit, {forbidden: this.onSubmitFailedWithBadRequest})}
                >
                    {this.renderForm}
                </Formik>
            </React.Fragment>
        );
    }
}

const StyledLoginComponent = withStyles(styles)(LoginComponent);
const I18nLoginComponent = withNamespaces()(StyledLoginComponent);

const mapDispatchToProps = (dispatch: Dispatch) => ({
    authenticated: (token: string, expiresAt: number, loadUserProperties?: boolean) => dispatch(authenticatedAction(token, expiresAt, loadUserProperties))
});

const Login = connect(null, mapDispatchToProps)(I18nLoginComponent);

export {Login};
