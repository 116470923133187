import {Languages} from '../../../i18n';
import passport from './passport.png';

export const claimantPassportData = (lang: string) => {
    if (lang === Languages.DE) {
        return [
            [
                'Wie kann ich meinen Reisepass oder meine Identitätskarte bzw. die entsprechenden Dokumente meines Mandanten hochladen?',
                `
                <p>Sie müssen eine Kopie Ihres bzw. den Reisepass oder Identitätskarte Ihres Mandanten hochladen. Wenn der Personalausweis Informationen auf der Rückseite enthält, laden Sie bitte Vorder- und Rückseite hoch. Wenn der Reisepass nur eine Seite hat, scannen und laden Sie bitte nur die Seite mit dem Code auf der Unterseite hoch. Stellen Sie sicher, dass alle Dateien gut lesbar sind. Verwenden Sie Ihre Digitalkamera, Ihre Handy Kamera oder Ihren Scanner, um eine elektronische Kopie des Reisepasses oder Personalausweises zu erstellen. Stellen Sie sicher, dass alle Details des Passes lesbar und nicht verschwommen oder verdeckt sind (ein Beispiel für einen akzeptablen Scan finden Sie unten). Des weiteren müssen Sie das Format der Bilder und die Dateigrösse überprüfen.</p>
                <p><img width="784" src="${passport}" /></p>
                `
            ],
            [
                'Welches Format wird unterstützt?',
                'Bitte laden Sie Ihr Dokument als PNG oder in Form eines JPG oder PDF mit einer maximalen Größe von 3MB hoch.'
            ],
            [
                'Ich habe versehentlich das falsche Dokument hochgeladen, was soll ich tun?',
                'Nachdem Sie Ihr Dokument hochgeladen haben, sehen Sie daneben ein "Mülleimer"-Symbol. Drücken Sie dieses und das Dokument wird gelöscht. Wenn Sie bereits auf "Weiter" geklickt haben, können Sie mit der Schaltfläche "Zurück" zu der Seite zurückkehren, auf der Sie ein bestimmtes Dokument ersetzen/löschen möchten. Das Dokument wird in der oberen Zeile angezeigt. Klicken Sie auf das Dokument, um die Dokumentenvorschau zu öffnen. Anschließend können Sie das Dokument löschen, indem Sie auf die Schaltfläche "Löschen", unten rechts klicken.'
            ],
            [
                'Wie viele Dokumente kann ich hochladen?',
                'Sie können in diesem Abschnitt nur zwei Dokumente hochladen, daher müssen Sie sicherstellen, dass alle erforderlichen Informationen in diesen beiden Hochladevorgängen erfasst werden.'
            ],
            [
                'Mein Weiter/Zurück Button hat sich grau verfärbt und ich kann den Prozess nicht fortsetzen',
                'Wenn Ihre Dateinamen oder Ihr eingegebener Text die vorgegebene Zeichenanzahl überschreitet kann es dazu kommen, dass sie den Prozess nicht fortführen können. In diesem Fall bitten wir Sie darum ihre Dateinamen sowie Texte zu kürzen und diese erneut im Portal hochzuladen.'
            ],
            [
                'Ich habe Probleme mit dem Portal, was kann ich von meiner Seite aus machen?',
                'Bitte stellen Sie sicher, dass Sie Ihren Browser-Cache geleert haben, bevor Sie das Portal nutzen.'
            ]
        ];
    }

    return [
        [
            'How can I upload my passport or identity card or the corresponding documents of my client(s)?',
            `
            <p>
                You will need to upload a copy of your passport or identity card, as well as those of your client(s).
                If the Identity card has information on the backside, please upload each side.
                If the passport has only one side please scan and upload only the side with the machine code on the bottom.
                Ensure it is clearly readable. Use your digital camera, phone camera or scanner to make an electronic copy
                of the passport or identity card. Ensure that all the details of the passport are legible and are not
                blurred or obscured (an example  of an acceptable scan is below). You must also ensure that you check the
                format of your pictures, for example some mobile phones take high definition pictures, and you will have
                to convert the image to a supported format and size (see next question) before uploading it.
            </p>
            <p><img width="784" src="${passport}" /></p>
            `
        ],
        ['Which format will be supported?', 'Please upload your document in the form of either PNG, JPG or PDF with a maximum size of 3MB.'],
        [
            'I have accidentally uploaded the wrong document, what should I do?',
            'After you have uploaded your document you will see a “bin” icon next to it, press that and the document will be deleted. If you have already clicked “next”, you can use the “back” button to go back to the page where you would like to replace/delete a particular document. The document will appear in the upper line. Click it to open the document viewer, and you can then delete the document by pressing the “delete” button on the bottom right.'
        ],
        [
            'How many documents can I upload?',
            'You can upload multiple documents in this section. Please select all documents at once after you click the upload button.'
        ],
        [
            'My Next/Back button has turned gray and I cannot continue the process',
            'If your file name or text exceeds the specified number of characters, you may not be able to continue the process. In this case we ask you to reduce your file names and texts and upload them to the portal again.'
        ],
        ['I have issues with the portal, what can I do from my side?', 'Please insure you cleared your browser cache before using the portal.']
    ];
};
