import {Reducer, AnyAction} from 'redux';
import {ISnackBarState, SnackBarActionTypes} from './snackbar.types';

const initialState: ISnackBarState = {
    message: null,
    variant: null
};

const snack = (state: ISnackBarState, action: AnyAction): ISnackBarState => {
    switch (action.type) {
        case SnackBarActionTypes.SHOW:
            return action.payload.payload;
        case SnackBarActionTypes.HIDE:
            return initialState;
        default:
            return state;
    }
};

export const snackbarReducer: Reducer<ISnackBarState> = (state = initialState, action) => snack(state, action);
