import React from 'react';
import {Dispatch} from 'redux';
import {connect} from 'react-redux';
import {
    withStyles,
    Grid,
    Card,
    CardHeader,
    CardContent,
    Button,
    IconButton,
    Typography,
    CardActions,
    TableRow,
    Table,
    TableCell,
    TableBody,
    TableHead
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import {Edit} from '@material-ui/icons';
import {ClaimsDialog} from './ClaimsDialog';
import {RootState} from '../../../store';
import {IAccountState, AppRoles} from '../../../store/account/account.types';
import {updateClaimant as updateClaimantAction, selectClaimantId as selectClaimantIdAction} from '../../../store/claimants/claimants.actions';
import {IClaimantsState, ClaimantStatus, IClaimant} from '../../../store/claimants/claimants.types';
import {IClaimsState} from '../../../store/claims/claims.types';
import {
    selectClaimId as selectClaimIdAction,
    deleteClaimById as deleteClaimByIdAction,
    getClaims as getClaimsAction
} from '../../../store/claims/claims.actions';
import {IAuthState} from '../../../store/auth/auth.types';
import {deleteClaim, patchClaimant} from '../../../libs/api/user/user';
import {styles, IClassesProperty} from '../styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Info from '@material-ui/icons/Info';
import Helmet from 'react-helmet';
import {withTranslation as withNamespaces, WithTranslation as WithNamespaces} from 'react-i18next';
import {setFaq as setFaqAction} from '../../../store/faq/faq.actions';
import {IFaqState} from '../../../store/faq/faq.types';
import {showSnackBar as showSnackBarAction} from '../../../store/snackbar/snackbar.actions';
import {ISnackBarState} from '../../../store/snackbar/snackbar.types';

export interface IClaimsStepProps extends WithNamespaces {
    classes: IClassesProperty;
    auth: IAuthState;
    claimants: IClaimantsState;
    claims: IClaimsState;
    account: IAccountState;
    updateClaimant(claimant: IClaimant): void;
    selectClaimantId(claimantId: string | null): void;
    getClaims(claimantId: string): void;
    selectClaimId(claimId: string): void;
    deleteClaimByID(claimId: string): void;
    setFaq(type: IFaqState): void;
    showSnackBar(payload: ISnackBarState): void;
}

class ClaimsStepComponent extends React.Component<IClaimsStepProps> {
    _isMounted = false;

    state = {
        submitting: false,
        order: 'desc',
        orderBy: '',
        selected: [],
        rowsPerPage: 5,
        page: 0,
        dialogOpen: false,
        claimFormValues: {
            id: '',
            claimantId: '',
            tokenPurchaseLocation: '',
            basisOfClaims: '',
            currency: '',
            amount: 0,
            amountChf: 0,
            comments: ''
        }
    };

    componentDidMount() {
        this._isMounted = true;

        const {getClaims} = this.props;
        const claimant = this.getClaimant();
        const {setFaq} = this.props;

        setFaq({type: ClaimantStatus.CLAIMS});
        this.setState({claimFormValues: this.getNewValuesForClaim()});
        getClaims(claimant.id);
    }

    componentWillUnmount = () => {
        this._isMounted = false;
    };

    setSubmitting = (submitting = true) => {
        if (this._isMounted) {
            this.setState({submitting});
        }
    };

    getRole = () => {
        const {account, t} = this.props;

        if (!account.properties) {
            throw new Error(t('Roles.invalid').toString());
        }

        return account.properties.role;
    };

    showError = (error: string) => {
        this.props.showSnackBar({message: error, variant: 'error'});
    };

    handleNextClick = async () => {
        const {auth, updateClaimant, t} = this.props;

        try {
            const role = this.getRole();

            const _values = {
                status: ClaimantStatus.SIGN_CLAIMS_FORM
            };

            const claimant = this.getClaimant();

            this.setSubmitting();

            if (role === AppRoles.CLAIMANT_CLAIMANT) {
                const updatedClaimant = await patchClaimant(_values, 'claims', null, auth.token);
                updateClaimant(updatedClaimant);
            } else if (role === AppRoles.CLAIMANT_LEGAL_REPRESENTATIVE) {
                const updatedClaimant = await patchClaimant(_values, 'claims', claimant.id, auth.token);
                updateClaimant(updatedClaimant);
            }
            this.setSubmitting(false);
        } catch (error) {
            console.error(error);
            this.showError(t('Form.failed').toString());
        }
    };

    handleBackClick = async () => {
        const {auth, updateClaimant, t} = this.props;

        try {
            const role = this.getRole();

            const _values = {
                status: ClaimantStatus.MEETING_OF_CLAIMANTS
            };

            const claimant = this.getClaimant();

            this.setSubmitting();

            if (role === AppRoles.CLAIMANT_CLAIMANT) {
                const updatedClaimant = await patchClaimant(_values, 'claims', null, auth.token);
                updateClaimant(updatedClaimant);
            } else if (role === AppRoles.CLAIMANT_LEGAL_REPRESENTATIVE) {
                const updatedClaimant = await patchClaimant(_values, 'claims', claimant.id, auth.token);
                updateClaimant(updatedClaimant);
            }
            this.setSubmitting(false);
        } catch (error) {
            console.error(error);
            this.showError(t('Form.failed').toString());
        }
    };

    handleNewClaim = () => {
        this.setState({claimFormValues: this.getNewValuesForClaim()});
        this.handleClaimOpen();
    };

    handleEditClick = (claimId: string) => {
        this.setState({claimFormValues: this.getLoadedValuesForClaim(claimId)});
        this.handleClaimOpen();
    };

    handleClaimOpen = () => {
        this.setState({dialogOpen: true});
    };

    handleClaimClose = () => {
        const {getClaims} = this.props;
        const claimant = this.getClaimant();

        getClaims(claimant.id);

        this.setState({dialogOpen: false, claimFormValues: this.getNewValuesForClaim()});
    };

    getClaimant = () => {
        const {claimants} = this.props;
        return claimants.selectedClaimantId ? claimants.allClaimants[claimants.selectedClaimantId] : claimants.allClaimants[claimants.byId[0]];
    };

    handleDeleteClick = async (claimId: string) => {
        const {auth, deleteClaimByID, updateClaimant, getClaims, t} = this.props;

        try {
            const role = this.getRole();
            const claimant = this.getClaimant();

            // tslint:disable-next-line: early-exit
            if (claimId !== '') {
                await deleteClaim(claimId, auth.token);
                deleteClaimByID(claimId);
                getClaims(claimant.id);

                const _values = {
                    status: ClaimantStatus.CLAIMS
                };

                if (role === AppRoles.CLAIMANT_CLAIMANT) {
                    const updatedClaimant = await patchClaimant(_values, 'claims', null, auth.token);
                    updateClaimant(updatedClaimant);
                } else if (role === AppRoles.CLAIMANT_LEGAL_REPRESENTATIVE) {
                    const updatedClaimant = await patchClaimant(_values, 'claims', claimant.id, auth.token);
                    updateClaimant(updatedClaimant);
                }
            } else {
                console.error('invalid claim delete action');
            }
        } catch (error) {
            console.error(error);
            this.showError(t('Form.failed').toString());
        }
    };

    getLoadedValuesForClaim = (claimId: string) => {
        const {claims} = this.props;
        const claim = claims.allClaims[claimId];

        return {
            id: claim.id,
            claimantId: claim.claimantId,
            tokenPurchaseLocation: claim.tokenPurchaseLocation,
            basisOfClaims: claim.basisOfClaims,
            currency: claim.currency,
            amount: claim.amount,
            amountChf: claim.amountChf,
            comments: claim.comments
        };
    };

    getNewValuesForClaim = () => {
        const claimant = this.getClaimant();

        return {
            claimantId: claimant.id,
            tokenPurchaseLocation: '',
            basisOfClaims: '',
            currency: '',
            amount: 0,
            amountChf: 0,
            comments: ''
        };
    };

    mapPurchaseLocation = (key: string) => {
        const {t} = this.props;

        const mapping = {
            ico: t('Claims.item.ico'),
            secondaryMarket: t('Claims.item.secondaryMarket'),
            privatePerson: t('Claims.item.privatePerson'),
            presaleOrPrivatePlacement: t('Claims.item.presaleOrPrivatePlacement'),
            founders: t('Claims.item.founders'),
            multi: t('Claims.item.multi'),
            interest: t('Claims.item.interest'),
            other: t('Claims.item.other')
        } as any;

        return mapping[key];
    };

    render() {
        const {classes, claims, t} = this.props;
        const role = this.getRole();

        return (
            <React.Fragment>
                <Helmet>
                    <title>{t('Claims.title')}</title>
                </Helmet>

                <Card className={classes.card}>
                    <CardHeader
                        classes={{
                            root: classes.cardHeader,
                            title: classes.cardHeaderTitle
                        }}
                        title={t('Claims.title')}
                        style={{textAlign: 'center'}}
                    />

                    <CardContent>
                        <Grid item className={classes.field}>
                            <Typography variant="h2">{t('Claims.headline')}:</Typography>
                            <List component="nav" aria-label={t('Claims.headline').toString()}>
                                <ListItem>
                                    <ListItemIcon>
                                        <Info />
                                    </ListItemIcon>
                                    <ListItemText primary={t('Claims.li1')} />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <Info />
                                    </ListItemIcon>
                                    <ListItemText primary={t('Claims.li2')} />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <Info />
                                    </ListItemIcon>
                                    {role === AppRoles.CLAIMANT_CLAIMANT ? (
                                        <ListItemText primary={t('Claims.li2Claimant')} />
                                    ) : (
                                        <ListItemText primary={t('Claims.li2LegalRep')} />
                                    )}
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <Info />
                                    </ListItemIcon>
                                    <ListItemText primary={t('Claims.li3')} />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <Info />
                                    </ListItemIcon>
                                    <ListItemText primary={t('Claims.li4')} />
                                </ListItem>
                            </List>
                            <div style={{marginTop: 20, textAlign: 'center', border: '2px solid rgba(0, 0, 0, 0.54)', padding: 10, borderRadius: 5}}>
                                <Typography variant="body2" style={{fontWeight: 'bold', color: 'rgba(0, 0, 0, 0.87)'}}>
                                    {t('Claims.box1')}
                                </Typography>
                                <Typography variant="body2" style={{fontWeight: 'bold', color: 'rgba(0, 0, 0, 0.87)'}}>
                                    {t('Claims.box2')}
                                </Typography>
                            </div>
                        </Grid>
                    </CardContent>

                    <CardContent>
                        <Grid container direction="column" spacing={2}>
                            <Grid item className={classes.field}>
                                <Typography variant="h2">{t('Claims.title')}:</Typography>
                                <Typography variant="body1" style={{marginTop: 10}}>
                                    <b style={{color: 'red'}}>{t('Claims.importantNotice1')}!</b>
                                    <br />
                                    {t('Claims.importantNotice2')}.
                                </Typography>
                                <br />
                                <div>
                                    <Table className={classes.table} style={{maxWidth: '100%'}} aria-labelledby="tableTitle">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell size="small">{t('Claims.basis')}</TableCell>
                                                <TableCell size="small">{t('Claims.tokenPurchaseLocation')}</TableCell>
                                                <TableCell size="small">{t('Claims.currency')}</TableCell>
                                                <TableCell size="small">{t('Claims.amount')}</TableCell>
                                                <TableCell size="small">{t('Claims.amountChf')}</TableCell>
                                                <TableCell size="small">{t('Claims.comments')}</TableCell>
                                                <TableCell size="small" align="right">
                                                    {t('Claims.actions')}
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {claims.byId.map((claimId) => {
                                                const claim = claims.allClaims[claimId];
                                                return (
                                                    <TableRow hover tabIndex={-1} key={claim.id}>
                                                        <TableCell size="small" scope="row">
                                                            {claim.basisOfClaims}
                                                        </TableCell>
                                                        <TableCell size="small" style={{whiteSpace: 'normal', wordWrap: 'break-word', maxWidth: 150}}>
                                                            {this.mapPurchaseLocation(claim.tokenPurchaseLocation)}
                                                        </TableCell>
                                                        <TableCell size="small" style={{whiteSpace: 'normal', wordWrap: 'break-word', maxWidth: 60}}>
                                                            {claim.currency}
                                                        </TableCell>
                                                        <TableCell size="small" style={{whiteSpace: 'normal', wordWrap: 'break-word', maxWidth: 120}}>
                                                            {claim.amount}
                                                        </TableCell>
                                                        <TableCell size="small" style={{whiteSpace: 'normal', wordWrap: 'break-word', maxWidth: 120}}>
                                                            {claim.amountChf}
                                                        </TableCell>
                                                        <TableCell size="small" style={{whiteSpace: 'normal', wordWrap: 'break-word', maxWidth: 130}}>
                                                            {claim.comments}
                                                        </TableCell>
                                                        <TableCell size="small" align="right">
                                                            <IconButton
                                                                aria-label={t('Claims.edit').toString()}
                                                                onClick={() => {
                                                                    this.handleEditClick(claim.id);
                                                                }}
                                                            >
                                                                <Edit />
                                                            </IconButton>
                                                            <IconButton
                                                                aria-label={t('Claims.delete').toString()}
                                                                onClick={async () => {
                                                                    await this.handleDeleteClick(claim.id);
                                                                }}
                                                            >
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </div>
                            </Grid>

                            <Grid container justify="flex-end">
                                <Grid item>
                                    <Button disabled={false} onClick={this.handleNewClaim} color="primary" variant="outlined">
                                        {t('Claims.add')}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>

                    <CardActions>
                        <Grid container justify="space-between">
                            <Grid item>
                                <Button onClick={this.handleBackClick} disabled={this.state.submitting} type="submit" color="default" variant="contained">
                                    {t('Navigation.back')}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    disabled={this.state.submitting || claims.byId.length === 0}
                                    onClick={this.handleNextClick}
                                    color="primary"
                                    variant="contained"
                                >
                                    {t('Navigation.next')}
                                </Button>
                            </Grid>
                        </Grid>
                    </CardActions>
                </Card>

                <ClaimsDialog dialogOpen={this.state.dialogOpen} handleClaimClose={this.handleClaimClose} values={this.state.claimFormValues} />
            </React.Fragment>
        );
    }
}

const StyledClaimsStepComponent = withStyles(styles)(ClaimsStepComponent);
const I18nClaimsStepComponent = withNamespaces()(StyledClaimsStepComponent);

const mapStateToProps = ({auth, account, claimants, claims}: RootState) => ({
    auth,
    account,
    claimants,
    claims
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    updateClaimant: (claimant: IClaimant) => dispatch(updateClaimantAction(claimant)),
    selectClaimantId: (claimantId: string) => dispatch(selectClaimantIdAction(claimantId)),
    getClaims: (claimantId: string) => dispatch(getClaimsAction(claimantId)),
    selectClaimId: (claimId: string) => dispatch(selectClaimIdAction(claimId)),
    deleteClaimByID: (claimId: string) => dispatch(deleteClaimByIdAction(claimId)),
    setFaq: (type: IFaqState) => dispatch(setFaqAction(type)),
    showSnackBar: (payload: ISnackBarState) => dispatch(showSnackBarAction(payload))
});

export const ClaimsStep = connect(mapStateToProps, mapDispatchToProps)(I18nClaimsStepComponent);
