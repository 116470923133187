import React, {Component} from 'react';
import {withTranslation as withNamespaces, WithTranslation as WithNamespaces} from 'react-i18next';
import {withStyles, Grid, FormControl, FormControlLabel, RadioGroup, Radio, Typography, Card, CardHeader, CardContent} from '@material-ui/core';
import {LegalRepresentativeForm} from './LegalRepresentativeForm';
import {NonLegalRepresentativeForm} from './NonLegalRepresentativeForm';
import {IClassesProperty, styles} from './styles';
import Helmet from 'react-helmet';

export interface IDecideLegalRepresentativeProps extends WithNamespaces {
    classes: IClassesProperty;
}

class DecideLegalRepresentativeComponent extends Component<IDecideLegalRepresentativeProps> {
    state = {
        legalRepresentativeSelected: 'no'
    };

    handleChange = (event: any) => {
        this.setState({legalRepresentativeSelected: event.target.value});
    };

    render() {
        const {classes, t} = this.props;

        return (
            <React.Fragment>
                <Helmet>
                    <title>{t('DecideLegalRepresentative.title')}</title>
                </Helmet>

                <Card className={classes.card}>
                    <CardHeader
                        classes={{
                            root: classes.cardHeader,
                            title: classes.cardHeaderTitle
                        }}
                        title={t('DecideLegalRepresentative.title')}
                        style={{textAlign: 'center'}}
                    />
                    <CardContent>
                        <Grid container direction="column" spacing={2}>
                            <Grid item>
                                <Typography>{t('DecideLegalRepresentative.form.legalRepresentative.label')}</Typography>
                                <FormControl>
                                    <RadioGroup
                                        aria-label="legal-representative"
                                        name="legalRepresentative"
                                        value={this.state.legalRepresentativeSelected}
                                        onChange={this.handleChange}
                                    >
                                        <FormControlLabel
                                            value="no"
                                            control={<Radio />}
                                            label={t('DecideLegalRepresentative.form.legalRepresentative.radioNo')}
                                        />
                                        <FormControlLabel
                                            value="yes"
                                            control={<Radio />}
                                            label={t('DecideLegalRepresentative.form.legalRepresentative.radioYes')}
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>
                        {this.state.legalRepresentativeSelected === 'yes' ? <LegalRepresentativeForm /> : <NonLegalRepresentativeForm />}
                    </CardContent>
                </Card>
            </React.Fragment>
        );
    }
}

const StyledDecideLegalRepresentativeComponent = withStyles(styles)(DecideLegalRepresentativeComponent);

const I18nDecideLegalRepresentativeComponent = withNamespaces()(StyledDecideLegalRepresentativeComponent);

export {I18nDecideLegalRepresentativeComponent as DecideLegalRepresentative};
