import React, {Component} from 'react';
import {Helmet} from 'react-helmet';
import {Formik, Form, Field, FormikProps, FormikValues} from 'formik';
import {formikSubmitHandler, ConvertValidationErrorsFunction} from '../../utils';
import * as Yup from 'yup';
import {withRouter, RouteComponentProps} from 'react-router';
import * as queryString from 'query-string';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Theme} from '@material-ui/core/styles/createMuiTheme';
import {withStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import {TextField} from '../../components/TextField';
import {Typography, Grid} from '@material-ui/core';
import {Link} from '../../components/Link';
import {changePassword, validateChangePasswordTicket} from '../../libs/api/user/user';
import {withTranslation as withNamespaces, WithTranslation as WithNamespaces} from 'react-i18next';

import CardMedia from '@material-ui/core/CardMedia';
import logo from '../../images/logo-cut.png';

const styles = (theme: Theme) => ({
    media: {
        height: 80,
        backgroundSize: 'auto 50px',
        marginTop: 20
    },
    card: {
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: '500px',
        [theme.breakpoints.up('sm')]: {
            marginTop: '5vh'
        }
    },
    progressContainer: {
        display: 'flex',
        justifyContent: 'center'
    },
    progress: {
        margin: theme.spacing(4)
    },
    textField: {
        width: '100%'
    }
});

export interface IChangePasswordComponentProps extends WithNamespaces {
    classes: {
        card: string;
        media: string;
        progressContainer: string;
        progress: string;
        textField: string;
    };
}

export enum Status {
    ticketUnchecked,
    ticketValid,
    ticketInvalid,
    formSubmitted
}

export interface IChangePasswordComponentState {
    status: Status;
    error: string | null;
}

interface IChangePasswordFormValues {
    password: string;
    passwordConfirm: string;
}

class ChangePasswordComponent extends Component<IChangePasswordComponentProps & RouteComponentProps, IChangePasswordComponentState> {
    validationSchema = Yup.object().shape({
        password: Yup.string().required(this.props.t('ChangePasswordComponent.validation.password').toString()),
        passwordConfirm: Yup.string()
            .oneOf([Yup.ref('password'), undefined], this.props.t('ChangePasswordComponent.validation.passwordConfirmMatch').toString())
            .required(this.props.t('ChangePasswordComponent.validation.passwordConfirmRequired').toString())
    });

    state = {
        status: Status.ticketUnchecked,
        submitted: false,
        error: null
    };

    componentDidMount() {
        this.verifyInvitation().catch(() => {
            console.log('Failed validating invitation.');
        });
    }

    getTicketId(): string | null {
        const {id} = queryString.parse(this.props.location.search);

        if (typeof id === 'string') {
            return id;
        }

        return null;
    }

    async verifyInvitation() {
        const ticketId = this.getTicketId();

        if (!ticketId) {
            return this.setState({
                status: Status.ticketInvalid
            });
        }

        try {
            await validateChangePasswordTicket(ticketId);

            return this.setState({
                status: Status.ticketValid
            });
        } catch (error) {
            if (error.response && error.response.status === 403) {
                return this.setState({
                    status: Status.ticketInvalid
                });
            }
            throw error;
        }
    }

    onSubmit = async (values: FormikValues) => {
        const ticketId = this.getTicketId();

        if (!ticketId) {
            return this.setState({
                status: Status.ticketInvalid
            });
        }

        await changePassword(ticketId, values);

        this.setState({
            status: Status.formSubmitted,
            error: null
        });
    };

    stripFirstWord = (str: string) => {
        return str.substr(str.indexOf(' ') + 1);
    };

    convertValidationErrors: ConvertValidationErrorsFunction<FormikValues> = (validationErrors) => {
        return validationErrors.reduce<any>((result, validationError) => {
            const constrainKeys = Object.keys(validationError.constraints);

            if (constrainKeys.length > 1) {
                result[validationError.property] = (
                    <React.Fragment>
                        <ul style={{paddingLeft: 15, marginTop: 0}}>
                            {constrainKeys.map((constrainKey) => (
                                <li key={constrainKey}>{this.stripFirstWord(validationError.constraints[constrainKey])}.</li>
                            ))}
                        </ul>
                    </React.Fragment>
                );
            } else if (constrainKeys.length === 1) {
                result[validationError.property] = `${this.stripFirstWord(validationError.constraints[constrainKeys[0]])}.`;
            }

            return result;
        }, {});
    };

    onSubmitFailed = () => {
        this.setState({
            status: Status.formSubmitted
        });
    };

    renderFormikForm = (props: FormikProps<IChangePasswordFormValues>) => {
        const {classes, t} = this.props;

        return (
            <Form>
                <CardContent>
                    <Typography>{t('ChangePasswordComponent.info')}</Typography>
                    <Field
                        name="password"
                        label={t('ChangePasswordComponent.password')}
                        type="password"
                        className={classes.textField}
                        margin="normal"
                        component={TextField}
                    />
                    <Field
                        name="passwordConfirm"
                        label={t('ChangePasswordComponent.passwordConfirm')}
                        type="password"
                        className={classes.textField}
                        margin="normal"
                        component={TextField}
                    />
                </CardContent>
                <CardActions>
                    <Grid container direction="row-reverse" justify="space-between" alignItems="flex-end">
                        <Grid item>
                            <Button type="submit" variant="contained" color="primary" disabled={props.isSubmitting}>
                                {props.isSubmitting ? t('ChangePasswordComponent.sending') : t('ChangePasswordComponent.submit')}
                            </Button>
                        </Grid>
                    </Grid>
                </CardActions>
            </Form>
        );
    };

    renderForm() {
        return (
            <Formik
                initialValues={{password: '', passwordConfirm: ''}}
                validationSchema={this.validationSchema}
                onSubmit={formikSubmitHandler(this.onSubmit, {convertValidationErrors: this.convertValidationErrors, unknownError: this.onSubmitFailed})}
            >
                {this.renderFormikForm}
            </Formik>
        );
    }

    renderContent() {
        const {classes, t} = this.props;
        const {status, error} = this.state;

        if (status === Status.ticketUnchecked) {
            return (
                <CardContent>
                    <div className={classes.progressContainer}>
                        <CircularProgress className={classes.progress} />
                    </div>
                </CardContent>
            );
        }

        if (error) {
            return (
                <CardContent>
                    <Typography>{t('ChangePasswordComponent.error')}</Typography>
                </CardContent>
            );
        }

        if (status === Status.ticketValid) {
            return this.renderForm();
        }

        if (status === Status.ticketInvalid) {
            return (
                <CardContent>
                    <Typography>{t('ChangePasswordComponent.invalid')}</Typography>
                </CardContent>
            );
        }

        if (status === Status.formSubmitted) {
            return (
                <CardContent>
                    <Typography>
                        {t('ChangePasswordComponent.submitted')}:{' '}
                        <Link variant="body2" to="/login">
                            {t('ChangePasswordComponent.link')}
                        </Link>
                    </Typography>
                </CardContent>
            );
        }

        return (
            <CardContent>
                <CircularProgress className={classes.progress} />
            </CardContent>
        );
    }

    render() {
        const {classes, t} = this.props;

        return (
            <React.Fragment>
                <Helmet>
                    <title>{t('ChangePasswordComponent.title')}</title>
                </Helmet>

                <Card className={classes.card}>
                    <CardMedia className={classes.media} image={logo} />
                    <CardHeader title={t('ChangePasswordComponent.title')} titleTypographyProps={{align: 'center'}} />
                    {this.renderContent()}
                </Card>
            </React.Fragment>
        );
    }
}

const StyledChangePasswordComponent = withStyles(styles)(ChangePasswordComponent);
const I18nChangePasswordComponent = withNamespaces()(StyledChangePasswordComponent);

export const ChangePassword = withRouter(I18nChangePasswordComponent);
