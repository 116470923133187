import React, {Component} from 'react';
import classNames from 'classnames';
import {Theme} from '@material-ui/core/styles/createMuiTheme';
import {withStyles} from '@material-ui/core';
import {Property} from 'csstype';
import {withTranslation as withNamespaces, WithTranslation as WithNamespaces} from 'react-i18next';

const styles = (theme: Theme) => ({
    Countdown: {
        display: 'flex',
        flexDirection: 'row' as Property.FlexDirection,
        justifyContent: 'center',
        marginTop: '5px',
        marginBottom: '5px',
        fontWeight: 300,
        lineHeight: 1.1,
        fontSize: '2rem',
        color: theme.palette.primary.dark
    },
    CountdownLight: {
        color: theme.palette.primary.light
    },
    CountdownSeparator: {
        fontSize: '2.5rem',
        fontWeight: 300
    },
    CountdownDaysValue: {
        fontSize: '2.5rem',
        fontWeight: 300
    },
    CountdownHoursValue: {
        fontSize: '2.5rem',
        fontWeight: 300
    },
    CountdownMinutesValue: {
        fontSize: '2.5rem',
        fontWeight: 300
    },
    CountdownSecondsValue: {
        fontSize: '2.5rem',
        fontWeight: 300
    },
    CountdownLabel: {
        fontSize: '0.6875rem',
        textTransform: 'uppercase' as Property.TextTransform,
        textAlign: 'center' as Property.TextAlign
    }
});

export interface ICountdownProps extends WithNamespaces {
    classes: {
        Countdown: string;
        CountdownLight: string;
        CountdownSeparator: string;
        CountdownDaysValue: string;
        CountdownHoursValue: string;
        CountdownMinutesValue: string;
        CountdownSecondsValue: string;
        CountdownLabel: string;
    };
    countDownDate: string;
    light?: boolean;
}

class CountdownComponent extends Component<ICountdownProps> {
    public state = {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
        expired: false
    };

    private interval: null | NodeJS.Timeout;

    constructor(props: ICountdownProps) {
        super(props);
        this.interval = null;
    }

    componentDidMount() {
        this.interval = this.bindCountdownInterval();
    }

    componentWillUnmount() {
        if (this.interval) {
            clearInterval(this.interval);
        }
    }

    bindCountdownInterval() {
        const countDownDate = new Date(this.props.countDownDate).getTime();

        const interval = setInterval(() => {
            const now = new Date().getTime();

            // Find the distance between now an the count down date
            const distance = countDownDate - now;

            this.setState({
                days: Math.floor(distance / (1000 * 60 * 60 * 24)),
                hours: Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
                minutes: Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
                seconds: Math.floor((distance % (1000 * 60)) / 1000)
            });

            if (distance < 0) {
                clearInterval(interval);
                this.setState({expired: true});
            }
        }, 1000);

        return interval;
    }

    renderCountdown() {
        const {classes, t} = this.props;

        return (
            <>
                <div>
                    <div className={classes.CountdownDaysValue}>{this.state.days.toString().padStart(2, '0')}</div>
                    <div className={classes.CountdownLabel}>{t('Countdown.days')}</div>
                </div>
                <div className={classes.CountdownSeparator}>:</div>
                <div>
                    <div className={classes.CountdownHoursValue}>{this.state.hours.toString().padStart(2, '0')}</div>
                    <div className={classes.CountdownLabel}>{t('Countdown.hours')}</div>
                </div>
                <div className={classes.CountdownSeparator}>:</div>
                <div>
                    <div className={classes.CountdownMinutesValue}>{this.state.minutes.toString().padStart(2, '0')}</div>
                    <div className={classes.CountdownLabel}>{t('Countdown.minutes')}</div>
                </div>
                <div className={classes.CountdownSeparator}>:</div>
                <div>
                    <div className={classes.CountdownSecondsValue}>{this.state.seconds.toString().padStart(2, '0')}</div>
                    <div className={classes.CountdownLabel}>{t('Countdown.seconds')}</div>
                </div>
            </>
        );
    }

    render() {
        const {light, classes} = this.props;

        return (
            <div className={classNames(classes.Countdown, light ? classes.CountdownLight : null)}>
                {this.state.expired ? 'EXPIRED' : this.renderCountdown()}
            </div>
        );
    }
}

const I18nCountdownComponent = withNamespaces()(CountdownComponent);

export const Countdown = withStyles(styles, {withTheme: true})(I18nCountdownComponent);
