import React from 'react';
import {Button} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {ClaimantStatus} from '../../store/claimants/claimants.types';
import ControlledExpansionPanels from '../ControlledExpansionPanels/ControlledExpansionPanels';
import {defaultData} from './data/default';
import {personalInformationData} from './data/personal-information';
import {powerOfAttorneyData} from './data/power-of-attorney';
import {claimantPassportData} from './data/claimant-passport';
import {additionalDocumentsData} from './data/additional-documents';
import {tokenPurchaseData} from './data/token-purchase';
import {meetingOfClaimantsData} from './data/meeting-of-claimants';
import {claimsData} from './data/claims';
import {signClaimsData} from './data/sign-claims';
import {sendTokensData} from './data/send-tokens';
import {reviewData} from './data/review';
import {withTranslation as withNamespaces, WithTranslation as WithNamespaces} from 'react-i18next';
import {getCurrentLanguage} from '../../i18n';
import {RootState} from '../../store';
import {Dispatch} from 'redux';
import {connect} from 'react-redux';
import {IFaqState} from '../../store/faq/faq.types';

interface IFAQComponent extends WithNamespaces {
    faqOpen: boolean;
    faq: IFaqState;
    handleFAQClose(): void;
}

class FAQComponent extends React.Component<IFAQComponent> {
    render() {
        const {faqOpen, handleFAQClose, t, faq} = this.props;
        const lang = getCurrentLanguage();
        let data = defaultData(lang);

        switch (faq.type) {
            case ClaimantStatus.PERSONAL_INFORMATION:
                data = personalInformationData(lang);
                break;
            case ClaimantStatus.POWER_OF_ATTORNEY:
                data = powerOfAttorneyData(lang);
                break;
            case ClaimantStatus.PASSPORT:
                data = claimantPassportData(lang);
                break;
            case ClaimantStatus.ADDITIONAL_DOCUMENTS:
                data = additionalDocumentsData(lang);
                break;
            case ClaimantStatus.TOKEN_PURCHASE:
                data = tokenPurchaseData(lang);
                break;
            case ClaimantStatus.MEETING_OF_CLAIMANTS:
                data = meetingOfClaimantsData(lang);
                break;
            case ClaimantStatus.CLAIMS:
                data = claimsData(lang);
                break;
            case ClaimantStatus.SIGN_CLAIMS_FORM:
                data = signClaimsData(lang);
                break;
            case ClaimantStatus.SEND_TOKENS:
                data = sendTokensData(lang);
                break;
            case ClaimantStatus.REVIEW:
            case ClaimantStatus.COMPLETE:
            case ClaimantStatus.INCOMPLETE:
                data = reviewData(lang);
                break;
            default:
                data = defaultData(lang);
        }

        return (
            <Dialog fullWidth={true} maxWidth={'lg'} open={faqOpen} onClose={handleFAQClose} aria-labelledby="max-width-dialog-title">
                <DialogTitle id="max-width-dialog-title">FAQ</DialogTitle>
                <DialogContent>
                    <ControlledExpansionPanels payload={data} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleFAQClose} disabled={false} type="submit" color="default" variant="contained">
                        {t('FAQComponent.close')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

const I18nFAQComponent = withNamespaces()(FAQComponent);

const mapStateToProps = ({faq}: RootState) => ({
    faq
});

const mapDispatchToProps = (_dispatch: Dispatch) => ({});

const FAQ = connect(mapStateToProps, mapDispatchToProps)(I18nFAQComponent);

export {FAQ};
