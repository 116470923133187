import React from 'react';
import {withStyles, Typography, Grid, Card, CardHeader, CardContent, Button, CardActions, Link} from '@material-ui/core';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {styles, IClassesProperty} from '../styles';
import {RootState} from '../../../store';
import {IClaimantsState, IClaimant, ClaimantStatus, IFile} from '../../../store/claimants/claimants.types';
import {IAuthState} from '../../../store/auth/auth.types';
import {DropzoneArea} from 'material-ui-dropzone';
import classNames from 'classnames';
import {Files} from '../../../components/Files';
import {patchClaimant, uploadFile, deleteFile} from '../../../libs/api/user/user';
import {IAccountState, AppRoles} from '../../../store/account/account.types';
import {selectClaimantId as selectClaimantIdAction, updateClaimant as updateClaimantAction} from '../../../store/claimants/claimants.actions';
import {getClaims as getClaimsAction} from '../../../store/claims/claims.actions';
import {IClaimsState} from '../../../store/claims/claims.types';
import Helmet from 'react-helmet';
import {showSnackBar as showSnackBarAction} from '../../../store/snackbar/snackbar.actions';
import {ISnackBarState} from '../../../store/snackbar/snackbar.types';
import {withTranslation as withNamespaces, WithTranslation as WithNamespaces} from 'react-i18next';
import {setFaq as setFaqAction} from '../../../store/faq/faq.actions';
import {IFaqState} from '../../../store/faq/faq.types';

export interface ISignClaimsStepProps extends WithNamespaces {
    classes: IClassesProperty;
    auth: IAuthState;
    claimants: IClaimantsState;
    claims: IClaimsState;
    account: IAccountState;
    getClaims(claimantId: string): void;
    selectClaimantId(claimantId: string | null): void;
    updateClaimant(claimant: IClaimant): void;
    showSnackBar(payload: ISnackBarState): void;
    setFaq(type: IFaqState): void;
}

class SignClaimsStepComponent extends React.Component<ISignClaimsStepProps> {
    _isMounted = false;

    state = {
        files: [],
        iFiles: [],
        submitting: false
    };

    componentDidMount() {
        this._isMounted = true;

        const {getClaims} = this.props;
        const claimant = this.getClaimant();
        const {setFaq} = this.props;

        setFaq({type: ClaimantStatus.SIGN_CLAIMS_FORM});

        if (claimant) {
            getClaims(claimant.id);
        }

        const initialValues = this.getInitialValuesFromClaimant();
        this.setState({iFiles: initialValues.signedClaimsDocument ? [initialValues.signedClaimsDocument] : []});
    }

    componentWillUnmount = () => {
        this._isMounted = false;
    };

    setSubmitting = (submitting = true) => {
        if (this._isMounted) {
            this.setState({submitting});
        }
    };

    getClaimant = () => {
        const {claimants} = this.props;
        return claimants.selectedClaimantId ? claimants.allClaimants[claimants.selectedClaimantId] : claimants.allClaimants[claimants.byId[0]];
    };

    getInitialValuesFromClaimant = () => {
        const claimant = this.getClaimant();

        return {
            signedClaimsDocument: claimant.signedClaimsDocument
        };
    };

    getRole = () => {
        const {account, t} = this.props;

        if (!account.properties) {
            throw new Error(t('Roles.invalid').toString());
        }

        return account.properties.role;
    };

    showError = (error: string) => {
        this.props.showSnackBar({message: error, variant: 'error'});
    };

    onDelete = async (type: string, file: IFile) => {
        const {auth, updateClaimant, t} = this.props;

        try {
            const claimant = this.getClaimant();

            const values = {
                id: file.id || '',
                type,
                file: file.fileNameHashed,
                path: claimant.id,
                status: ClaimantStatus.SIGN_CLAIMS_FORM
            };

            this.setSubmitting();

            const updatedClaimant = await deleteFile(values, auth.token);
            updateClaimant(updatedClaimant);

            this.setState({
                iFiles: []
            });

            this.setSubmitting(false);
        } catch (error) {
            console.error(error);
            this.showError(t('FileComponent.deleteFailed').toString());
        }
    };

    handleBackClick = async () => {
        const {iFiles} = this.state;
        const {auth, updateClaimant, t} = this.props;

        try {
            const role = this.getRole();
            const claimant = this.getClaimant();

            if (iFiles.length > 0) {
                // delete file
                await this.onDelete('signedClaimsDocument', iFiles[0]);
            }

            // Update claimant entity
            this.setSubmitting();

            const values = {
                signedClaimsDocument: null,
                status: ClaimantStatus.CLAIMS
            };

            if (role === AppRoles.CLAIMANT_CLAIMANT) {
                const updatedClaimant = await patchClaimant(values, 'step-only', null, auth.token);
                updateClaimant(updatedClaimant);
            } else if (role === AppRoles.CLAIMANT_LEGAL_REPRESENTATIVE) {
                const updatedClaimant = await patchClaimant(values, 'step-only', claimant.id, auth.token);
                updateClaimant(updatedClaimant);
            }

            this.setSubmitting(false);
        } catch (error) {
            console.error(error);
            this.showError(t('Upload.failed').toString());
        }
    };

    onSubmit = async () => {
        const {files} = this.state;
        const {auth, updateClaimant, t} = this.props;

        try {
            const role = this.getRole();
            const claimant = this.getClaimant();
            const claimantValues = this.getInitialValuesFromClaimant();
            const status = claimant.hideSendTokenStep ? ClaimantStatus.REVIEW : ClaimantStatus.SEND_TOKENS;

            let values = {
                ...claimantValues,
                status
            };

            this.setSubmitting();

            // File upload
            if (files.length > 0) {
                const payload = new FormData();
                payload.append('claimantId', claimant.id);
                payload.append('docType', 'signedClaimsDocument');

                files.forEach((file) => {
                    payload.append('files', file);
                });

                const uploadedFiles = await uploadFile(payload, auth.token);

                if (!uploadedFiles) {
                    throw new Error(t('Upload.failed').toString());
                }

                values = {
                    status,
                    signedClaimsDocument: {
                        fileNameOriginal: uploadedFiles[0].fileNameOriginal,
                        fileNameHashed: uploadedFiles[0].fileNameHashed,
                        mimeType: uploadedFiles[0].mimeType
                    }
                };
            }

            // Update claimant in DB
            if (role === AppRoles.CLAIMANT_CLAIMANT) {
                const updatedClaimant = await patchClaimant(values, 'signed-claims', null, auth.token);
                updateClaimant(updatedClaimant);
            } else if (role === AppRoles.CLAIMANT_LEGAL_REPRESENTATIVE) {
                const updatedClaimant = await patchClaimant(values, 'signed-claims', claimant.id, auth.token);
                updateClaimant(updatedClaimant);
            }
            this.setSubmitting(false);
        } catch (error) {
            console.error(error);
            this.showError(t('Upload.failed').toString());
        }
    };

    getTokenPurchaseMapping(lang: string) {
        if (lang === 'de') {
            return {
                ico: 'ICO Teilnehmer',
                secondaryMarket: 'Zweitmarkt',
                privatePerson: 'Privatperson',
                presaleOrPrivatePlacement: 'Vorverkauf / Privatplatzierungen',
                founders: 'Gründer',
                multi: 'Mehrere Quellen',
                other: 'Anderes'
            };
        }
        return {
            ico: 'ICO subscribers',
            secondaryMarket: 'Secondary Market',
            privatePerson: 'Private Person',
            presaleOrPrivatePlacement: 'Pre-Sale / Private Placement',
            founders: 'Founders',
            multi: 'Multiple sources',
            other: 'Other'
        };
    }

    mapPurchaseLocation = (key: string, lang: string) => {
        const enMapping: any = {
            ico: 'ICO subscribers',
            secondaryMarket: 'Secondary market',
            privatePerson: 'Private person',
            presaleOrPrivatePlacement: 'Pre-sale / Private placement',
            founders: 'Founders',
            multi: 'Multiple sources',
            interest: 'Interest',
            other: 'Other'
        };

        const deMapping: any = {
            ico: 'ICO Teilnehmer',
            secondaryMarket: 'Sekundärmarkt',
            privatePerson: 'Privatperson',
            presaleOrPrivatePlacement: 'Vorverkauf / Privatplatzierung',
            founders: 'Gründer',
            multi: 'Unterschiedliche Quellen',
            interest: 'Zinsen',
            other: 'Anderweitig'
        };

        if (lang === 'en') {
            return enMapping[key];
        }

        return deMapping[key];
    };

    getPdf = (lang: string) => {
        const {account, claims, t} = this.props;
        const claimant = this.getClaimant();
        const role = this.getRole();
        const tokenLocationString = lang === 'de' ? 'Tokenherkunft' : 'Token purchase location';

        const nameMapping: any = {
            contact: {
                en: 'Contact person (last name / first name)',
                de: 'Kontaktperson (Nachname / Vorname)'
            },
            private: {
                en: 'Last name / first name',
                de: 'Name / Vorname'
            },
            company: {
                en: 'Company',
                de: 'Firma'
            }
        };

        let name = {
            key: nameMapping.private[lang],
            val: `${claimant.familyName} / ${claimant.givenName}`
        };

        let company = {
            key: '',
            val: ''
        };

        if (claimant.company) {
            company = {
                key: nameMapping.company[lang],
                val: `${claimant.company}`
            };
            name = {
                key: nameMapping.contact[lang],
                val: `${claimant.familyName} / ${claimant.givenName}`
            };
        }

        try {
            const options: any = {
                claimant: {
                    company,
                    name,
                    address: `${claimant.address1} ${claimant.address2}`,
                    address2: `${claimant.postalCode}, ${claimant.addressLocality}, ${claimant.addressCountry}`,
                    phone: claimant.phoneNumber,
                    email: claimant.email
                },
                legalRep: {
                    name: '-',
                    address: '-',
                    address2: '-',
                    phone: '-',
                    email: '-'
                },
                claims: {},
                additionalDocuments: [],
                powerOfAttorneyLetter: [],
                passport: [],
                purchaseDocuments: []
            };

            if (role === AppRoles.CLAIMANT_LEGAL_REPRESENTATIVE) {
                if (!account || !account.properties || !account.properties.legalRepresentativeDetails) {
                    throw new Error(this.props.t('Roles.invalid').toString());
                }

                options.legalRep = {
                    name: `${account.properties.legalRepresentativeDetails.familyName} / ${account.properties.legalRepresentativeDetails.givenName} / ${account.properties.legalRepresentativeDetails.company}`,
                    address: `${account.properties.legalRepresentativeDetails.address1} ${account.properties.legalRepresentativeDetails.address2}`,
                    address2: `${account.properties.legalRepresentativeDetails.postalCode}, ${account.properties.legalRepresentativeDetails.addressLocality}, ${account.properties.legalRepresentativeDetails.addressCountry}`,
                    phone: account.properties.legalRepresentativeDetails.phoneNumber,
                    email: account.properties.email
                };
            }

            claimant.additionalDocuments.forEach((doc) => {
                options.additionalDocuments.push(doc.fileNameOriginal);
            });

            if (claimant.powerOfAttorneyLetter.length > 0) {
                claimant.powerOfAttorneyLetter.forEach((doc) => {
                    options.powerOfAttorneyLetter.push(doc.fileNameOriginal);
                });
            } else {
                options.powerOfAttorneyLetter = ['-'];
            }

            claimant.passport.forEach((doc) => {
                options.passport.push(doc.fileNameOriginal);
            });

            claimant.purchaseDocuments.forEach((doc) => {
                options.purchaseDocuments.push(doc.fileNameOriginal);
            });

            const howTokenPurchase = claimant.otherTokenPurchase
                ? `${tokenLocationString}: ${claimant.otherTokenPurchase}`
                : `${tokenLocationString}: ${this.getTokenPurchaseMapping(lang)[claimant.tokenPurchaseLocation]}`;
            options.purchaseDocuments.push(howTokenPurchase);

            if (lang === 'de') {
                options.claims = {
                    items: claims.byId.map((claim) => {
                        return [
                            claims.allClaims[claim].basisOfClaims,
                            this.mapPurchaseLocation(claims.allClaims[claim].tokenPurchaseLocation, 'de'),
                            claims.allClaims[claim].currency,
                            claims.allClaims[claim].amount,
                            claims.allClaims[claim].amountChf,
                            claims.allClaims[claim].comments
                        ];
                    })
                };

                import('./pdfGerman')
                    .then(({getSignClaimsPdfGerman}) => {
                        getSignClaimsPdfGerman(options);
                    })
                    .catch((err) => {
                        console.error(err);
                        this.showError(t('FileComponent.pdfDisplayError').toString());
                    });
            }

            if (lang === 'en') {
                options.claims = {
                    items: claims.byId.map((claim) => {
                        return [
                            claims.allClaims[claim].basisOfClaims,
                            this.mapPurchaseLocation(claims.allClaims[claim].tokenPurchaseLocation, 'en'),
                            claims.allClaims[claim].currency,
                            claims.allClaims[claim].amount,
                            claims.allClaims[claim].amountChf,
                            claims.allClaims[claim].comments
                        ];
                    })
                };

                import('./pdfEnglish')
                    .then(({getSignClaimsPdfEnglish}) => {
                        getSignClaimsPdfEnglish(options);
                    })
                    .catch((err) => {
                        console.error(err);
                        this.showError(t('FileComponent.pdfDisplayError').toString());
                    });
            }
        } catch (error) {
            console.error(error);
            this.showError(t('FileComponent.pdfDisplayError').toString());
        }
    };

    handleChange = (files: []) => {
        this.setState({
            files
        });
    };

    handleFilesRejected = (_files: []) => {
        this.props.showSnackBar({message: `${this.props.t('Upload.invalid')}.`, variant: 'error'});
    };

    mapHonorificPrefix = (value: string) => {
        const {t} = this.props;

        const mapping = {
            mr: t('SignClaimsStep.pmr'),
            mrs: t('SignClaimsStep.pmrs')
        } as any;

        return mapping[value];
    };

    render() {
        const {classes, auth, t} = this.props;
        const {files, iFiles} = this.state;
        const claimant = this.getClaimant();
        const role = this.getRole();

        const honorificPrefix = claimant.honorificPrefix ? `${this.mapHonorificPrefix(claimant.honorificPrefix)} ` : '';
        let salutation = t('SignClaimsStep.claimant.dropzoneText');

        if (this.getRole() === AppRoles.CLAIMANT_LEGAL_REPRESENTATIVE) {
            salutation = t('SignClaimsStep.legalRep.dropzoneText', {name: `${honorificPrefix}${claimant.givenName} ${claimant.familyName}`});
        }

        // Hack to resolve issues with i18n and missing error message if file count exceed, but file size is ok
        const DZ = DropzoneArea as any;

        return (
            <React.Fragment>
                <Helmet>
                    <title>{t('SignClaimsStep.title')}</title>
                </Helmet>

                <Card className={classes.card}>
                    <CardHeader
                        classes={{
                            root: classes.cardHeader,
                            title: classes.cardHeaderTitle
                        }}
                        title={t('SignClaimsStep.title')}
                        style={{textAlign: 'center'}}
                    />
                    <CardContent>
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography component="div" variant="body1" className={classes.textRow}>
                                    {role === AppRoles.CLAIMANT_CLAIMANT ? t('SignClaimsStep.p1Claimant') : t('SignClaimsStep.p1LegalRep')}
                                    <ul>
                                        <li>
                                            <Link style={{cursor: 'pointer'}} onClick={() => this.getPdf('de')}>
                                                {t('SignClaimsStep.germanVersion')}
                                            </Link>
                                        </li>
                                        <li>
                                            <Link style={{cursor: 'pointer'}} onClick={() => this.getPdf('en')}>
                                                {t('SignClaimsStep.englishVersion')}
                                            </Link>
                                        </li>
                                    </ul>
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={12}>
                                <div className={classNames(classes.colLeft, classes.row)}>
                                    {files.length > 0 && iFiles.length > 0 ? (
                                        <Typography className={classes.warning}>{t('Upload.replaceInfo')}!</Typography>
                                    ) : null}
                                    {files.length === 0 && iFiles.length > 0 ? (
                                        <Files type="signedClaimsDocument" onDelete={this.onDelete} auth={auth} files={iFiles} folder={claimant.id} />
                                    ) : null}
                                    <div className={this.props.classes.dropZoneWrapper}>
                                        <DZ
                                            getFileLimitExceedMessage={() => t('Upload.getFileLimitExceedMessage1')}
                                            getFileAddedMessage={() => t('Upload.getFileAddedMessage')}
                                            getFileRemovedMessage={() => t('Upload.getFileRemovedMessage')}
                                            getDropRejectMessage={() => t('Upload.getDropRejectMessage')}
                                            showAlerts={true}
                                            onDropRejected={this.handleFilesRejected}
                                            onChange={this.handleChange}
                                            acceptedFiles={['application/pdf']}
                                            dropzoneText={salutation.toString()}
                                            filesLimit={1}
                                            maxFileSize={10000000}
                                            dropzoneClass={this.props.classes.dropZoneCentered}
                                            showPreviewsInDropzone={false}
                                            showPreviews={true}
                                            dropzoneParagraphClass={this.props.classes.dropZoneParagraph}
                                            showFileNamesInPreview={true}
                                        />
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </CardContent>

                    <CardActions>
                        <Grid container justify="space-between">
                            <Grid item>
                                <Button disabled={this.state.submitting} onClick={this.handleBackClick} type="button" color="default" variant="contained">
                                    {t('Navigation.back')}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    onClick={this.onSubmit}
                                    disabled={this.state.submitting || (iFiles.length === 0 && files.length === 0)}
                                    type="submit"
                                    color="primary"
                                    variant="contained"
                                >
                                    {t('Navigation.next')}
                                </Button>
                            </Grid>
                        </Grid>
                    </CardActions>
                </Card>
            </React.Fragment>
        );
    }
}

const StyledSignClaimsStepComponent = withStyles(styles)(SignClaimsStepComponent);
const I18nSignClaimsStepComponent = withNamespaces()(StyledSignClaimsStepComponent);

const mapStateToProps = ({auth, account, claimants, claims}: RootState) => ({
    auth,
    account,
    claimants,
    claims
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    getClaims: (claimantId: string) => dispatch(getClaimsAction(claimantId)),
    updateClaimant: (claimant: IClaimant) => dispatch(updateClaimantAction(claimant)),
    selectClaimantId: (claimantId: string) => dispatch(selectClaimantIdAction(claimantId)),
    showSnackBar: (payload: ISnackBarState) => dispatch(showSnackBarAction(payload)),
    setFaq: (type: IFaqState) => dispatch(setFaqAction(type))
});

export const SignClaimsStep = connect(mapStateToProps, mapDispatchToProps)(I18nSignClaimsStepComponent);
