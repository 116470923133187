import * as yup from 'yup';
import {IConfigurations} from '../typings/configurations';

interface IWindow {
    ENV: IConfigurations;
}

const configSchema = yup.object().shape({
    APP_API_ENDPOINT: yup
        .string()
        // eslint-disable-next-line no-useless-escape
        .matches(/^https:\/\/.*[^\/]$/, 'Must be a valid endpoint of the following schema without a trailing slash (https://www.company.com)')
        .required(),
    APP_MULTISIG_CONTRACT_ADDRESS: yup.string().required(),
    APP_TOKEN_CONTRACT: yup.string().required(),
    APP_TOKEN_SYMBOL: yup.string().required(),
    APP_TOKEN_NETWORK: yup.string().required(),
    APP_SENTRY_IS_ACTIVE: yup.boolean(),
    APP_SENTRY_DSN: yup.string().nullable(),
    APP_READONLY_MODE: yup.boolean(),
    APP_MEETING_OF_CLAIMANTS: yup.boolean(),
    APP_PORTAL_CLOSED: yup.boolean(),
    APP_GOOGLE_ANALYTICS_CODE: yup.string(),
    APP_GOOGLE_RECAPTCHA_SITE_KEY: yup.string(),
    APP_COUNTDOWN: yup.string().nullable()
});

const getConfig = (): IConfigurations => {
    if (!window.config) {
        window.config = configSchema.validateSync(((window as any) as IWindow).ENV, {stripUnknown: true}) as IConfigurations;
    }
    return window.config;
};

export default getConfig();
