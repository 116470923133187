import React from 'react';
import {withStyles, Grid, Table, TableHead, TableRow, TableCell, TableBody, Fab, Theme, IconButton} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import {PageTitle} from '../../components/PageTitle';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {RootState} from '../../store';
import {KeyboardArrowRight} from '@material-ui/icons';
import {IClaimantsState, ClaimantStatus, IClaimant} from '../../store/claimants/claimants.types';
import {selectClaimantId as selectClaimantIdAction} from '../../store/claimants/claimants.actions';
import {ClaimProcess} from '../ClaimProcess';
import Helmet from 'react-helmet';
import {withTranslation as withNamespaces, WithTranslation as WithNamespaces} from 'react-i18next';
import {setFaq as setFaqAction} from '../../store/faq/faq.actions';
import {IFaqState} from '../../store/faq/faq.types';
import {LockIcon} from '../../components/LockIcon';
import getConfig from '../../config';

const styles = (theme: Theme) => ({
    container: {
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: '1000px',
        marginBottom: '5vh'
    },
    fab: {
        margin: theme.spacing(1)
    }
});

export interface IClaimantsOverviewProps extends WithNamespaces {
    classes: {
        container: string;
        fab: string;
    };
    claimants: IClaimantsState;
    selectClaimantId(claimantId: string): void;
    setFaq(type: IFaqState): void;
}

class ClaimantsOverviewComponent extends React.Component<IClaimantsOverviewProps> {
    state = {
        addClicked: false
    };

    componentDidMount = () => {
        const {setFaq, claimants} = this.props;

        if (!this.state.addClicked && !claimants.selectedClaimantId) {
            setFaq({type: null});
        }
    };

    getDepositAddress(claimant: IClaimant) {
        if (!claimant.generatedWallets || claimant.generatedWallets.length === 0) {
            return null;
        }

        return claimant.generatedWallets[0].ethereumAddress;
    }

    extractOverviewStatus = (status: ClaimantStatus) => {
        const {t} = this.props;

        switch (status) {
            case ClaimantStatus.REVIEW:
                return t('ClaimantsOverview.review');
            case ClaimantStatus.COMPLETE:
                return t('ClaimantsOverview.complete');
            case ClaimantStatus.INCOMPLETE:
                return t('ClaimantsOverview.incomplete');
            default:
                return t('ClaimantsOverview.inProgress');
        }
    };

    showOnEtherscan = async (event: any) => {
        event.persist();

        let url = 'https://etherscan.io/address/';
        if (getConfig.APP_TOKEN_NETWORK === 'rinkeby') {
            url = 'https://rinkeby.etherscan.io/address/';
        }

        const etherScanLink = `${url}${event.target.innerText}`;
        window.open(etherScanLink, '_blank');
    };

    handleEditClick = (claimant: IClaimant) => {
        const {selectClaimantId} = this.props;
        selectClaimantId(claimant.id);
    };

    handleAddClick = () => {
        this.setState({addClicked: true});
    };

    getReviewNotes(claimant: IClaimant) {
        if (!claimant.auditTrail) {
            return '-';
        }

        const sorted = claimant.auditTrail.sort((a, b) => {
            // tslint:disable-next-line: early-exit
            if (a.createdAt < b.createdAt) {
                return -1;
            }
            if (a.createdAt > b.createdAt) {
                return 1;
            }
            return 0;
        });

        const result = sorted.filter((item) => item.category === 'admin--update-claimant-status');
        return result.length > 0 ? result[result.length - 1].description : '-';
    }

    render() {
        const {classes, claimants, t} = this.props;

        if (this.state.addClicked || claimants.selectedClaimantId) {
            return <ClaimProcess />;
        }

        return (
            <React.Fragment>
                <Helmet>
                    <title>{t('ClaimantsOverview.title')}</title>
                </Helmet>

                <Grid container className={classes.container} direction="column" alignItems="center" spacing={2}>
                    <Grid item>
                        <PageTitle text={t('ClaimantsOverview.title')} />
                    </Grid>
                    <Grid item>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {/* <TableCell component="th" size="small">
                                        {t('ClaimantsOverview.id')}
                                    </TableCell> */}
                                    <TableCell size="small" component="th">
                                        {t('ClaimantsOverview.givenName')}
                                    </TableCell>
                                    <TableCell size="small" component="th">
                                        {t('ClaimantsOverview.familyName')}
                                    </TableCell>
                                    <TableCell size="small" component="th">
                                        {t('ClaimantsOverview.depositAddress')}
                                    </TableCell>
                                    <TableCell size="small" component="th">
                                        {t('ClaimantsOverview.status')}
                                    </TableCell>
                                    <TableCell size="small" component="th">
                                        {t('ClaimantsOverview.notes')}
                                    </TableCell>
                                    <TableCell size="small" component="th">
                                        {t('ClaimantsOverview.locked')}
                                    </TableCell>
                                    <TableCell size="small" component="th" />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {claimants.byId.map((claimantId) => {
                                    const claimant = claimants.allClaimants[claimantId];

                                    return (
                                        <TableRow key={claimant.id}>
                                            {/* <TableCell size="small">EN{claimant.eId.toString().padStart(6, '0')}</TableCell> */}
                                            <TableCell size="small">{claimant.givenName}</TableCell>
                                            <TableCell size="small">{claimant.familyName}</TableCell>
                                            <TableCell size="small" onClick={this.showOnEtherscan} style={{cursor: 'pointer'}}>
                                                {this.getDepositAddress(claimant) || '-'}
                                            </TableCell>
                                            <TableCell size="small">{this.extractOverviewStatus(claimant.status)}</TableCell>
                                            <TableCell size="small">{this.getReviewNotes(claimant)}</TableCell>
                                            <TableCell size="small" align="center">
                                                <LockIcon locked={claimant.locked} />
                                            </TableCell>
                                            <TableCell size="small">
                                                <IconButton aria-label={t('ClaimantsOverview.edit').toString()} onClick={() => this.handleEditClick(claimant)}>
                                                    <KeyboardArrowRight />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </Grid>

                    {getConfig.APP_READONLY_MODE ? null : (
                        <Grid item>
                            <Fab color="primary" aria-label={t('ClaimantsOverview.add').toString()} className={classes.fab} onClick={this.handleAddClick}>
                                <AddIcon />
                            </Fab>
                        </Grid>
                    )}
                </Grid>
            </React.Fragment>
        );
    }
}

const StyledClaimantsOverviewComponent = withStyles(styles)(ClaimantsOverviewComponent);
const I18nClaimantsOverviewComponent = withNamespaces()(StyledClaimantsOverviewComponent);

const mapStateToProps = ({claimants}: RootState) => ({
    claimants
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    selectClaimantId: (claimantId: string) => dispatch(selectClaimantIdAction(claimantId)),
    setFaq: (type: IFaqState) => dispatch(setFaqAction(type))
});

export const ClaimantsOverview = connect(mapStateToProps, mapDispatchToProps)(I18nClaimantsOverviewComponent);
