import React, {Component} from 'react';
import {Helmet} from 'react-helmet';
import {withRouter, Redirect, RouteComponentProps} from 'react-router';
import {Dispatch} from 'redux';
import {connect} from 'react-redux';
import getConfig from '../../config';
import {RootState} from '../../store';
import {IAuthState} from '../../store/auth/auth.types';
import {authenticated as authenticatedAction} from '../../store/auth/auth.actions';
import {Formik, Form, Field, FormikProps, FormikValues, FormikHelpers as FormikActions} from 'formik';
import * as Yup from 'yup';
import {AxiosError} from 'axios';
import {formikSubmitHandler} from '../../utils';
import {userApi} from '../../libs/api';
import {Theme} from '@material-ui/core/styles/createMuiTheme';
import {withStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import {Link} from '../../components/Link';
import {TextField} from '../../components/TextField';
import {Grid, FormHelperText} from '@material-ui/core';
import {withTranslation as withNamespaces, WithTranslation as WithNamespaces} from 'react-i18next';
import {getCurrentLanguage} from '../../i18n';
import ReCAPTCHA from 'react-google-recaptcha';
import CardMedia from '@material-ui/core/CardMedia';
import logo from '../../images/logo-cut.png';

const styles = (theme: Theme) => ({
    card: {
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: '500px',
        [theme.breakpoints.up('sm')]: {
            marginTop: '5vh'
        }
    },
    media: {
        height: 80,
        backgroundSize: 'auto 50px',
        marginTop: 20
    },
    textField: {
        width: '100%'
    },
    recaptcha: {
        marginTop: theme.spacing(2)
    },
    cardActionLink: {
        display: 'block',
        marginTop: '7px',
        marginBottom: '8px'
    },
    announcement: {
        color: theme.palette.primary.dark,
        marginBottom: 10
    }
});

export interface IRegisterComponentProps extends RouteComponentProps, WithNamespaces {
    classes: {
        card: string;
        media: string;
        textField: string;
        recaptcha: string;
        cardActionLink: string;
        announcement: string;
    };
    authState: IAuthState;
    authenticated(token: string, expiresAt: number, loadUserProperties?: boolean): void;
}

export interface IRegisterComponentState {
    isAlreadyAuthenticated: boolean;
}

interface IRegisterFormValues {
    username: string;
    password: string;
    confirmPassword: string;
    recaptcha: string;
}

class RegisterComponent extends Component<IRegisterComponentProps, IRegisterComponentState> {
    recaptchaRef = React.createRef<ReCAPTCHA>();

    state = {
        isAlreadyAuthenticated: false
    };

    validationSchema = Yup.object().shape({
        username: Yup.string()
            .email(this.props.t('RegisterComponent.validation.email').toString())
            .required(this.props.t('RegisterComponent.validation.required').toString()),
        password: Yup.string().required(this.props.t('RegisterComponent.validation.requiredPwd').toString()),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), undefined], this.props.t('RegisterComponent.validation.matchPwd').toString())
            .required(this.props.t('RegisterComponent.validation.confirmPwd').toString()),
        recaptcha: Yup.string()
            .nullable()
            .required(this.props.t('RegisterComponent.validation.recaptcha').toString())
    });

    componentDidMount() {
        const {authState} = this.props;

        if (authState.isAuthenticated) {
            this.setState({isAlreadyAuthenticated: true});
        }
    }

    onSubmit = async (values: FormikValues, formikActions: FormikActions<IRegisterFormValues>) => {
        const {authenticated} = this.props;
        const token = await userApi.register(values.username, values.password, getCurrentLanguage(), values.recaptcha);

        authenticated(token.token, token.expiresAt);

        formikActions.setStatus({success: true});
    };

    onSubmitFailedWithBadRequest = (error: AxiosError, _values: FormikValues, formikActions: FormikActions<IRegisterFormValues>) => {
        if (this.recaptchaRef && this.recaptchaRef.current) {
            this.recaptchaRef.current.reset();
        }

        if (error.response && error.response.data && error.response.data.message) {
            return formikActions.setStatus({error: error.response.data.message});
        }
        return formikActions.setStatus({error: this.props.t('RegisterComponent.error')});
    };

    getFormFields = (props: FormikProps<IRegisterFormValues>) => {
        const {classes, t} = this.props;

        return (
            <>
                <Field
                    name="username"
                    label={t('RegisterComponent.username')}
                    type="email"
                    className={classes.textField}
                    margin="normal"
                    component={TextField}
                />
                <Field
                    name="password"
                    label={t('RegisterComponent.password')}
                    type="password"
                    className={classes.textField}
                    margin="normal"
                    component={TextField}
                />
                <Field
                    name="confirmPassword"
                    label={t('RegisterComponent.confirmPassword')}
                    type="password"
                    className={classes.textField}
                    margin="normal"
                    component={TextField}
                />
                <div className={this.props.classes.recaptcha}>
                    <ReCAPTCHA
                        ref={this.recaptchaRef}
                        sitekey={getConfig.APP_GOOGLE_RECAPTCHA_SITE_KEY}
                        onChange={(value) => props.setFieldValue('recaptcha', value)}
                    />
                    {props.errors.recaptcha && props.touched.recaptcha && (
                        <FormHelperText component="div" error>
                            {props.errors.recaptcha}
                        </FormHelperText>
                    )}
                </div>
            </>
        );
    };

    renderForm = (props: FormikProps<IRegisterFormValues>) => {
        const {classes, t} = this.props;
        const link = getCurrentLanguage() === 'en' ? 'https://www.envion-konkurs.ch/en/' : 'https://www.envion-konkurs.ch';
        const linkText = getCurrentLanguage() === 'en' ? 'www.envion-konkurs.ch/en/' : 'www.envion-konkurs.ch';

        return (
            <React.Fragment>
                <CardContent>
                    {!getConfig.APP_READONLY_MODE ? (
                        <Typography align="center" variant="body2">
                            {t('RegisterComponent.info')}
                        </Typography>
                    ) : null}

                    {props.status && props.status.error && (
                        <Typography color="error">
                            {props.status.error === t('RegisterComponent.usernameExists') ? t('RegisterComponent.alreadyRegistered') : props.status.error}
                        </Typography>
                    )}

                    {!getConfig.APP_READONLY_MODE ? this.getFormFields(props) : null}

                    <div style={{marginTop: 20}}>
                        <Typography variant="body2" style={{marginBottom: 10}}>
                            {t('Maintenance.p1')}
                        </Typography>
                        {/* <Typography variant="body2" style={{marginBottom: 10}}>
                            <span>{t('Maintenance.p2')} </span>
                            <a rel="noopener noreferrer" target="_blank" href={link}>
                                {linkText}
                            </a>
                        </Typography> */}
                        {/* <Typography variant="body2">
                            {t('Maintenance.p3')} <b>{t('Maintenance.p4')}</b> {t('Maintenance.p5')}
                        </Typography>
                        <Typography variant="body2">
                            <b>{t('Maintenance.p6')}</b>
                        </Typography> */}

                        {getConfig.APP_READONLY_MODE && !getConfig.APP_PORTAL_CLOSED ? (
                            <Typography variant="body2">
                                <b>
                                    {/* tslint:disable-next-line: react-no-dangerous-html */}
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: t('ReadonlyMode.announcement', {
                                                mail:
                                                    '<a target="_blank" href="mailto:envion.konkurs@wenger-plattner.ch">envion.konkurs@wenger-plattner.ch</a>',
                                                interpolation: {escapeValue: false}
                                            })
                                        }}
                                    />
                                </b>
                            </Typography>
                        ) : null}
                    </div>

                    {getConfig.APP_PORTAL_CLOSED ? (
                        <div style={{marginTop: 20}}>
                            <Typography variant="body2" className={classes.announcement}>
                                {/* {t('AnnouncementPortalClosed.p1')} <b>{t('AnnouncementPortalClosed.p2')}</b> {t('AnnouncementPortalClosed.p3')} */}
                                {/* <br />
                                <br /> */}
                                {/* tslint:disable-next-line: react-no-dangerous-html */}
                                {/* <span
                                    dangerouslySetInnerHTML={{
                                        __html: t('AnnouncementPortalClosed.p4', {
                                            mail: '<a target="_blank" href="mailto:envion.konkurs@wenger-plattner.ch">envion.konkurs@wenger-plattner.ch</a>',
                                            interpolation: {escapeValue: false}
                                        })
                                    }}
                                />
                                <br />
                                <br /> */}
                                {/* tslint:disable-next-line: react-no-dangerous-html */}
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: t('AnnouncementPortalClosed.p5', {
                                            mail: '<a target="_blank" href="mailto:envion.konkurs@wenger-plattner.ch">envion.konkurs@wenger-plattner.ch</a>',
                                            interpolation: {escapeValue: false}
                                        })
                                    }}
                                />{' '}
                                {t('AnnouncementPortalClosed.p6')}
                                <br />
                                <br />
                                {t('AnnouncementPortalClosed.p7')}{' '}
                                <a rel="noopener noreferrer" target="_blank" href={link}>
                                    {linkText}.
                                </a>
                            </Typography>
                        </div>
                    ) : (
                        <div style={{marginTop: 20}}>
                            <Typography variant="body2" className={classes.announcement}>
                                {t('Announcement.p1')} <b>{t('Announcement.p2')}</b> {t('Announcement.p3')}
                                <br />
                                <br />
                                {t('Announcement.p4')}
                                <br />
                                <br />
                                <u>
                                    <span
                                    dangerouslySetInnerHTML={{
                                        __html: t('Announcement.p5', {
                                            mail: '<a target="_blank" href="mailto:envion.konkurs@wenger-plattner.ch">envion.konkurs@wenger-plattner.ch</a>',
                                            interpolation: {escapeValue: false}
                                        })
                                    }}
                                />
                                </u> {t('Announcement.p6')}
                                <br />
                                <br />
                                {t('Announcement.p7')}{' '}
                                <a rel="noopener noreferrer" target="_blank" href={link}>
                                    {linkText}.
                                </a>
                            </Typography>
                        </div>
                    )}
                </CardContent>

                <CardActions>
                    <Grid container direction="row-reverse" justify="space-between" alignItems="flex-end">
                        {!getConfig.APP_READONLY_MODE ? (
                            <Grid item>
                                <Button type="submit" variant="contained" color="primary" disabled={props.isSubmitting}>
                                    {props.isSubmitting ? t('RegisterComponent.sending') : t('RegisterComponent.register')}
                                </Button>
                            </Grid>
                        ) : null}

                        <Grid item>
                            <Link to="/login" className={classes.cardActionLink}>
                                {t('RegisterComponent.loginInstead')}
                            </Link>
                        </Grid>
                    </Grid>
                </CardActions>
            </React.Fragment>
        );
    };

    renderSuccessMessage() {
        return (
            <CardContent>
                <Typography>{this.props.t('RegisterComponent.successMessage')}</Typography>
            </CardContent>
        );
    }

    formikFormRenderer = (props: FormikProps<IRegisterFormValues>) => {
        const {classes, t} = this.props;

        return (
            <Form>
                <Card className={classes.card}>
                    <CardMedia className={classes.media} image={logo} />
                    <CardHeader
                        title={props.status && props.status.success ? t('RegisterComponent.thanks') : t('RegisterComponent.register')}
                        titleTypographyProps={{align: 'center'}}
                    />
                    {props.status && props.status.success ? this.renderSuccessMessage() : this.renderForm(props)}
                </Card>
            </Form>
        );
    };

    render() {
        const {isAlreadyAuthenticated} = this.state;

        if (isAlreadyAuthenticated) {
            return <Redirect to="/" />;
        }

        return (
            <React.Fragment>
                <Helmet>
                    <title>{this.props.t('RegisterComponent.title')}</title>
                </Helmet>
                <Formik
                    initialValues={{username: '', password: '', confirmPassword: '', recaptcha: ''}}
                    validationSchema={this.validationSchema}
                    onSubmit={formikSubmitHandler(this.onSubmit, {forbidden: this.onSubmitFailedWithBadRequest})}
                >
                    {this.formikFormRenderer}
                </Formik>
            </React.Fragment>
        );
    }
}

const StyledRegisterComponent = withStyles(styles)(RegisterComponent);
const I18nRegisterComponent = withNamespaces()(StyledRegisterComponent);

const mapStateToProps = ({auth}: RootState) => ({
    authState: auth
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    authenticated: (token: string, expiresAt: number, loadUserProperties = false) => dispatch(authenticatedAction(token, expiresAt, loadUserProperties))
});

export const Register = withRouter(connect(mapStateToProps, mapDispatchToProps)(I18nRegisterComponent));
