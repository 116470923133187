import React from 'react';
import getConfig from '../../config';
import Lock from '@material-ui/icons/Lock';
import LockOpen from '@material-ui/icons/LockOpen';

interface IProps {
    locked?: boolean;
}

export class LockIcon extends React.PureComponent<IProps> {
    render() {
        const {locked} = this.props;

        if (locked) {
            return <Lock />;
        }

        return getConfig.APP_READONLY_MODE ? <Lock /> : <LockOpen />;
    }
}
