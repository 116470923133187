import {Languages} from '../../../i18n';

export const tokenPurchaseData = (lang: string) => {
    if (lang === Languages.DE) {
        return [
            [
                'Wo haben Sie bzw. Ihr Mandant die EVN-Tokens erworben?',
                'Bitte wählen Sie zwischen:' +
                    '<ul>' +
                    '<li>ICO Teilnehmer\t(Sie bzw. Ihr Mandant haben am ICO teilgenommen)</li>' +
                    '<li>Sekundärmarkt\t(Sie bzw. Ihr Mandant haben EVN-Tokens über eine Börse gekauft)</li>' +
                    '<li>Privatperson\t(Sie bzw. Ihr Mandant haben EVN-Tokens bei einer Privatperson erworben)</li>' +
                    '<li>Vorverkauf / Privatplatzierung\t(Sie bzw. Ihr Mandant haben EVN-Tokens im Rahmen des Pre-Sale erworben)</li>' +
                    '<li>Gründer\t(Sie bzw. Ihr Mandant haben Ihre Token als Gründer erhalten)</li>' +
                    '<li>Unterschiedliche Quellen\t(Sie bzw. Ihr Mandant haben EVN-Tokens über unterschiedliche Quellen gekauft)</li>' +
                    '<li>Anderweitig</li>' +
                    '</ul>' +
                    '<p><b>Bitte laden Sie die Erwerbsdokumente hoch.</b></p>' +
                    '<p>Sie müssen zur Begründung Ihrer Forderungen bzw. denjenigen Ihres Mandanten Dokumente vorlegen, die den Erwerb belegen, z.B. Kreditkartenabrechnungen, Kontoauszüge, Screenshots aus dem envion Dashboard oder andere Dokumente, die deutlich machen, wie Sie / Ihr Mandant die EVN-Tokens erworben haben.</p>' +
                    '<p>Beispiel:<br />Sie bzw. Ihr Mandant hat im Rahmen des ICO EVN-Tokens erworben und diese mit seiner Kreditkarte bezahlt.</p>' +
                    '<p>Sie müssen die Kreditkartenabrechnung hochladen, aus der hervorgeht, dass die Zahlung an die envion-Zahlstelle übermittelt wurde.</p>' +
                    '<p>Wenn Sie bzw. Ihr Mandant die EVN-Tokens im Rahmen des ICO erworben und mit Bitcoin bezahlt hat, geben Sie bitte Ihre/seine Bitcoin-Adresse und die dazugehörige Transaktions-ID an.</p>' +
                    '<p>Darüber hinaus fügen Sie bitte alle E-Mails oder Screenshots hinzu, welche die Zuordnung der EVN-Tokens belegen.</p>'
            ],
            [
                'Was ist die Option "Keine Erwerbsdokumente"?',
                'Bitte laden Sie das Formular "Keine Erwerbsdokumente" herunter, sofern Sie bzw. Ihr Mandant über keine Erwerbsdokumente verfügen, die den Tokenerwerb belegen. Füllen Sie das Formular bitte leserlich in Grossbuchstaben aus, unterzeichnen es und laden es anschließend hoch.'
            ],
            [
                'Welches Format wird unterstützt?',
                'Bitte laden Sie Ihr Dokument als PNG oder in Form eines JPG oder PDF mit einer maximalen Größe von 3MB hoch.'
            ],
            [
                'Ich habe versehentlich das falsche Dokument hochgeladen, was soll ich tun?',
                'Nachdem Sie Ihr Dokument hochgeladen haben, sehen Sie daneben ein "Mülleimer"-Symbol. Drücken Sie dieses und das Dokument wird gelöscht. Wenn Sie bereits auf "Weiter" geklickt haben, können Sie mit der Schaltfläche "Zurück" zu der Seite zurückkehren, auf der Sie ein bestimmtes Dokument ersetzen/löschen möchten. Das Dokument wird in der oberen Zeile angezeigt. Klicken Sie auf das Dokument, um die Dokumentenvorschau zu öffnen. Anschließend können Sie das Dokument löschen, indem Sie auf die Schaltfläche "Löschen", unten rechts klicken.'
            ],
            ['Wie viele Dokumente kann ich hochladen?', 'Sie können hier maximal 10 Dokumente hochladen.'],
            [
                'Mein Weiter/Zurück Button hat sich grau verfärbt und ich kann den Prozess nicht fortsetzen',
                'Wenn Ihre Dateinamen oder Ihr eingegebener Text die vorgegebene Zeichenanzahl überschreitet kann es dazu kommen, dass sie den Prozess nicht fortführen können. In diesem Fall bitten wir Sie darum ihre Dateinamen sowie Texte zu kürzen und diese erneut im Portal hochzuladen.'
            ],
            [
                'Das Kommentarfeld ist nicht gross genug',
                'Wenn das Kommentarfeld nicht gross genug ist, um die erforderlichen Informationen einzugeben, laden Sie bitte ein separates Dokument im Abschnitt "Zusätzliche Dokumente" hoch und geben Sie die Information "siehe separates Dokument" im Kommentarfeld an.'
            ],
            [
                'Ich habe Probleme mit dem Portal, was kann ich von meiner Seite aus machen?',
                'Bitte stellen Sie sicher, dass Sie Ihren Browser-Cache geleert haben, bevor Sie das Portal nutzen.'
            ]
        ];
    }

    return [
        [
            'Where did you or your client(s) purchase the EVN tokens?',
            'Please choose between:' +
                '<ul>' +
                '<li>ICO subscribers\t(you / your client(s) participated in the ICO)</li>' +
                '<li>secondary market\t(you / your client(s) purchased tokens via an exchange platform)</li>' +
                '<li>private person\t(you / your client(s) purchased tokens from a private person)</li>' +
                '<li>pre-sale / private placement\t(you / your client(s) has acquired tokens as part of the pre-sale)</li>' +
                '<li>founders\t(you / your client(s) received founders’ tokens)</li>' +
                '<li>multiple sources\t(you / your client(s) purchased tokens from multiple sources)</li>' +
                '<li>other</li>' +
                '</ul>' +
                '<p><b>Please upload documents related to the purchase</b></p>' +
                '<p>In order to substantiate your claims or those of your client(s), you will have to submit documents proving the contribution/purchase e.g. credit card statements, bank statements, envion dashboard screenshots, exchange confirmations or any other documents clearly showing how you or your client(s) purchased the EVN tokens.</p>' +
                '<p>Example:<br />You or your client(s) purchased EVN tokens in the "main tokensale” (ico) and used a credit card to pay for a contribution.</p>' +
                '<p>You need to upload the credit card statement proving the payment transferred towards the envion payment provider. In addition, please add all e-mails or screenshots which prove the assignment of the EVN token.</p>' +
                '<p>If you or your client(s) have contributed to the main token sale and paid in Bitcoin for example, then please provide your Bitcoin address and the transaction ID.</p>'
        ],
        [
            'What is the “No purchase documents” option?',
            'Please download the "No purchase documents" form if you or your client(s) do not have any documents that can support the purchase of tokens. Please fill out the form legibly in capital letters, sign it and upload it.'
        ],
        ['Which format will be supported?', 'Please upload your document in the form of either PNG, JPG or PDF with a maximum size of 3MB.'],
        [
            'I have accidentally uploaded the wrong document, what should I do?',
            'After you have uploaded your document you will see a “bin” icon next to it, press that and the document will be deleted. If you have already clicked “next”, you can use the “back” button to go back to the page where you would like to replace/delete a particular document. The document will appear in the upper line. Click it to open the document viewer, and you can then delete the document by pressing the “delete” button on the bottom right.'
        ],
        ['How many documents can I upload?', 'You can upload a maximum of 10 documents here.'],
        [
            'My Next/Back button has turned gray and I cannot continue the process',
            'If your file name or text exceeds the specified number of characters, you may not be able to continue the process. In this case we ask you to reduce your file names and texts and upload them to the portal again.'
        ],
        [
            'The comment field is not wide enough',
            'If the comment field is not wide enough to enter the required information, please upload a separate document in the "Additional documents" section and indicate "see separate document" in the comment field.'
        ],
        ['I have issues with the portal, what can I do from my side?', 'Please insure you cleared your browser cache before using the portal.']
    ];
};
