import React from 'react';
import {withStyles, Card, CardHeader, CardContent, CardActions, Button, Grid} from '@material-ui/core';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {RootState} from '../../../store';
import {IClaimantsState, ClaimantStatus} from '../../../store/claimants/claimants.types';
import {IAuthState} from '../../../store/auth/auth.types';
import {selectClaimantId as selectClaimantIdAction} from '../../../store/claimants/claimants.actions';
import {IAccountState, AppRoles} from '../../../store/account/account.types';
import {styles, IClassesProperty} from '../styles';
import Helmet from 'react-helmet';
import {LinkButton} from '../../../components/LinkButton';
import {withTranslation as withNamespaces, WithTranslation as WithNamespaces} from 'react-i18next';
import {setFaq as setFaqAction} from '../../../store/faq/faq.actions';
import {IFaqState} from '../../../store/faq/faq.types';
import {LockIcon} from '../../../components/LockIcon';
import getConfig from '../../../config';
import {ClaimantInfo} from '../../../components/ClaimantInfo';

export interface ICompleteStepProps extends WithNamespaces {
    account: IAccountState;
    classes: IClassesProperty;
    auth: IAuthState;
    claimants: IClaimantsState;
    status: string;
    selectClaimantId(claimantId: string | null): void;
    setFaq(type: IFaqState): void;
}

class CompleteStepComponent extends React.Component<ICompleteStepProps> {
    componentDidMount() {
        const {setFaq} = this.props;
        switch (this.props.status) {
            case 'review':
            default:
                setFaq({type: ClaimantStatus.REVIEW});
                break;
            case 'complete':
                setFaq({type: ClaimantStatus.COMPLETE});
                break;
            case 'wrong':
                setFaq({type: ClaimantStatus.INCOMPLETE});
        }
    }

    getRole = () => {
        const {account, t} = this.props;

        if (!account.properties) {
            throw new Error(t('Roles.invalid').toString());
        }

        return account.properties.role;
    };

    getStatus() {
        const {status, t} = this.props;
        const claimant = this.getClaimant();
        const locked = getConfig.APP_READONLY_MODE || claimant.locked ? true : false;

        switch (status) {
            case 'review':
                return {
                    title: t('CompleteStep.review.title'),
                    body: t('CompleteStep.review.body'),
                    action: false,
                    locked
                };
            case 'complete':
                return {title: t('CompleteStep.approved.title'), body: t('CompleteStep.approved.body'), action: false, locked};
            case 'wrong':
                return {
                    title: t('CompleteStep.wrong.title'),
                    body: t('CompleteStep.wrong.body'),
                    action: !locked,
                    locked
                };
            default:
                return {title: 'default case', body: 'default case', action: false, locked};
        }
    }

    getClaimant = () => {
        const {claimants} = this.props;
        return claimants.selectedClaimantId ? claimants.allClaimants[claimants.selectedClaimantId] : claimants.allClaimants[claimants.byId[0]];
    };

    handleAction = () => {
        const {selectClaimantId} = this.props;
        const claimant = this.getClaimant();

        claimant.status = ClaimantStatus.PERSONAL_INFORMATION;
        selectClaimantId(claimant.id);
    };

    render() {
        const {classes, t, auth} = this.props;
        const {title, action, locked, body} = this.getStatus();
        const role = this.getRole();
        const claimant = this.getClaimant();

        const _title = (
            <>
                <span style={{verticalAlign: 'middle'}}>
                    <LockIcon locked={locked} />
                </span>
                <span style={{marginLeft: 10, marginRight: 10}}>{title}</span>
            </>
        );

        return (
            <React.Fragment>
                <Helmet>
                    <title>{title}</title>
                </Helmet>

                <Card className={classes.card}>
                    <CardHeader
                        classes={{
                            root: classes.cardHeader,
                            title: classes.cardHeaderTitle
                        }}
                        title={claimant.locked ? _title : title}
                        style={{textAlign: 'center'}}
                    />

                    <CardContent>
                        <ClaimantInfo claimant={claimant} auth={auth} role={role} body={body} />
                    </CardContent>

                    {role === AppRoles.CLAIMANT_LEGAL_REPRESENTATIVE ? (
                        action ? (
                            <CardActions>
                                <Grid direction="row-reverse" container justify="space-between" alignItems="flex-end">
                                    <Grid item>
                                        <Button onClick={this.handleAction} color="primary" variant="contained">
                                            {t('CompleteStep.continue')}
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <LinkButton to="/" color="primary" variant="contained">
                                            {t('CompleteStep.back')}
                                        </LinkButton>
                                    </Grid>
                                </Grid>
                            </CardActions>
                        ) : (
                            <CardActions>
                                <Grid direction="row-reverse" container justify="space-between" alignItems="flex-end">
                                    <Grid item>
                                        <LinkButton to="/" color="primary" variant="contained">
                                            {t('CompleteStep.back')}
                                        </LinkButton>
                                    </Grid>
                                </Grid>
                            </CardActions>
                        )
                    ) : action ? (
                        <CardActions>
                            <Grid direction="row-reverse" container justify="space-between" alignItems="flex-end">
                                <Grid item>
                                    <Button onClick={this.handleAction} color="primary" variant="contained">
                                        {t('CompleteStep.continue')}
                                    </Button>
                                </Grid>
                            </Grid>
                        </CardActions>
                    ) : null}
                </Card>
            </React.Fragment>
        );
    }
}

const StyledCompleteStepComponent = withStyles(styles)(CompleteStepComponent);
const I18nCompleteStepComponent = withNamespaces()(StyledCompleteStepComponent);

const mapStateToProps = ({auth, account, claimants}: RootState) => ({
    auth,
    account,
    claimants
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    selectClaimantId: (claimantId: string) => dispatch(selectClaimantIdAction(claimantId)),
    setFaq: (type: IFaqState) => dispatch(setFaqAction(type))
});

export const CompleteStep = connect(mapStateToProps, mapDispatchToProps)(I18nCompleteStepComponent);
