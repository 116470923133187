import React, {FunctionComponent} from 'react';
import {Link} from 'react-router-dom';
import classNames from 'classnames';
import {Property} from 'csstype';
import {withStyles} from '@material-ui/core/styles';
import {Theme} from '@material-ui/core/styles/createMuiTheme';
import logo from '../../../images/logo.png';

const styles = (theme: Theme) => ({
    logoLink: {
        userSelect: 'none' as Property.UserSelect,
        display: 'block',
        color: theme.palette.common.white,
        width: 'auto',
        [theme.breakpoints.up('md')]: {
            width: 130
        }
    },
    logoLinkA: {
        transition: 'all .2s ease-in-out',
        '&:hover': {
            transform: 'scale(1.02)'
        }
    },
    logo: {
        marginRight: 10,
        height: 32,
        float: 'left' as Property.Float
    }
});

export interface ILogoComponentProps {
    classes: {
        logoLink: string;
        logoLinkA: string;
        logo: string;
    };
    className?: string;
}

const LogoComponent: FunctionComponent<ILogoComponentProps> = (props) => {
    const {classes} = props;

    return (
        <Link to="/" className={classNames(classes.logoLink, classes.logoLinkA, props.className)}>
            <img className={classes.logo} src={logo} alt="" />
        </Link>
    );
};

export const Logo = withStyles(styles)(LogoComponent);
