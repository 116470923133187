import {axios} from '../../../axios';
import config from '../../../config';
import {ITokenResponse, IToken} from '../auth/types';
import {authUtilities} from '../auth';
import {FormikValues} from 'formik';
import {IClaimant, IFile} from '../../../store/claimants/claimants.types';
import {IClaim} from '../../../store/claims/claims.types';
import {IAccountProperties} from '../../../store/account/account.types';

export const get = async (token: string) => {
    return axios.get(`${config.APP_API_ENDPOINT}/claimant-ui/user`, {
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            Authorization: `Bearer ${token}`
        }
    });
};

export const verifyEmailAddress = async (token: string, id: string) => {
    return axios.post(
        `${config.APP_API_ENDPOINT}/claimant-ui/user/verify-email-address`,
        {id},
        {
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                Authorization: `Bearer ${token}`
            }
        }
    );
};

export const changePasswordRequest = async (values: FormikValues, preferredLanguage: string, recaptcha: any) => {
    return axios.post(`${config.APP_API_ENDPOINT}/claimant-ui/user/change-password-request`, {
        ...values,
        preferredLanguage,
        recaptcha
    });
};

export const changePassword = async (ticketId: string, values: FormikValues) => {
    return axios.post(`${config.APP_API_ENDPOINT}/claimant-ui/user/change-password`, {
        ticketId,
        password: values.password
    });
};

export const validateChangePasswordTicket = async (ticketId: string) => {
    return axios.post(`${config.APP_API_ENDPOINT}/claimant-ui/user/validate-change-password-ticket`, {id: ticketId});
};

export const updatePassword = async (values: FormikValues, token: string | null) => {
    return axios.post(`${config.APP_API_ENDPOINT}/claimant-ui/user/update-password`, values, {
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            Authorization: `Bearer ${token}`
        }
    });
};

export const register = async (username: string, password: string, preferredLanguage: string, recaptcha: any): Promise<IToken> => {
    const response = await axios.post<ITokenResponse>(`${config.APP_API_ENDPOINT}/claimant-ui/user/register`, {
        username,
        password,
        preferredLanguage,
        recaptcha
    });

    const expiresAt = authUtilities.calculateExpiresAtDate(response.data.expiresIn);

    return {
        token: response.data.token,
        expiresAt
    };
};

export const acceptLegalNotice = async (token: string | null): Promise<IAccountProperties> => {
    const response = await axios.post<IAccountProperties>(
        `${config.APP_API_ENDPOINT}/claimant-ui/user/accept-legal-notice`,
        {},
        {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
    );

    return response.data;
};

export const setUserAsClaimant = async (token: string | null): Promise<IToken> => {
    const response = await axios.patch<ITokenResponse>(
        `${config.APP_API_ENDPOINT}/claimant-ui/user/set-user-as-claimant`,
        {},
        {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
    );

    const expiresAt = authUtilities.calculateExpiresAtDate(response.data.expiresIn);

    return {
        token: response.data.token,
        expiresAt
    };
};

export const setUserAsLegalRepresentative = async (values: FormikValues, token: string | null): Promise<IToken> => {
    const response = await axios.post<ITokenResponse>(`${config.APP_API_ENDPOINT}/claimant-ui/user/set-user-as-legal-representative`, values, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });

    const expiresAt = authUtilities.calculateExpiresAtDate(response.data.expiresIn);

    return {
        token: response.data.token,
        expiresAt
    };
};

export const setUserAsLegalRepresentativeComplete = async (token: string | null): Promise<IToken> => {
    const response = await axios.post<ITokenResponse>(
        `${config.APP_API_ENDPOINT}/claimant-ui/user/set-user-as-legal-representative-complete`,
        {},
        {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
    );

    const expiresAt = authUtilities.calculateExpiresAtDate(response.data.expiresIn);

    return {
        token: response.data.token,
        expiresAt
    };
};

export const createClaimant = async (values: FormikValues, token: string | null): Promise<IClaimant> => {
    const response = await axios.post<IClaimant>(`${config.APP_API_ENDPOINT}/claimant-ui/claimants/user-information`, values, {
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            Authorization: `Bearer ${token}`
        }
    });

    return response.data;
};

export const uploadFile = async (formData: FormData, token: string | null): Promise<IFile[]> => {
    const response = await axios.post(`${config.APP_API_ENDPOINT}/claimant-ui/claimants/upload`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`
        }
    });

    return response.data;
};

export const deleteFile = async (values: {id: string; type: string; file: string; path: string; status: string}, token: string | null) => {
    const response = await axios.delete(
        `${config.APP_API_ENDPOINT}/claimant-ui/claimants/file/${values.id}/${values.path}/${values.type}/${values.status}/${values.file}`,
        {
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                Authorization: `Bearer ${token}`
            }
        }
    );

    return response.data;
};

export const getSecuredBlob = async (path: string, contentType: string, token: string | null) => {
    try {
        const response = await axios.get(`${config.APP_API_ENDPOINT}/claimant-ui/claimants/file/${path}`, {
            headers: {
                ContentType: contentType,
                Authorization: `Bearer ${token}`
            },
            responseType: 'blob'
        });
        return response.data;
    } catch (error) {
        // @TODO: do proper error handling
        console.error('Failed to load file.', error);
        // tslint:disable-next-line: no-return-undefined
        return new Blob();
    }
};

export const patchClaimant = async (values: FormikValues, step: string, claimantId: string | null, token: string | null): Promise<IClaimant> => {
    // If claimantId is not NULL, the requests is triggered by an legal representative
    const requestValues = claimantId ? {...values, id: claimantId} : values;

    const response = await axios.patch<IClaimant>(`${config.APP_API_ENDPOINT}/claimant-ui/claimants/${step}`, requestValues, {
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            Authorization: `Bearer ${token}`
        }
    });

    return response.data;
};

export const patchUserPassport = async (values: FormikValues, token: string | null) => {
    const response = await axios.post(`${config.APP_API_ENDPOINT}/claimant-ui/user/add-user-passport`, values, {
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            Authorization: `Bearer ${token}`
        }
    });

    return response.data;
};

export const uploadLegalRepPassport = async (formData: FormData, token: string | null): Promise<IFile> => {
    const response = await axios.post(`${config.APP_API_ENDPOINT}/claimant-ui/user/upload-passport`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`
        }
    });

    return response.data;
};

export const getClaims = async (token: string, claimantId: string) => {
    return axios.get(`${config.APP_API_ENDPOINT}/claimant-ui/claims?claimantId=${claimantId}`, {
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            Authorization: `Bearer ${token}`
        }
    });
};

export const createClaim = async (values: FormikValues, token: string | null): Promise<IClaim> => {
    const response = await axios.post<IClaim>(`${config.APP_API_ENDPOINT}/claimant-ui/claims/createClaim`, values, {
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            Authorization: `Bearer ${token}`
        }
    });

    return response.data;
};

export const updateClaim = async (values: FormikValues, token: string | null): Promise<IClaim> => {
    const response = await axios.patch<IClaim>(`${config.APP_API_ENDPOINT}/claimant-ui/claims/updateClaim`, values, {
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            Authorization: `Bearer ${token}`
        }
    });

    return response.data;
};

export const deleteClaim = async (claimId: string, token: string | null): Promise<void> => {
    const response = await axios.delete(`${config.APP_API_ENDPOINT}/claimant-ui/claims/deleteClaim` + claimId, {
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            Authorization: `Bearer ${token}`
        }
    });

    return response.data;
};
