import React, {Component} from 'react';
import {Dispatch} from 'redux';
import {connect} from 'react-redux';
import {Form, Formik, Field, FormikProps, FormikValues, FormikErrors} from 'formik';
import * as Yup from 'yup';
import {formikSubmitHandler, ConvertValidationErrorsFunction} from '../../../utils';
import {withStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import {CardActions, Button} from '@material-ui/core';
import {TextField} from '../../../components/TextField';
import {RootState} from '../../../store';
import {IAuthState} from '../../../store/auth/auth.types';
import {IAccountState /*, IAccountProperties*/} from '../../../store/account/account.types';
import {styles, IClassesProperty} from '../styles';
import {updatePassword} from '../../../libs/api/user/user';
import {withTranslation as withNamespaces, WithTranslation as WithNamespaces} from 'react-i18next';
import {Redirect} from 'react-router';

export interface IPasswordChangeProps extends WithNamespaces {
    classes: IClassesProperty;
    account: IAccountState;
    auth: IAuthState;
}
interface IPasswordChangeFormValues {
    currentPassword: string;
    newPassword: string;
    retypeNewPassword: string;
}

class PasswordChangeComponent extends Component<IPasswordChangeProps> {
    state = {
        pwdChanged: false
    };

    validationSchema = Yup.object().shape({
        currentPassword: Yup.string().required(this.props.t('PasswordChangeComponent.validation.currentPassword').toString()),
        newPassword: Yup.string()
            .min(8, this.props.t('PasswordChangeComponent.validation.newPasswordMin').toString())
            .required(this.props.t('PasswordChangeComponent.validation.newPasswordRequired').toString()),
        retypeNewPassword: Yup.string().required(this.props.t('PasswordChangeComponent.validation.retypeNewPassword').toString())
    });

    onSubmit = async (values: FormikValues) => {
        const {auth} = this.props;

        await updatePassword(values, auth.token);
        this.setState({pwdChanged: true});
    };

    stripFirstWord = (str: string) => {
        return str.substr(str.indexOf(' ') + 1);
    };

    convertValidationErrors: ConvertValidationErrorsFunction<FormikValues> = (validationErrors) => {
        return validationErrors.reduce<any>((result, validationError) => {
            const constrainKeys = Object.keys(validationError.constraints);

            if (constrainKeys.length > 1) {
                result[validationError.property] = (
                    <React.Fragment>
                        <ul style={{paddingLeft: 15, marginTop: 0}}>
                            {constrainKeys.map((constrainKey) => (
                                <li key={constrainKey}>{this.stripFirstWord(validationError.constraints[constrainKey])}.</li>
                            ))}
                        </ul>
                    </React.Fragment>
                );
            } else if (constrainKeys.length === 1) {
                result[validationError.property] = `${this.stripFirstWord(validationError.constraints[constrainKeys[0]])}.`;
            }

            return result;
        }, {});
    };

    validate = (values: IPasswordChangeFormValues) => {
        const errors: FormikErrors<IPasswordChangeFormValues> = {};

        if (values.newPassword !== values.retypeNewPassword) {
            errors.retypeNewPassword = this.props.t('PasswordChangeComponent.errors.retypeNewPassword').toString();
        }

        return errors;
    };

    handleBack = () => {
        this.setState({pwdChanged: true});
    };

    renderForm = (_: FormikProps<IPasswordChangeFormValues>) => {
        const {classes, t} = this.props;

        if (this.state.pwdChanged) {
            return <Redirect to="/" />;
        }

        return (
            <Form>
                <Card className={classes.card}>
                    <CardHeader
                        classes={{
                            root: classes.cardHeader,
                            title: classes.cardHeaderTitle
                        }}
                        title={t('PasswordChangeComponent.title')}
                    />
                    <CardContent>
                        <Grid container direction="column" spacing={3}>
                            <Grid item md={6}>
                                <Field name="currentPassword" label={t('PasswordChangeComponent.currentPassword')} type="password" component={TextField} />
                            </Grid>
                            <Grid item md={6}>
                                <Field name="newPassword" label={t('PasswordChangeComponent.newPassword')} type="password" component={TextField} />
                            </Grid>
                            <Grid item md={6}>
                                <Field name="retypeNewPassword" label={t('PasswordChangeComponent.retypeNewPassword')} type="password" component={TextField} />
                            </Grid>
                        </Grid>
                    </CardContent>
                    <CardActions>
                        <Grid container direction="row-reverse" justify="space-between" alignItems="flex-end" style={{marginTop: 20}}>
                            <Grid item>
                                <Button disabled={this.state.pwdChanged} type="submit" color="primary" variant="contained">
                                    {this.state.pwdChanged ? t('PasswordChangeComponent.done') : t('PasswordChangeComponent.submit')}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button onClick={this.handleBack} type="button" color="default" variant="contained">
                                    {t('Navigation.back')}
                                </Button>
                            </Grid>
                        </Grid>
                    </CardActions>
                </Card>
            </Form>
        );
    };

    render() {
        const initialValues = {
            currentPassword: '',
            newPassword: '',
            retypeNewPassword: ''
        };

        return (
            <Formik
                initialValues={initialValues}
                validate={this.validate}
                validationSchema={this.validationSchema}
                onSubmit={formikSubmitHandler(this.onSubmit, {convertValidationErrors: this.convertValidationErrors})}
                enableReinitialize={true}
            >
                {this.renderForm}
            </Formik>
        );
    }
}

export const StyledPasswordChangeComponent = withStyles(styles)(PasswordChangeComponent);
const I18nPasswordChangeComponent = withNamespaces()(StyledPasswordChangeComponent);

const mapStateToProps = ({auth, account}: RootState) => ({
    auth,
    account
});

const mapDispatchToProps = (_dispatch: Dispatch) => ({});

export const PasswordChange = connect(mapStateToProps, mapDispatchToProps)(I18nPasswordChangeComponent);
