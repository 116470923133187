// tslint:disable:file-name-casing

import React from 'react';
import ReactDOM from 'react-dom';
import Raven from 'raven-js';
import {Provider} from 'react-redux';
import {ConnectedRouter} from 'connected-react-router';
import {store} from './store';
import {getHistory} from './history';
import * as serviceWorker from './serviceWorker';
import {Root} from './containers/Root';
import Routes from './containers/Routes/Routes';
import {I18nextProvider} from 'react-i18next';
import {i18n} from './i18n';
import getConfig from './config';

// tslint:disable-next-line: no-import-side-effect
import 'typeface-roboto';

// Init Sentry
if (getConfig.APP_SENTRY_IS_ACTIVE && getConfig.APP_SENTRY_DSN) {
    Raven.config(getConfig.APP_SENTRY_DSN).install();
}

ReactDOM.render(
    <Provider store={store}>
        <I18nextProvider i18n={i18n}>
            <ConnectedRouter history={getHistory()}>
                <Root>
                    <Routes />
                </Root>
            </ConnectedRouter>
        </I18nextProvider>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
