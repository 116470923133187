import React from 'react';
import {Dispatch} from 'redux';
import {connect} from 'react-redux';
import {withStyles, Grid, Card, CardHeader, CardContent, CardActions, Button} from '@material-ui/core';
import {styles, IClassesProperty} from './styles';
import {RootState} from '../../store';
import {IAuthState} from '../../store/auth/auth.types';
import {authenticated as authenticatedAction} from '../../store/auth/auth.actions';
import {IAccountState} from '../../store/account/account.types';
import {patchUserPassport, uploadLegalRepPassport, setUserAsLegalRepresentativeComplete} from '../../libs/api/user/user';
import {DropzoneArea} from 'material-ui-dropzone';
import Helmet from 'react-helmet';
import {Redirect} from 'react-router';
import {showSnackBar as showSnackBarAction} from '../../store/snackbar/snackbar.actions';
import {ISnackBarState} from '../../store/snackbar/snackbar.types';
import {withTranslation as withNamespaces, WithTranslation as WithNamespaces} from 'react-i18next';

export interface IPassportStepProps extends WithNamespaces {
    classes: IClassesProperty;
    account: IAccountState;
    auth: IAuthState;
    authenticated(token: string, expiresAt: number, loadUserProperties?: boolean): void;
    showSnackBar(payload: ISnackBarState): void;
}

class UploadPassportComponent extends React.Component<IPassportStepProps> {
    _isMounted = false;

    state = {
        files: [],
        redirect: false
    };

    componentDidMount = () => {
        this._isMounted = true;
    };

    componentWillUnmount = () => {
        this._isMounted = false;
    };

    showError = (error: string) => {
        this.props.showSnackBar({message: error, variant: 'error'});
    };

    onSubmit = async () => {
        const {files} = this.state;
        const {auth, account, authenticated, t} = this.props;

        if (!account.properties || !account.properties.legalRepresentativeDetails) {
            return;
        }

        try {
            // File upload
            const payload = new FormData();
            payload.append('userId', account.properties.legalRepresentativeDetails.userId);
            files.forEach((file) => {
                payload.append('files', file);
            });

            const uploadedPassport = await uploadLegalRepPassport(payload, auth.token);

            if (!uploadedPassport) {
                throw new Error(t('Upload.failed').toString());
            }

            const values = {
                userId: account.properties.legalRepresentativeDetails.userId,
                passport: uploadedPassport
            };

            const updatedLegalRep = await patchUserPassport(values, auth.token);

            if (!updatedLegalRep) {
                throw new Error(t('Upload.failed').toString());
            }

            const {token, expiresAt} = await setUserAsLegalRepresentativeComplete(auth.token);
            authenticated(token, expiresAt, true);

            if (this._isMounted) {
                this.setState({redirect: true});
            }
        } catch (error) {
            console.error(error);
            this.showError(t('Upload.failed').toString());
        }
    };

    handleChange = (files: []) => {
        this.setState({
            files
        });
    };

    handleFilesRejected = (_files: []) => {
        this.props.showSnackBar({message: `${this.props.t('Upload.invalid')}.`, variant: 'error'});
    };

    render() {
        const {classes, t} = this.props;
        const {files} = this.state;

        if (this.state.redirect) {
            return <Redirect to="/" />;
        }

        // Hack to resolve issues with i18n and missing error message if file count exceed, but file size is ok
        const DZ = DropzoneArea as any;

        return (
            <React.Fragment>
                <Helmet>
                    <title>{t('LegalRepUploadPassport.title')}</title>
                </Helmet>

                <Card className={classes.card}>
                    <CardHeader
                        classes={{
                            root: classes.cardHeader,
                            title: classes.cardHeaderTitle
                        }}
                        title={t('LegalRepUploadPassport.title')}
                        style={{textAlign: 'center'}}
                    />

                    <CardContent className={this.props.classes.dropZoneWrapper}>
                        <DZ
                            getFileLimitExceedMessage={() => t('Upload.getFileLimitExceedMessage2')}
                            getFileAddedMessage={() => t('Upload.getFileAddedMessage')}
                            getFileRemovedMessage={() => t('Upload.getFileRemovedMessage')}
                            getDropRejectMessage={() => t('Upload.getDropRejectMessage')}
                            showAlerts={true}
                            onDropRejected={this.handleFilesRejected}
                            onChange={this.handleChange}
                            acceptedFiles={['image/*', 'application/pdf']}
                            dropzoneText={t('LegalRepUploadPassport.dropzoneText').toString()}
                            filesLimit={2}
                            maxFileSize={10000000}
                            dropzoneClass={this.props.classes.dropZoneCentered}
                            showPreviewsInDropzone={false}
                            showPreviews={true}
                            dropzoneParagraphClass={this.props.classes.dropZoneParagraph}
                            showFileNamesInPreview={true}
                        />
                    </CardContent>

                    <CardActions>
                        <Grid container justify="flex-end">
                            <Grid item>
                                <Button onClick={this.onSubmit} disabled={files.length === 0} type="button" color="primary" variant="contained">
                                    {t('Navigation.next')}
                                </Button>
                            </Grid>
                        </Grid>
                    </CardActions>
                </Card>
            </React.Fragment>
        );
    }
}

const StyledUploadPassportComponent = withStyles(styles)(UploadPassportComponent);
const I18nUploadPassportComponent = withNamespaces()(StyledUploadPassportComponent);

const mapStateToProps = ({auth, account}: RootState) => ({
    auth,
    account
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    authenticated: (token: string, expiresAt: number, loadUserProperties?: boolean) => dispatch(authenticatedAction(token, expiresAt, loadUserProperties)),
    showSnackBar: (payload: ISnackBarState) => dispatch(showSnackBarAction(payload))
});

export const UploadPassport = connect(mapStateToProps, mapDispatchToProps)(I18nUploadPassportComponent);
