import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {Grid, createStyles, Theme, CircularProgress} from '@material-ui/core';

const styles = (_theme: Theme) =>
    createStyles({
        text: {
            textAlign: 'center'
        }
    });

class ActionPending extends Component<{classes: any; text: string}> {
    render() {
        const {classes, text} = this.props;

        return (
            <Grid container spacing={0} direction="column" alignItems="center" justify="center" style={{minHeight: '70vh'}}>
                <Grid item xs={12}>
                    <CircularProgress size={60} />
                </Grid>
                <Grid item xs={12}>
                    <Typography className={classes.text} variant="h6">
                        {text}
                    </Typography>
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles)(ActionPending);
