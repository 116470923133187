import React, {Component} from 'react';
import {withTranslation as withNamespaces, WithTranslation as WithNamespaces} from 'react-i18next';
import {connect} from 'react-redux';
import {RootState} from '../../../store';
import {IAuthState} from '../../../store/auth/auth.types';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import AccountCircle from '@material-ui/icons/AccountCircle';
import {UserPopover} from './UserPopover';
import {Menu, MenuItem} from '@material-ui/core';
import {i18n, getCurrentLanguage, Languages} from '../../../i18n';

const styles = () => ({
    topMenu: {
        display: 'flex'
    }
});

enum Menus {
    LANGUAGE = 'LANGUAGE',
    USER = 'USER'
}

export interface ITopMenuComponentProps extends WithNamespaces {
    classes: {
        topMenu: string;
    };
    auth: IAuthState;
}

export interface ITopMenuComponentState {
    anchorEl: any;
    openMenu: Menus | null;
}

class TopMenuComponent extends Component<ITopMenuComponentProps, ITopMenuComponentState> {
    state = {
        anchorEl: null,
        openMenu: null
    };

    handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({anchorEl: event.currentTarget, openMenu: Menus.USER});
    };

    handleLanguageMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({anchorEl: event.currentTarget, openMenu: Menus.LANGUAGE});
    };

    handleMenuClose = () => {
        this.setState({anchorEl: null, openMenu: null});
    };

    handleChangeLanguage = (language: string) => () => {
        i18n.changeLanguage(language).catch(() => console.log('Failed to change language.'));

        this.setState({anchorEl: null, openMenu: null});
    };

    render() {
        const {classes, auth, t} = this.props;
        const {anchorEl, openMenu} = this.state;
        const isUserMenuOpen = Boolean(anchorEl && openMenu === Menus.USER);
        const isLanguageMenuOpen = Boolean(anchorEl && openMenu === Menus.LANGUAGE);
        const currentLanguage = getCurrentLanguage();

        return (
            <div className={classes.topMenu}>
                <Tooltip title={t('language.changeLanguage').toString()}>
                    <Button onClick={this.handleLanguageMenuOpen} color="inherit">
                        {currentLanguage}
                    </Button>
                </Tooltip>

                {auth.isAuthenticated ? (
                    <IconButton
                        aria-owns={isUserMenuOpen ? 'material-appbar' : undefined}
                        aria-haspopup="true"
                        onClick={this.handleProfileMenuOpen}
                        color="inherit"
                    >
                        <AccountCircle />
                    </IconButton>
                ) : null}

                <UserPopover anchorEl={anchorEl} open={isUserMenuOpen} onClose={this.handleMenuClose} />

                <Menu
                    anchorEl={anchorEl}
                    anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                    keepMounted
                    transformOrigin={{vertical: 'top', horizontal: 'right'}}
                    open={isLanguageMenuOpen}
                    onClose={this.handleMenuClose}
                >
                    <MenuItem onClick={this.handleChangeLanguage('en')} selected={currentLanguage === Languages.EN}>
                        {t('language.en')}
                    </MenuItem>
                    <MenuItem onClick={this.handleChangeLanguage('de')} selected={currentLanguage === Languages.DE}>
                        {t('language.de')}
                    </MenuItem>
                </Menu>
            </div>
        );
    }
}

const StyledTopMenuComponent = withStyles(styles)(TopMenuComponent);
const I18nTopMenuComponent = withNamespaces()(StyledTopMenuComponent);

const mapStateToProps = ({auth}: RootState) => ({
    auth
});

export const TopMenu = connect(mapStateToProps)(I18nTopMenuComponent);
