import {Languages} from '../../../i18n';

export const defaultData = (lang: string) => {
    if (lang === Languages.DE) {
        return [
            [
                'Welche Browser werden unterstützt?',
                `
                    Bitte stellen Sie sicher, dass Sie die neueste Version einer der folgenden Browser verwenden:
                    <ul>
                        <li>Google Chrome</li>
                        <li>Opera</li>
                        <li>Brave</li>
                        <li>Mozilla Firefox</li>
                    </ul>
                    Alle anderen Browser werden nicht unterstützt (z.B. Internet Explorer oder Safari).
                `
            ],
            [
                'Warum muss ich bestätigen, dass ich die rechtlichen Hinweise zur Kenntnis genommen habe?',
                `
                    <p>Die im Portal erhobenen Daten werden von der Konkursverwaltung und von ihr beauftragten Dritten ausschließlich zur Durchführung des Konkursverfahrens verwendet und gespeichert. Dritte können einen gesetzlichen Anspruch auf Einsichtnahme in diese Daten haben, insbesondere unter den Voraussetzungen von Art. 8a SchKG. Ebenso können Behörden einen gesetzlichen Anspruch auf Einsichtnahme in diese Daten haben.</p>
                    <p>Um das Portal nutzen zu können, müssen Sie deshalb bestätigen, dass Sie die rechtlichen Hinweise zur Kenntnis genommen und verstanden haben.</p>
                `
            ],
            [
                'Welche E-Mail soll ich für die Registrierung verwenden?',
                'Bitte verwenden Sie die E-Mail-Adresse, die Sie bzw. Ihr Mandant für den Erwerb der EVN-Tokens verwendet haben. Diese Angaben werden im Rahmen der Prüfung der Forderungsanmeldung zwecks Abgleich mit den Daten der envion benötigt.'
            ],
            [
                'Ich habe keine E-Mail erhalten, was soll ich tun?',
                'Wenn Sie die Bestätigungs-E-Mail in Ihrem Posteingang nicht sehen sollten, überprüfen Sie bitte Ihren Junk oder Spam-Ordner und Ihre E-Mail-Einstellungen. Falls Sie in Ihrer E-Mail-Adresse einen Tippfehler eingegeben haben, registrieren Sie sich bitte erneut. Wenn Sie immer noch Probleme und keine E-Mail erhalten haben, kontaktieren Sie bitte unseren Support per E-Mail.'
            ],
            [
                'Repräsentieren Sie sich selbst oder sind Sie ein Gläubigervertreter, der einen oder mehrere Gläubiger vertritt?',
                '<p>Bitte wählen Sie entweder "Nein, ich vertrete mich selbst", wenn Sie ein einzelner Gläubiger sind, der für sich selbst handelt, oder "Ja, ich vertrete einen oder mehrere Gläubiger”, wenn Sie Gläubigervertreter sind.</p>' +
                    '<p>Dieser Prozess kann nach der Einstellung nicht mehr geändert werden, und Sie werden je nach der von Ihnen gewählten Option aufgefordert, unterschiedliche Dokumente und Informationen anzugeben.</p>'
            ],
            [
                'Mein Weiter/Zurück Button hat sich grau verfärbt und ich kann den Prozess nicht fortsetzen',
                'Wenn Ihre Dateinamen oder Ihr eingegebener Text die vorgegebene Zeichenanzahl überschreitet kann es dazu kommen, dass sie den Prozess nicht fortführen können. In diesem Fall bitten wir Sie darum ihre Dateinamen sowie Texte zu kürzen und diese erneut im Portal hochzuladen.'
            ],
            [
                'Das Kommentarfeld ist nicht gross genug',
                'Wenn das Kommentarfeld nicht gross genug ist, um die erforderlichen Informationen einzugeben, laden Sie bitte ein separates Dokument im Abschnitt "Zusätzliche Dokumente" hoch und geben Sie die Information "siehe separates Dokument" im Kommentarfeld an.'
            ],
            [
                'Ich kann mich nicht in das Portal einloggen und bekomme nur einen weißen Bildschirm angezeigt. Was kann ich machen?',
                'Bitte nutzen Sie den Browser Google Chrome. Löschen Sie Ihren Speicherplatz in Chrome, indem Sie in Ihren Browser auf "Anzeigen" → "Entwickler" → "Entwicklertools" → "Developer Tools" klicken. Klicken Sie auf "Application" → "Clear Storage" → "Clear site data". Es ist wichtig, dass Sie sich auf der Anmeldeseite befinden, während Sie Ihren Speicher löschen. Bitte aktualisieren Sie die Seite. Ihr Login sollte nach diesem Prozess funktionieren.'
            ],
            [
                'Ich habe Probleme mit dem Portal, was kann ich von meiner Seite aus machen?',
                'Bitte stellen Sie sicher, dass Sie Ihren Browser-Cache geleert haben, bevor Sie das Portal nutzen.'
            ]
        ];
    }

    return [
        [
            'Which browsers are supported?',
            'Please ensure you use the latest version of any of the following browsers:<ul><li>Google Chrome</li><li>Opera</li><li>Brave</li><li>Mozilla Firefox</li></ul>All other browsers are not supported (e.g. Internet Explorer or Safari).'
        ],
        [
            'Why do I have to confirm that I have taken note and understood the legal notice?',
            `
            <p>The data collected with the portal will be used and stored by the bankruptcy administration and third parties commissioned by it exclusively for the purpose of conducting the bankruptcy proceedings. However, third parties may have a right to consult this data, in particular under the conditions of Art. 8a SchKG. Likewise, authorities may have the right to consult such data.</p>
            <p>You will not be able to continue with your claim through the portal unless you have confirmed that you have taken note and understood the legal notice.</p>
            `
        ],
        [
            'Which e-mail should I use for the registration?',
            "Please use the e-mail address that you or your client used to purchase the EVN tokens. This information is required as part of the verification of the claim registration in order to compare it with envion's data."
        ],
        [
            'I have not received an e-mail, what should I do?',
            'If you do not see the verification e-mail in your inbox please check your junk or spam folder and your e-mail settings. In case the e-mail address you entered contains a typo, please start a new registration. If you still have problems and have not received an e-mail please, contact our support via e-mail.'
        ],
        [
            'Do you represent yourself or are you a legal representative who represents one or several claimant(s)?',
            '<p>Please choose either "No, I represent myself" if you are an individual claimant acting directly or "Yes, I represent one or several creditor(s)" if you are the legal representative of several claimants.</p>' +
                '<p>This process cannot be changed after it has been set and you will be asked to provide different documents and details depending on the option that you have selected.</p>'
        ],
        [
            'My Next/Back button has turned gray and I cannot continue the process',
            'If your file name or text exceeds the specified number of characters, you may not be able to continue the process. In this case we ask you to reduce your file names and texts and upload them to the portal again.'
        ],
        [
            'The comment field is not wide enough',
            'If the comment field is not wide enough to enter the required information, please upload a separate document in the "Additional documents" section and indicate "see separate document" in the comment field.'
        ],
        [
            'I cannot login into the portal, I only get a white screen. What should I do?',
            'Please use the browser Google Chrome. Clear your storage by clicking on your browser on "View" → "Developer" → "Developer Tools". Click on "Application" → "Clear Storage" → "Clear site data". It is important that you are on the login page while clearing your storage. Please refresh the page. Your login should work after this process.'
        ],
        ['I have issues with the portal, what can I do from my side?', 'Please insure you cleared your browser cache before using the portal.']
    ];
};
