import React from 'react';
import ReactGA from 'react-ga';
import {Switch, withRouter} from 'react-router';
import {AppRoute, AccessRestriction} from '../AppRoute';
import {Login} from '../../pages/Login';
import {Register} from '../../pages/Register';
import {VerifyEmailAddress} from '../../pages/VerifyEmailAddress';
import {ForgotPassword} from '../../pages/ForgotPassword';
import {ChangePassword} from '../../pages/ChangePassword';
import {Home} from '../../pages/Home';
import {Account} from '../../pages/Account';
import applicationConfig from '../../config';
import {RouteComponentProps} from 'react-router-dom';

class Routes extends React.Component<RouteComponentProps> {
    componentDidMount() {
        const {location, history} = this.props;

        // tslint:disable-next-line: early-exit
        if (process.env.NODE_ENV === 'production') {
            // On direct page navigation
            ReactGA.initialize(applicationConfig.APP_GOOGLE_ANALYTICS_CODE);
            ReactGA.pageview(location.pathname + location.search);

            // On route changed event
            history.listen((_location: any) => {
                ReactGA.pageview(`${_location.pathname}${_location.search}`);
            });
        }
    }
    render() {
        return (
            <Switch>
                <AppRoute exact path="/" redirectTo="/home" />
                <AppRoute exact path="/login" accessRestriction={AccessRestriction.public} accessRestrictionRedirect="/home" component={Login} />
                <AppRoute exact path="/register" component={Register} />
                <AppRoute exact path="/verify-email-address" component={VerifyEmailAddress} />
                <AppRoute
                    exact
                    path="/forgot-password"
                    accessRestriction={AccessRestriction.public}
                    accessRestrictionRedirect="/home"
                    component={ForgotPassword}
                />
                <AppRoute
                    exact
                    path="/change-password"
                    accessRestriction={AccessRestriction.public}
                    accessRestrictionRedirect="/home"
                    component={ChangePassword}
                />
                <AppRoute exact path="/home" accessRestriction={AccessRestriction.private} accessRestrictionRedirect="/login" component={Home} />
                <AppRoute exact path="/account" accessRestriction={AccessRestriction.private} accessRestrictionRedirect="/login" component={Account} />
            </Switch>
        );
    }
}
export default withRouter(Routes);
