import {takeEvery, put} from 'redux-saga/effects';
import {authenticated, authenticatedSuccess} from '../auth.actions';
import {AuthActionTypes} from '../auth.types';
import {setSession} from '../../../libs/auth';
import {getAccount} from '../../account/account.actions';

export function* watchAuthenticated() {
    // tslint:disable-next-line: no-unnecessary-type-annotation
    yield takeEvery(AuthActionTypes.AUTH_AUTHENTICATED, function*(action: ReturnType<typeof authenticated>) {
        const {token, expiresAt} = action.payload;

        setSession(token, expiresAt);

        yield put(authenticatedSuccess());
        yield put(getAccount());
    });
}
