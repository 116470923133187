import {Reducer, AnyAction} from 'redux';
import {IClaimsState, AllClaims, IClaim, ClaimsActionTypes} from './claims.types';
import _ from 'lodash';

const initialState: IClaimsState = {
    isFetching: false,
    allClaims: {},
    byId: [],
    selectedClaimId: null
};

const allClaims = (state: AllClaims = initialState.allClaims, action: AnyAction): AllClaims => {
    switch (action.type) {
        case ClaimsActionTypes.ADD_CLAIM:
        case ClaimsActionTypes.UPDATE_CLAIM:
            const updatedClaims = {...state};
            const claim: IClaim = action.payload;

            updatedClaims[claim.id] = claim;

            return updatedClaims;
        case ClaimsActionTypes.GET_RESPONSE:
            const claims = action.payload.user.claims;

            if (!claims) {
                return state;
            }

            // tslint:disable-next-line: no-shadowed-variable
            const allClaims: AllClaims = {};

            // tslint:disable-next-line: no-shadowed-variable
            claims.forEach((claim: IClaim) => {
                allClaims[claim.id] = claim;
            });

            return allClaims;
        default:
            return state;
    }
};

const byId = (state: string[] = initialState.byId, action: AnyAction): string[] => {
    let claim: IClaim;

    switch (action.type) {
        case ClaimsActionTypes.ADD_CLAIM:
            claim = action.payload;
            state.push(claim.id);
            return state;
        case ClaimsActionTypes.GET_RESPONSE:
            const claims = action.payload.user.claims;

            if (!claims) {
                return state;
            }

            // tslint:disable-next-line: no-shadowed-variable
            return claims.map((claim: IClaim) => claim.id);
        case ClaimsActionTypes.DELETE_CLAIM:
            const claimId = action.payload;
            // tslint:disable-next-line: underscore-consistent-invocation
            return _.without(state, claimId);
        default:
            return state;
    }
};

const isFetching = (state = initialState.isFetching, action: AnyAction): boolean => {
    switch (action.type) {
        case ClaimsActionTypes.GET:
        case ClaimsActionTypes.GET_REQUEST:
            return true;
        case ClaimsActionTypes.GET_RESPONSE:
        case ClaimsActionTypes.GET_ERROR:
            return false;
        default:
            return state;
    }
};

const selectedClaimId = (state = initialState.selectedClaimId, action: AnyAction): string | null => {
    switch (action.type) {
        case ClaimsActionTypes.SELECT_CLAIM:
            return action.payload;
        case ClaimsActionTypes.UNSELECT_CLAIM:
            return null;
        default:
            return state;
    }
};

export const claimsReducer: Reducer<IClaimsState> = (state = initialState, action) => ({
    isFetching: isFetching(state.isFetching, action),
    allClaims: allClaims(state.allClaims, action),
    selectedClaimId: selectedClaimId(state.selectedClaimId, action),
    byId: byId(state.byId, action)
});
