import {takeEvery, all, put, call, select} from 'redux-saga/effects';
import {getAccountRequest, getAccountResponse, getAccountError} from '../account.actions';
import {AccountActionTypes} from '../account.types';
import {logout} from '../../auth/auth.actions';
import {RootState} from '../../';
import {userApi} from '../../../libs/api';

export const getAccessToken = (state: RootState) => state.auth.token;

export function* getAccountSaga() {
    yield takeEvery(AccountActionTypes.GET, function*() {
        try {
            yield put(getAccountRequest());

            const accessToken = yield select(getAccessToken);
            const response = yield call(userApi.get, accessToken);

            yield put(getAccountResponse(response.data));
        } catch (error) {
            if (error.response && error.response.status === 401) {
                return yield all([put(logout()), put(getAccountError(error))]);
            }
            return yield put(getAccountError(error));
        }
    });
}
