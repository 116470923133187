import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {Link as LINK, LinkProps} from '@material-ui/core';

export interface ILinkProps extends LinkProps {
    to: string;
}

export const Link = (props: ILinkProps) => {
    return (
        <LINK component={RouterLink} to={props.to} variant={props.variant || 'body2'}>
            {props.children}
        </LINK>
    );
};
