import {action} from 'typesafe-actions';
import {AccountActionTypes, IAccountProperties} from './account.types';

export const getAccount = () => action(AccountActionTypes.GET);
export const getAccountRequest = () => action(AccountActionTypes.GET_REQUEST);
export const getAccountResponse = (user: IAccountProperties) => action(AccountActionTypes.GET_RESPONSE, {user});
export const getAccountError = (error: any) => action(AccountActionTypes.GET_ERROR, {error});

export const verifyEmailAddress = (id: string) => action(AccountActionTypes.VERIFY_EMAIL, {id});
export const verifyEmailAddressRequest = (id: string) => action(AccountActionTypes.VERIFY_EMAIL_REQUEST, {id});
export const verifyEmailAddressResponse = (id: string, user: IAccountProperties) => action(AccountActionTypes.VERIFY_EMAIL_RESPONSE, {id, user});
export const verifyEmailAddressError = (id: string, error: any) => action(AccountActionTypes.VERIFY_EMAIL_ERROR, {id, error});

export const applyFormResult = (result: IAccountProperties) => action(AccountActionTypes.APPLY_FORM_RESULT, result);
