import {IFile} from '../claimants/claimants.types';

export enum AccountActionTypes {
    GET = '@@account/GET',
    GET_REQUEST = '@@account/GET_REQUEST',
    GET_RESPONSE = '@@account/GET_RESPONSE',
    GET_ERROR = '@@account/GET_ERROR',
    VERIFY_EMAIL = '@@account/VERIFY_EMAIL',
    VERIFY_EMAIL_REQUEST = '@@account/VERIFY_EMAIL_REQUEST',
    VERIFY_EMAIL_RESPONSE = '@@account/VERIFY_EMAIL_RESPONSE',
    VERIFY_EMAIL_ERROR = '@@account/VERIFY_EMAIL_ERROR',
    APPLY_FORM_RESULT = '@@account/APPLY_FORM_RESULT'
}

export interface IAccountProperties {
    email: string;
    emailVerified: boolean;
    hasAcceptedLegalNotice: boolean;
    lastLogin: string;
    role: string;
    legalRepresentativeDetails?: ILegalRepresentativeDetails;
}

export interface ILegalRepresentativeDetails {
    userId: string;
    createdAt: string;
    updatedAt: string;
    givenName: string;
    familyName: string;
    honorificPrefix: string;
    honorificSuffix: string;
    company: string;
    additional: string;
    phoneNumber: string;
    preferredLanguage: string;
    address1: string;
    address2: string;
    postalCode: string;
    addressLocality: string;
    addressCountry: string;
    passport: IFile[];
}

export enum AppRoles {
    CLAIMANT_UNDECIDED = 'CLAIMANT_UNDECIDED',
    CLAIMANT_LEGAL_REPRESENTATIVE = 'CLAIMANT_LEGAL_REPRESENTATIVE',
    CLAIMANT_LEGAL_REPRESENTATIVE_INCOMPLETE = 'CLAIMANT_LEGAL_REPRESENTATIVE_INCOMPLETE',
    CLAIMANT_CLAIMANT = 'CLAIMANT_CLAIMANT'
}

export type AccountProperties = IAccountProperties | null;

export interface IAccountState {
    properties: AccountProperties;
    isFetching: boolean;
}
