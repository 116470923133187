import {Languages} from '../../../i18n';

export const signClaimsData = (lang: string) => {
    if (lang === Languages.DE) {
        return [
            [
                'Was muss ich tun, um die Forderungsanmeldung zu unterzeichnen?',
                `
                <ol>
                    <li>Laden Sie das Formular "Forderungsanmeldung" in Ihrer bevorzugten Sprache (Englisch oder Deutsch) herunter,</li>
                    <li>Überprüfen Sie, ob alle Details richtig sind. Wenn Sie Fehler finden sollten, gehen Sie bitte zurück in die entsprechenden Registrierungsschritte und korrigieren Sie diese,</li>
                    <li>Unterzeichnen Sie das Formular "Forderungsanmeldung”, sobald Sie sicher sind, dass alles richtig ist,</li>
                    <li>Laden Sie das unterzeichnete Formular hoch.</li>
                </ol>
            `
            ],
            [
                'Welches Format wird unterstützt?',
                'Bitte laden Sie Ihr Dokument als PNG oder in Form eines JPG oder PDF mit einer maximalen Größe von 3MB hoch.'
            ],
            ['Wie viele Dokumente kann ich hochladen?', 'Sie können hier nur ein Dokument hochladen.'],
            [
                'Mein Weiter/Zurück Button hat sich grau verfärbt und ich kann den Prozess nicht fortsetzen',
                'Wenn Ihre Dateinamen oder Ihr eingegebener Text die vorgegebene Zeichenanzahl überschreitet kann es dazu kommen, dass sie den Prozess nicht fortführen können. In diesem Fall bitten wir Sie darum ihre Dateinamen sowie Texte zu kürzen und diese erneut im Portal hochzuladen.'
            ],
            [
                'Ich habe Probleme mit dem Portal, was kann ich von meiner Seite aus machen?',
                'Bitte stellen Sie sicher, dass Sie Ihren Browser-Cache geleert haben, bevor Sie das Portal nutzen.'
            ]
        ];
    }

    return [
        [
            'What do I need to do to sign the claim form?',
            `
            <ol>
                <li>Download the claim form in your preferred language (English or German)</li>
                <li>Review if all details are correct. If you find mistakes, go back to the claimant portal and correct them</li>
                <li>Sign the claim form once you are sure that it is correct</li>
                <li>Upload the final signed claim form</li>
            </ol>
        `
        ],
        ['Which format will be supported?', 'Please upload your document in the form of either PNG, JPG or PDF with a maximum size of 3MB.'],
        ['How many documents can I upload?', 'You can upload only one document here.'],
        [
            'My Next/Back button has turned gray and I cannot continue the process',
            'If your file name or text exceeds the specified number of characters, you may not be able to continue the process. In this case we ask you to reduce your file names and texts and upload them to the portal again.'
        ],
        ['I have issues with the portal, what can I do from my side?', 'Please insure you cleared your browser cache before using the portal.']
    ];
};
