import React from 'react';
import {Redirect} from 'react-router';
import {withStyles, Grid, Card, CardHeader, CardContent, Button, Typography, MenuItem} from '@material-ui/core';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {Form, Formik, Field, FormikProps, FormikValues, FormikErrors} from 'formik';
import * as Yup from 'yup';
import {formikSubmitHandler, ConvertValidationErrorsFunction} from '../../../utils';
import {styles, IClassesProperty} from '../styles';
import {RootState} from '../../../store';
import {TextField} from '../../../components/TextField';
import {Select} from '../../../components/Select';
import {COUNTRIES} from '../../../libs/countries';
import {IClaimantsState, IClaimant, ClaimantStatus} from '../../../store/claimants/claimants.types';
import {patchClaimant, createClaimant} from '../../../libs/api/user/user';
import {IAuthState} from '../../../store/auth/auth.types';
import {
    addClaimant as addClaimantAction,
    selectClaimantId as selectClaimantIdAction,
    unselectClaimant as unselectClaimantAction,
    updateClaimant as updateClaimantAction
} from '../../../store/claimants/claimants.actions';
import {IAccountState, AppRoles} from '../../../store/account/account.types';
import Helmet from 'react-helmet';
import {i18n, getCurrentLanguage, Languages} from '../../../i18n';
import {withTranslation as withNamespaces, WithTranslation as WithNamespaces} from 'react-i18next';
import {setFaq as setFaqAction} from '../../../store/faq/faq.actions';
import {IFaqState} from '../../../store/faq/faq.types';
import {showSnackBar as showSnackBarAction} from '../../../store/snackbar/snackbar.actions';
import {ISnackBarState} from '../../../store/snackbar/snackbar.types';

export interface IPersonalInformationStepProps extends WithNamespaces {
    classes: IClassesProperty;
    auth: IAuthState;
    claimants: IClaimantsState;
    account: IAccountState;
    addClaimant(claimant: IClaimant): void;
    updateClaimant(claimant: IClaimant): void;
    selectClaimantId(claimantId: string): void;
    unselectClaimant(): void;
    setFaq(type: IFaqState): void;
    showSnackBar(payload: ISnackBarState): void;
}

interface IPersonalInformationFormValues {
    honorificPrefix: string;
    honorificSuffix: string;
    givenName: string;
    familyName: string;
    company: string;
    additional: string;
    phoneNumber: string;
    email: string;
    preferredLanguage: string;
    address1: string;
    address2: string;
    postalCode: string;
    addressLocality: string;
    addressCountry: string;
}

class PersonalInformationStepComponent extends React.Component<IPersonalInformationStepProps> {
    state = {
        redirect: false
    };

    validationSchema = Yup.object().shape({
        honorificPrefix: Yup.string().required(this.props.t('PersonalInformationStep.validation.honorificPrefix').toString()),
        honorificSuffix: Yup.string(),
        givenName: Yup.string().required(this.props.t('PersonalInformationStep.validation.givenName').toString()),
        familyName: Yup.string().required(this.props.t('PersonalInformationStep.validation.familyName').toString()),
        company: Yup.string(),
        additional: Yup.string(),
        phoneNumber: Yup.string().required(this.props.t('PersonalInformationStep.validation.phoneNumber').toString()),
        email: Yup.string()
            .required(this.props.t('PersonalInformationStep.validation.email').toString())
            .email(this.props.t('PersonalInformationStep.validation.email').toString()),
        preferredLanguage: Yup.string().required(this.props.t('PersonalInformationStep.validation.preferredLanguage').toString()),
        address1: Yup.string()
            .max(40, `${this.props.t('PersonalInformationStep.validation.addressMax')}.`)
            .required(this.props.t('PersonalInformationStep.validation.address1').toString()),
        address2: Yup.string().max(40, `${this.props.t('PersonalInformationStep.validation.addressMax')}.`),
        postalCode: Yup.string().required(this.props.t('PersonalInformationStep.validation.postalCode').toString()),
        addressLocality: Yup.string().required(this.props.t('PersonalInformationStep.validation.addressLocality').toString()),
        addressCountry: Yup.string().required(this.props.t('PersonalInformationStep.validation.addressCountry').toString())
    });

    componentDidMount = () => {
        const {setFaq} = this.props;
        setFaq({type: ClaimantStatus.PERSONAL_INFORMATION});
    };

    getAccountMail = () => {
        const {account, t} = this.props;

        if (!account.properties) {
            throw new Error(t('Roles.invalid').toString());
        }

        return account.properties.email;
    };

    getRole = () => {
        const {account, t} = this.props;

        if (!account.properties) {
            throw new Error(t('Roles.invalid').toString());
        }

        return account.properties.role;
    };

    isNewClaimant = () => {
        const {claimants} = this.props;

        const role = this.getRole();

        if (
            (role === AppRoles.CLAIMANT_LEGAL_REPRESENTATIVE && !claimants.selectedClaimantId) ||
            (role === AppRoles.CLAIMANT_CLAIMANT && claimants.byId.length === 0)
        ) {
            return true;
        }

        return false;
    };

    showError = (error: string | Error) => {
        const _error = typeof error === 'string' ? error : error.message;
        this.props.showSnackBar({message: _error, variant: 'error'});
    };

    // onBack = async (props: FormikProps<IPersonalInformationFormValues>) => {
    //     const values: FormikValues = props.values;
    //     const {auth, addClaimant, unselectClaimant} = this.props;
    //     const role = this.getRole();
    //     const addNew = this.isNewClaimant();

    //     if (role !== AppRoles.CLAIMANT_LEGAL_REPRESENTATIVE) {
    //         throw new Error(this.props.t('Roles.invalid').toString());
    //     }

    //     if (addNew && !props.isValid) {
    //         // not fancy, but validates the form and marks invalid fields
    //         return props.submitForm();
    //     }

    //     const _values = {
    //         ...values,
    //         status: ClaimantStatus.PERSONAL_INFORMATION
    //     };

    //     if (addNew) {
    //         const createdClaimant = await createClaimant(_values, auth.token);
    //         addClaimant(createdClaimant);
    //     } else {
    //         const claimant = this.getClaimant();
    //         await patchClaimant(_values, 'personal-information', claimant.id, auth.token);
    //     }

    //     unselectClaimant();
    // };

    onSubmit = async (values: FormikValues) => {
        const {auth, addClaimant, updateClaimant, selectClaimantId} = this.props;

        const role = this.getRole();
        const addNew = this.isNewClaimant();

        if (role === AppRoles.CLAIMANT_CLAIMANT) {
            const _values = {
                ...values,
                status: ClaimantStatus.PASSPORT
            };

            if (addNew) {
                const createdClaimant = await createClaimant(_values, auth.token);
                addClaimant(createdClaimant);
            } else {
                const updatedClaimant = await patchClaimant(_values, 'personal-information', null, auth.token);
                updateClaimant(updatedClaimant);
            }
        } else if (role === AppRoles.CLAIMANT_LEGAL_REPRESENTATIVE) {
            const _values = {
                ...values,
                status: ClaimantStatus.POWER_OF_ATTORNEY
            };

            if (addNew) {
                const createdClaimant = await createClaimant(_values, auth.token);
                addClaimant(createdClaimant);
                selectClaimantId(createdClaimant.id);
            } else {
                const claimant = this.getClaimant();
                const updatedClaimant = await patchClaimant(_values, 'personal-information', claimant.id, auth.token);
                updateClaimant(updatedClaimant);
            }
        }
    };

    stripFirstWord = (str: string) => {
        return str.substr(str.indexOf(' ') + 1);
    };

    convertValidationErrors: ConvertValidationErrorsFunction<FormikValues> = (validationErrors) => {
        return validationErrors.reduce<any>((result, validationError) => {
            const constrainKeys = Object.keys(validationError.constraints);

            if (constrainKeys.length > 1) {
                result[validationError.property] = (
                    <React.Fragment>
                        <ul style={{paddingLeft: 15, marginTop: 0}}>
                            {constrainKeys.map((constrainKey) => (
                                <li key={constrainKey}>{this.stripFirstWord(validationError.constraints[constrainKey])}.</li>
                            ))}
                        </ul>
                    </React.Fragment>
                );
            } else if (constrainKeys.length === 1) {
                result[validationError.property] = `${this.stripFirstWord(validationError.constraints[constrainKeys[0]])}.`;
            }

            return result;
        }, {});
    };

    validate = (values: IPersonalInformationFormValues) => {
        const errors: FormikErrors<IPersonalInformationFormValues> = {};

        if (this.getRole() === AppRoles.CLAIMANT_CLAIMANT && getCurrentLanguage() !== values.preferredLanguage) {
            i18n.changeLanguage(values.preferredLanguage).catch(() => console.log('Failed to change language.'));
        }

        return errors;
    };

    getEmptyInitialValues = () => {
        const role = this.getRole();
        const email = role === AppRoles.CLAIMANT_CLAIMANT ? this.getAccountMail() : '';

        return {
            honorificPrefix: '',
            honorificSuffix: '',
            givenName: '',
            familyName: '',
            company: '',
            additional: '',
            phoneNumber: '',
            email,
            preferredLanguage: '',
            address1: '',
            address2: '',
            postalCode: '',
            addressLocality: '',
            addressCountry: '',
            accountHolderName: '',
            iban: '',
            bic: ''
        };
    };

    getClaimant = () => {
        const {claimants} = this.props;

        return claimants.selectedClaimantId ? claimants.allClaimants[claimants.selectedClaimantId] : claimants.allClaimants[claimants.byId[0]];
    };

    getInitialValuesFromClaimant = () => {
        const claimant = this.getClaimant();

        return {
            honorificPrefix: claimant.honorificPrefix,
            honorificSuffix: claimant.honorificSuffix,
            givenName: claimant.givenName,
            familyName: claimant.familyName,
            company: claimant.company,
            additional: claimant.additional,
            phoneNumber: claimant.phoneNumber,
            email: claimant.email,
            preferredLanguage: claimant.preferredLanguage,
            address1: claimant.address1,
            address2: claimant.address2,
            postalCode: claimant.postalCode,
            addressLocality: claimant.addressLocality,
            addressCountry: claimant.addressCountry,
            accountHolderName: claimant.accountHolderName,
            iban: claimant.iban,
            bic: claimant.bic
        };
    };

    areCriticalPropertiesLocked() {
        const claimant = this.getClaimant();

        if (this.isNewClaimant()) {
            return false;
        }

        if (!claimant || !Object.prototype.hasOwnProperty.call(claimant, 'lockCriticalProperties')) {
            return false;
        }

        return claimant.lockCriticalProperties;
    }

    renderForm = (props: FormikProps<IPersonalInformationFormValues>) => {
        const {classes, t} = this.props;
        const role = this.getRole();

        const lockCriticalProperties = this.areCriticalPropertiesLocked();
        const isMailDisabled = role === AppRoles.CLAIMANT_CLAIMANT ? true : this.areCriticalPropertiesLocked();

        const lang: 'en' | 'de' = getCurrentLanguage();

        return (
            <Form>
                <Grid container direction="column" spacing={2}>
                    <Grid container direction="row">
                        <Grid item className={classes.field}>
                            <Typography className={classes.subTitle} variant="h6">
                                {t('PersonalInformationStep.head1')}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row">
                        <Grid item md={6} xs={12} className={classes.field}>
                            <Field
                                name="honorificPrefix"
                                label={`${t('PersonalInformationStep.honorificPrefix')} *`}
                                err={props.errors.honorificPrefix}
                                component={Select}
                            >
                                <MenuItem value="mr">{t('PersonalInformationStep.mr')}</MenuItem>
                                <MenuItem value="mrs">{t('PersonalInformationStep.mrs')}</MenuItem>
                            </Field>
                        </Grid>
                        <Grid item md={6} xs={12} className={classes.field}>
                            <Field name="honorificSuffix" label={t('PersonalInformationStep.honorificSuffix')} type="text" component={TextField} />
                        </Grid>
                    </Grid>
                    <Grid container direction="row">
                        <Grid item md={6} xs={12} className={classes.field}>
                            <Field
                                name="givenName"
                                label={`${t('PersonalInformationStep.givenName')} *`}
                                disabled={lockCriticalProperties}
                                type="text"
                                component={TextField}
                            />
                        </Grid>
                        <Grid item md={6} xs={12} className={classes.field}>
                            <Field
                                name="familyName"
                                label={`${t('PersonalInformationStep.familyName')} *`}
                                disabled={lockCriticalProperties}
                                type="text"
                                component={TextField}
                            />
                        </Grid>
                    </Grid>
                    <Grid container direction="row">
                        <Grid item md={6} xs={12} className={classes.field}>
                            <Field name="company" label={t('PersonalInformationStep.company')} type="text" component={TextField} />
                        </Grid>
                        <Grid item md={6} xs={12} className={classes.field}>
                            <Field name="additional" label={t('PersonalInformationStep.additional')} type="text" component={TextField} />
                        </Grid>
                    </Grid>
                    <Grid container direction="row">
                        <Grid item md={6} xs={12} className={classes.field}>
                            <Field name="phoneNumber" label={`${t('PersonalInformationStep.phoneNumber')} *`} type="text" component={TextField} />
                        </Grid>
                        <Grid item md={6} xs={12} className={classes.field}>
                            <Field name="email" label={`${t('PersonalInformationStep.email')} *`} disabled={isMailDisabled} type="text" component={TextField} />
                        </Grid>
                    </Grid>
                    <Grid container direction="row">
                        <Grid item md={6} xs={12} className={classes.field}>
                            <Field
                                name="preferredLanguage"
                                label={`${t('PersonalInformationStep.preferredLanguage')} *`}
                                err={props.errors.preferredLanguage}
                                component={Select}
                            >
                                <MenuItem value={Languages.EN}>{t('PersonalInformationStep.english')}</MenuItem>
                                <MenuItem value={Languages.DE}>{t('PersonalInformationStep.german')}</MenuItem>
                            </Field>
                        </Grid>
                    </Grid>
                    <Grid container direction="row">
                        <Grid item className={classes.field}>
                            <Typography className={classes.subTitle} variant="h6">
                                {t('PersonalInformationStep.head2')}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row">
                        <Grid item md={8} xs={12} className={classes.field}>
                            <Field name="address1" label={`${t('PersonalInformationStep.address1')} *`} type="text" component={TextField} />
                        </Grid>
                    </Grid>
                    <Grid container direction="row">
                        <Grid item md={8} xs={12} className={classes.field}>
                            <Field name="address2" label={`${t('PersonalInformationStep.address2')}`} type="text" component={TextField} />
                        </Grid>
                    </Grid>
                    <Grid container direction="row">
                        <Grid item md={6} xs={12} className={classes.field}>
                            <Field name="postalCode" label={`${t('PersonalInformationStep.postalCode')} *`} type="text" component={TextField} />
                        </Grid>

                        <Grid item md={6} xs={12} className={classes.field}>
                            <Field name="addressLocality" label={`${t('PersonalInformationStep.addressLocality')} *`} type="text" component={TextField} />
                        </Grid>
                    </Grid>
                    <Grid container direction="row">
                        <Grid item md={6} xs={12} className={classes.field}>
                            <Field
                                name="addressCountry"
                                label={`${t('PersonalInformationStep.addressCountry')} *`}
                                err={props.errors.addressCountry}
                                component={Select}
                            >
                                {Object.keys(COUNTRIES[lang]).map((key) => (
                                    <MenuItem key={key} value={key}>
                                        {COUNTRIES[lang][key]}
                                    </MenuItem>
                                ))}
                            </Field>
                        </Grid>
                    </Grid>

                    {/* <Grid container direction="row">
                        <Grid item className={classes.field}>
                            <Typography className={classes.subTitle} variant="h6">
                                {t('PersonalInformationStep.head3')}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row">
                        <Grid item md={8} xs={12} className={classes.field}>
                            <Field name="accountHolderName" label={t('PersonalInformationStep.accountHolderName')} type="text" component={TextField} />
                        </Grid>
                    </Grid>
                    <Grid container direction="row">
                        <Grid item md={8} xs={12} className={classes.field}>
                            <Field name="iban" label={t('PersonalInformationStep.iban')} type="text" component={TextField} />
                        </Grid>
                    </Grid>
                    <Grid container direction="row">
                        <Grid item md={8} xs={12} className={classes.field}>
                            <Field name="bic" label={t('PersonalInformationStep.bic')} type="text" component={TextField} />
                        </Grid>
                    </Grid> */}
                </Grid>

                <Grid container direction="row-reverse" justify="space-between" alignItems="flex-end" style={{marginTop: 20}}>
                    <Grid item>
                        <Button disabled={Object.keys(props.errors).length > 0 || props.isSubmitting} type="submit" color="primary" variant="contained">
                            {t('Navigation.next')}
                        </Button>
                    </Grid>
                    {/* @TODO: before enable back BTN, fix issue where form values are not up to date, after this step is called a second time */}
                    {/* {role === AppRoles.CLAIMANT_LEGAL_REPRESENTATIVE ? (
                        <Grid item>
                            <Button
                                onClick={async () => this.onBack(props)}
                                disabled={Object.keys(props.errors).length > 0 || props.isSubmitting}
                                type="button"
                                color="default"
                                variant="contained"
                            >
                                {t('Navigation.back')}
                            </Button>
                        </Grid>
                    ) : null} */}
                </Grid>
            </Form>
        );
    };

    render() {
        const {classes, t} = this.props;

        const initialValues = this.isNewClaimant() ? this.getEmptyInitialValues() : this.getInitialValuesFromClaimant();

        if (this.state.redirect) {
            return <Redirect to="/" />;
        }

        return (
            <React.Fragment>
                <Helmet>
                    <title>{t('PersonalInformationStep.title')}</title>
                </Helmet>

                <Card className={classes.card}>
                    <CardHeader
                        classes={{
                            root: classes.cardHeader,
                            title: classes.cardHeaderTitle
                        }}
                        title={t('PersonalInformationStep.title')}
                        style={{textAlign: 'center'}}
                    />
                    <CardContent>
                        <Formik
                            initialValues={initialValues}
                            validate={this.validate}
                            validationSchema={this.validationSchema}
                            onSubmit={formikSubmitHandler(this.onSubmit, {
                                convertValidationErrors: this.convertValidationErrors,
                                forbidden: this.showError,
                                badRequest: this.showError,
                                unknownError: this.showError
                            })}
                            enableReinitialize={true}
                        >
                            {this.renderForm}
                        </Formik>
                    </CardContent>
                </Card>
            </React.Fragment>
        );
    }
}

const StyledPersonalInformationStepComponent = withStyles(styles)(PersonalInformationStepComponent);
const I18nPersonalInformationStepComponent = withNamespaces()(StyledPersonalInformationStepComponent);

const mapStateToProps = ({auth, account, claimants}: RootState) => ({
    auth,
    account,
    claimants
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    addClaimant: (claimant: IClaimant) => dispatch(addClaimantAction(claimant)),
    updateClaimant: (claimant: IClaimant) => dispatch(updateClaimantAction(claimant)),
    selectClaimantId: (claimantId: string) => dispatch(selectClaimantIdAction(claimantId)),
    unselectClaimant: () => dispatch(unselectClaimantAction),
    setFaq: (type: IFaqState) => dispatch(setFaqAction(type)),
    showSnackBar: (payload: ISnackBarState) => dispatch(showSnackBarAction(payload))
});

export const PersonalInformationStep = connect(mapStateToProps, mapDispatchToProps)(I18nPersonalInformationStepComponent);
