import React from 'react';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {RootState} from '../../store';
import {unselectClaimant as unselectClaimantAction} from '../../store/claimants/claimants.actions';
import {IClaimantsState, ClaimantStatus} from '../../store/claimants/claimants.types';
import {PersonalInformationStep} from './PersonalInformationStep';
import {ClaimantPassportStep} from './ClaimantPassportStep';
import {AdditionalDocumentsStep} from './AdditionalDocumentsStep';
import {PowerOfAttorneyStep} from './PowerOfAttorneyStep';
import {MeetingOfClaimantsStep} from './MeetingOfClaimantsStep';
import {ClaimsStep} from './ClaimsStep';
import {SignClaimsStep} from './SignClaimsStep';
import {TokenPurchaseStep} from './TokenPurchaseStep';
import {SendTokensStep} from './SendTokensStep';
import {CompleteStep} from './CompletedStep';
import {LockedClaimant} from './LockedClaimant';
import {IAccountState, AppRoles} from '../../store/account/account.types';
import {Redirect} from 'react-router';
import {withTranslation as withNamespaces, WithTranslation as WithNamespaces} from 'react-i18next';
import getConfig from '../../config';
import {ReadOnly} from '../../components/ReadOnly';

export interface IClaimProcessProps extends WithNamespaces {
    claimants: IClaimantsState;
    account: IAccountState;
    unselectClaimant(): void;
}

class ClaimProcessComponent extends React.Component<IClaimProcessProps> {
    getClaimant = () => {
        const {claimants} = this.props;

        return claimants.selectedClaimantId ? claimants.allClaimants[claimants.selectedClaimantId] : claimants.allClaimants[claimants.byId[0]];
    };

    getRole = () => {
        const {account, t} = this.props;

        if (!account.properties) {
            throw new Error(t('Roles.invalid').toString());
        }

        return account.properties.role;
    };

    isNewClaimant = () => {
        const {claimants} = this.props;

        const role = this.getRole();

        if (
            (role === AppRoles.CLAIMANT_LEGAL_REPRESENTATIVE && !claimants.selectedClaimantId) ||
            (role === AppRoles.CLAIMANT_CLAIMANT && claimants.byId.length === 0)
        ) {
            return true;
        }

        return false;
    };

    componentWillUnmount = () => {
        const {unselectClaimant} = this.props;

        unselectClaimant();
    };

    render() {
        if (this.isNewClaimant() && !getConfig.APP_READONLY_MODE) {
            return <PersonalInformationStep />;
        }

        const claimant = this.getClaimant();

        if (this.isNewClaimant() && !claimant) {
            return <ReadOnly />;
        }

        if (
            claimant.locked &&
            claimant.status !== ClaimantStatus.REVIEW &&
            claimant.status !== ClaimantStatus.COMPLETE &&
            claimant.status !== ClaimantStatus.INCOMPLETE
        ) {
            return <LockedClaimant />;
        }

        if (
            getConfig.APP_READONLY_MODE &&
            claimant.status !== ClaimantStatus.REVIEW &&
            claimant.status !== ClaimantStatus.COMPLETE &&
            claimant.status !== ClaimantStatus.INCOMPLETE
        ) {
            return <ReadOnly />;
        }

        switch (claimant.status) {
            case ClaimantStatus.PERSONAL_INFORMATION:
                return <PersonalInformationStep />;
            case ClaimantStatus.POWER_OF_ATTORNEY:
                return <PowerOfAttorneyStep />;
            case ClaimantStatus.PASSPORT:
                return <ClaimantPassportStep />;
            case ClaimantStatus.ADDITIONAL_DOCUMENTS:
                return <AdditionalDocumentsStep />;
            case ClaimantStatus.TOKEN_PURCHASE:
                return <TokenPurchaseStep />;
            case ClaimantStatus.MEETING_OF_CLAIMANTS:
                return <MeetingOfClaimantsStep />;
            case ClaimantStatus.CLAIMS:
                return <ClaimsStep />;
            case ClaimantStatus.SIGN_CLAIMS_FORM:
                return <SignClaimsStep />;
            case ClaimantStatus.SEND_TOKENS:
                return <SendTokensStep />;
            case ClaimantStatus.REVIEW:
                return <CompleteStep status={'review'} />;
            case ClaimantStatus.COMPLETE:
                return <CompleteStep status={'complete'} />;
            case ClaimantStatus.INCOMPLETE:
                return <CompleteStep status={'wrong'} />;
            default:
                return <Redirect to="/" />;
        }
    }
}

const I18nClaimProcessComponent = withNamespaces()(ClaimProcessComponent);

const mapStateToProps = ({account, claimants}: RootState) => ({
    account,
    claimants
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    unselectClaimant: () => dispatch(unselectClaimantAction)
});

export const ClaimProcess = connect(mapStateToProps, mapDispatchToProps)(I18nClaimProcessComponent);
