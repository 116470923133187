import React from 'react';
import {Dispatch} from 'redux';
import {connect} from 'react-redux';
import {withStyles, Card, CardHeader, CardContent, CardActions, Grid} from '@material-ui/core';
import {RootState} from '../../store';
import Helmet from 'react-helmet';
import {withTranslation as withNamespaces, WithTranslation as WithNamespaces} from 'react-i18next';
// import {LockIcon} from '../../components/LockIcon';
import {Theme} from '@material-ui/core/styles/createMuiTheme';
import {ClaimantInfo} from '../../components/ClaimantInfo';
import {IClaimantsState} from '../../store/claimants/claimants.types';
import {IAuthState} from '../../store/auth/auth.types';
import {IAccountState, AppRoles} from '../../store/account/account.types';
import {LinkButton} from '../LinkButton';

const styles = (theme: Theme) => ({
    card: {
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: '900px',
        marginBottom: '5vh'
    },
    cardHeader: {
        backgroundColor: theme.palette.primary.main
    },
    cardHeaderTitle: {
        color: theme.palette.primary.contrastText,
        fontSize: '1.7rem'
    },
    subTitle: {
        marginTop: '20px'
    }
});

interface IReadOnlyProps extends WithNamespaces {
    auth: IAuthState;
    account: IAccountState;
    claimants: IClaimantsState;
    classes: {
        card: string;
        cardHeader: string;
        cardHeaderTitle: string;
        subTitle: string;
    };
}

class ReadOnlyComponent extends React.Component<IReadOnlyProps> {
    getClaimant = () => {
        const {claimants} = this.props;
        return claimants.selectedClaimantId ? claimants.allClaimants[claimants.selectedClaimantId] : claimants.allClaimants[claimants.byId[0]];
    };

    getRole = () => {
        const {account, t} = this.props;

        if (!account.properties) {
            throw new Error(t('Roles.invalid').toString());
        }

        return account.properties.role;
    };

    render() {
        const {classes, t, auth} = this.props;
        const claimant = this.getClaimant();
        const role = this.getRole();

        const _title = (
            <>
                {/* <span style={{verticalAlign: 'middle'}}>
                    <LockIcon locked />
                </span> */}
                <span style={{marginLeft: 10, marginRight: 10}}>{t('ReadonlyMode.title')}</span>
                {/* <span style={{verticalAlign: 'middle'}}>
                    <LockIcon locked />
                </span> */}
            </>
        );

        return (
            <React.Fragment>
                <Helmet>
                    <title>{t('ReadonlyMode.title')}</title>
                </Helmet>

                <Card className={classes.card}>
                    <CardHeader
                        classes={{
                            root: classes.cardHeader,
                            title: classes.cardHeaderTitle
                        }}
                        title={_title}
                        style={{textAlign: 'center'}}
                    />

                    <CardContent>
                        <ClaimantInfo claimant={claimant} auth={auth} role={role} body={t('ReadonlyMode.temporarilyLocked')} />
                    </CardContent>

                    {role === AppRoles.CLAIMANT_LEGAL_REPRESENTATIVE ? (
                        <CardActions>
                            <Grid direction="row-reverse" container justify="space-between" alignItems="flex-end">
                                <Grid item>
                                    <LinkButton to="/" color="primary" variant="contained">
                                        {t('CompleteStep.back')}
                                    </LinkButton>
                                </Grid>
                            </Grid>
                        </CardActions>
                    ) : null}
                </Card>
            </React.Fragment>
        );
    }
}

const StyledReadOnlyComponent = withStyles(styles)(ReadOnlyComponent);
const I18nReadOnlyComponent = withNamespaces()(StyledReadOnlyComponent);

const mapStateToProps = ({auth, account, claimants}: RootState) => ({
    claimants,
    auth,
    account
});

const mapDispatchToProps = (_dispatch: Dispatch) => ({});

export const ReadOnly = connect(mapStateToProps, mapDispatchToProps)(I18nReadOnlyComponent);
