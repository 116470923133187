import {Theme} from '@material-ui/core/styles/createMuiTheme';

export const styles = (theme: Theme) => ({
    card: {
        marginTop: '3vh',
        marginBottom: '3vh'
    },
    cardHeader: {
        backgroundColor: theme.palette.primary.main
    },
    cardHeaderTitle: {
        color: theme.palette.primary.contrastText
    },
    submitButton: {
        marginTop: theme.spacing(3)
    },
    subTitle: {
        marginTop: '25px'
    }
});

export interface IClassesProperty {
    card: string;
    cardHeader: string;
    cardHeaderTitle: string;
    submitButton: string;
    subTitle: string;
}
