import React, {Component} from 'react';
import {withStyles, Grid, FormControlLabel, Checkbox, Typography, Card, CardHeader, CardContent, Button} from '@material-ui/core';
import {withTranslation as withNamespaces, WithTranslation as WithNamespaces, Trans} from 'react-i18next';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {RootState} from '../../store';
import {IAuthState} from '../../store/auth/auth.types';
import {applyFormResult as applyFormResultAction} from '../../store/account/account.actions';
import {IAccountProperties} from '../../store/account/account.types';
import {Theme} from '@material-ui/core/styles/createMuiTheme';
import Helmet from 'react-helmet';
import {userApi} from '../../libs/api';

export const styles = (theme: Theme) => ({
    card: {
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: '800px',
        marginBottom: '5vh'
    },
    cardHeader: {
        backgroundColor: theme.palette.primary.main
    },
    cardHeaderTitle: {
        color: theme.palette.primary.contrastText,
        fontSize: '1.7rem'
    },
    submitButton: {
        marginTop: theme.spacing(3)
    }
});

export interface ILegalNoticeProps extends WithNamespaces {
    classes: {
        card: string;
        cardHeader: string;
        cardHeaderTitle: string;
        submitButton: string;
    };
    authState: IAuthState;
    applyFormResult(result: IAccountProperties): void;
}

class LegalNoticeComponent extends Component<ILegalNoticeProps> {
    _isMounted = false;

    state = {
        agree: false,
        submitting: false
    };

    componentDidMount = () => {
        this._isMounted = true;
    };

    componentWillUnmount = () => {
        this._isMounted = false;
    };

    handleCheckboxChange = (event: any) => {
        this.setState({agree: event.target.checked});
    };

    submit = async () => {
        const {authState, applyFormResult} = this.props;

        if (this._isMounted) {
            this.setState({submitting: true});
        }

        const result = await userApi.acceptLegalNotice(authState.token);

        applyFormResult(result);

        if (this._isMounted) {
            this.setState({submitting: false});
        }
    };

    render() {
        const {classes, t} = this.props;

        return (
            <React.Fragment>
                <Helmet>
                    <title>{t('LegalNotice.title')}</title>
                </Helmet>

                <Card className={classes.card}>
                    <CardHeader
                        classes={{
                            root: classes.cardHeader,
                            title: classes.cardHeaderTitle
                        }}
                        title={t('LegalNotice.title')}
                        style={{textAlign: 'center'}}
                    />
                    <CardContent>
                        <Grid container direction="row" spacing={2}>
                            <Grid item xs={12}>
                                <Typography paragraph>{t('LegalNotice.p1')}</Typography>

                                <Typography paragraph>
                                    <Trans
                                        i18nKey="LegalNotice.p2"
                                        components={[
                                            <a
                                                rel="noopener noreferrer"
                                                target="_blank"
                                                key={1}
                                                href="https://www.admin.ch/opc/de/classified-compilation/18890002/index.html"
                                            >
                                                SchKG
                                            </a>,
                                            <a
                                                rel="noopener noreferrer"
                                                target="_blank"
                                                key={2}
                                                href="https://www.admin.ch/opc/de/classified-compilation/19110023/index.html"
                                            >
                                                KOV
                                            </a>
                                        ]}
                                    />
                                </Typography>

                                <Typography paragraph>{t('LegalNotice.p3')}</Typography>

                                <Typography paragraph>{t('LegalNotice.p4')}</Typography>

                                <Typography paragraph>{t('LegalNotice.p5')}</Typography>

                                <Typography paragraph>{t('LegalNotice.p6')}</Typography>
                                <FormControlLabel
                                    control={<Checkbox checked={this.state.agree} onChange={this.handleCheckboxChange} value="agree" color="primary" />}
                                    label={t('LegalNotice.agreeCheckbox')}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} justify="flex-end">
                            <Grid item>
                                <Button
                                    disabled={this.state.submitting || !this.state.agree}
                                    type="submit"
                                    color="primary"
                                    variant="contained"
                                    onClick={this.submit}
                                >
                                    {t('LegalNotice.submitButton')}
                                </Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </React.Fragment>
        );
    }
}

const StyledLegalNoticeComponent = withStyles(styles)(LegalNoticeComponent);

const I18nLegalNoticeComponent = withNamespaces()(StyledLegalNoticeComponent);

const mapStateToProps = ({auth}: RootState) => ({
    authState: auth
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    applyFormResult: (result: IAccountProperties) => dispatch(applyFormResultAction(result))
});

export const LegalNotice = connect(mapStateToProps, mapDispatchToProps)(I18nLegalNoticeComponent);
