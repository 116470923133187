import {Reducer, AnyAction} from 'redux';
import {FaqActionTypes, IFaqState} from './faq.types';

const initialState: IFaqState = {
    type: null
};

const faq = (state: IFaqState, action: AnyAction): IFaqState => {
    switch (action.type) {
        case FaqActionTypes.SET:
            return action.payload.payload;
        default:
            return state;
    }
};

export const faqReducer: Reducer<IFaqState> = (state = initialState, action) => faq(state, action);
