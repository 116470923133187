import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {RootState} from '../../store';
import {IAccountState, AppRoles} from '../../store/account/account.types';
import {getAccount as getAccountAction} from '../../store/account/account.actions';
import {DecideLegalRepresentative} from '../DecideLegalRepresentative';
import {UploadPassport} from '../DecideLegalRepresentative/UploadPassport';
import {ClaimantsOverview} from '../ClaimantsOverview';
import {ClaimProcess} from '../ClaimProcess';
import {LegalNotice} from '../LegalNotice';
import {ActionPending} from '../../components/ActionPending';
import {withTranslation as withNamespaces, WithTranslation as WithNamespaces} from 'react-i18next';
import getConfig from '../../config';
import {ReadOnly} from '../../components/ReadOnly';

export interface IHomeProps extends WithNamespaces {
    account: IAccountState;
    getAccount(): void;
}

class HomeComponent extends Component<IHomeProps> {
    componentDidMount() {
        const {account, getAccount} = this.props;

        if (!account.properties && !account.isFetching) {
            getAccount();
        }
    }

    render() {
        const {account, t} = this.props;

        if (!account.properties || account.isFetching) {
            return <ActionPending text={t('HomeComponent.fetching')} />;
        }

        if (!getConfig.APP_READONLY_MODE && !account.properties.hasAcceptedLegalNotice) {
            return <LegalNotice />;
        }

        const readonly = [AppRoles.CLAIMANT_UNDECIDED as string, AppRoles.CLAIMANT_LEGAL_REPRESENTATIVE_INCOMPLETE as string].includes(account.properties.role);

        if (getConfig.APP_READONLY_MODE && readonly) {
            return <ReadOnly />;
        }

        switch (account.properties.role) {
            case AppRoles.CLAIMANT_UNDECIDED:
                return <DecideLegalRepresentative />;
            case AppRoles.CLAIMANT_LEGAL_REPRESENTATIVE_INCOMPLETE:
                return <UploadPassport />;
            case AppRoles.CLAIMANT_LEGAL_REPRESENTATIVE:
                return <ClaimantsOverview />;
            case AppRoles.CLAIMANT_CLAIMANT:
                return <ClaimProcess />;
            default:
                return <div />;
        }
    }
}

const mapStateToProps = ({account}: RootState) => ({
    account
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    getAccount: () => dispatch(getAccountAction())
});

const I18nHomeComponent = withNamespaces()(HomeComponent);

export const Home = connect(mapStateToProps, mapDispatchToProps)(I18nHomeComponent);
