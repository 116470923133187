export enum ClaimsActionTypes {
    GET = '@@claims/GET',
    GET_REQUEST = '@@claims/GET_REQUEST',
    GET_RESPONSE = '@@claims/GET_RESPONSE',
    GET_ERROR = '@@claims/GET_ERROR',
    SELECT_CLAIM = '@@claims/SELECT_CLAIM',
    UNSELECT_CLAIM = '@@claims/UNSELECT_CLAIM',
    ADD_CLAIM = '@@claims/ADD_CLAIM',
    UPDATE_CLAIM = '@@claims/UPDATE_CLAIM',
    DELETE_CLAIM = '@@claims/DELETE_CLAIM'
}

export interface IClaim {
    id: string;
    claimantId: string;
    tokenPurchaseLocation: string;
    basisOfClaims: string;
    currency: string;
    amount: number;
    amountChf: number;
    comments: string;
}

// tslint:disable-next-line: interface-over-type-literal
export type AllClaims = {[id: string]: IClaim};

export interface IClaimsState {
    isFetching: boolean;
    allClaims: AllClaims;
    byId: string[];
    selectedClaimId: string | null;
}
