import React from 'react';
import {Dispatch} from 'redux';
import {connect} from 'react-redux';
import {TextField as MText, withStyles, Grid, Button, Typography, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem} from '@material-ui/core';
import {TextField} from '../../../components/TextField';
import {Select} from '../../../components/Select';
import {Form, Formik, Field, FormikProps, FormikValues, FormikErrors} from 'formik';
import * as Yup from 'yup';
import {formikSubmitHandler, ConvertValidationErrorsFunction} from '../../../utils';
import {RootState} from '../../../store';
import {IAuthState} from '../../../store/auth/auth.types';
import {createClaim as createClaimApi, updateClaim as updateClaimApi} from '../../../libs/api/user/user';
import {createClaim as createClaimAction, updateClaim as updateClaimAction} from '../../../store/claims/claims.actions';
import {IClaim} from '../../../store/claims/claims.types';
import {styles, IClassesProperty} from '../styles';
import {withTranslation as withNamespaces, WithTranslation as WithNamespaces} from 'react-i18next';
import {IClaimantsState} from '../../../store/claimants/claimants.types';
import {showSnackBar as showSnackBarAction} from '../../../store/snackbar/snackbar.actions';
import {ISnackBarState} from '../../../store/snackbar/snackbar.types';

export interface IClaimsFormValues {
    claimantId: string;
    tokenPurchaseLocation?: string;
    basisOfClaims: string;
    currency: string;
    amount: number;
    amountChf: number;
    comments: string;
}

export interface IClaimsDialogProps extends WithNamespaces {
    classes: IClassesProperty;
    claimants: IClaimantsState;
    auth: IAuthState;
    values: IClaim;
    dialogOpen: boolean;
    addClaim(claim: IClaim): void;
    updateClaim(claim: IClaim): void;
    handleClaimClose(): void;
    showSnackBar(payload: ISnackBarState): void;
}

class ClaimsDialogComponent extends React.Component<IClaimsDialogProps> {
    state = {
        otherShow: false,
        otherError: true,
        otherHelper: null,
        otherText: ''
    };

    defaultCurrencies = ['EUR', 'CHF', 'USD', 'ETH', 'BTC'];

    validationSchema = Yup.object().shape({
        tokenPurchaseLocation: Yup.string(),
        basisOfClaims: Yup.string().required(`${this.props.t('Claims.validation.basis')}.`),
        currency: Yup.string().required(`${this.props.t('Claims.validation.currency')}.`),
        amount: Yup.number()
            .required(`${this.props.t('Claims.validation.amount')}.`)
            .moreThan(0, `${this.props.t('Claims.validation.amount')}`)
            .typeError(`${this.props.t('Claims.validation.amount')}.`),
        amountChf: Yup.number()
            .required(`${this.props.t('Claims.validation.amountChf')}.`)
            .moreThan(0, `${this.props.t('Claims.validation.amountChf')}`)
            .typeError(`${this.props.t('Claims.validation.amountChf')}.`),
        comments: Yup.string().required(`${this.props.t('Claims.validation.comments')}.`)
    });

    showError = (error: string) => {
        this.props.showSnackBar({message: error, variant: 'error'});
    };

    getClaimant = () => {
        const {claimants} = this.props;
        return claimants.selectedClaimantId ? claimants.allClaimants[claimants.selectedClaimantId] : claimants.allClaimants[claimants.byId[0]];
    };

    handleOtherChange = (event: any, props: FormikProps<IClaimsFormValues>) => {
        props.setFieldTouched('currency', true);
        this.setState({otherText: event.target.value});
        this.validate(props.values);
    };

    validate = (values: IClaimsFormValues) => {
        const claimant = this.getClaimant();
        const errors: FormikErrors<IClaimsFormValues> = {};

        if (['CHF', 'EUR', 'USD'].includes(values.currency)) {
            const isValidAmount = values.amount.toString().match(/^\d{1,}((\.){0,1}\d{1,2})?$/) !== null;
            if (!isValidAmount) {
                errors.amount = this.props.t('Claims.validation.amountCurrency');
            }
        }

        const isValidAmountChf = values.amountChf.toString().match(/^\d{1,}((\.){0,1}\d{1,2})?$/) !== null;
        if (!isValidAmountChf) {
            errors.amountChf = this.props.t('Claims.validation.amountChf');
        }

        if (values.currency === 'OTHER') {
            this.setState({otherShow: true});

            if (this.state.otherText.length !== 3) {
                this.setState({otherError: true, otherHelper: this.props.t('Claims.validation.other')});
            } else {
                this.setState({otherError: false, otherHelper: null});
            }
        } else {
            this.setState({otherShow: false, otherError: false, otherHelper: null});
        }

        if (claimant.tokenPurchaseLocation === 'multi' && !values.tokenPurchaseLocation) {
            errors.tokenPurchaseLocation = this.props.t('Claims.validation.tokenPurchaseLocation').toString();
        }

        return errors;
    };

    stripFirstWord = (str: string) => {
        return str.substr(str.indexOf(' ') + 1);
    };

    convertValidationErrors: ConvertValidationErrorsFunction<FormikValues> = (validationErrors) => {
        return validationErrors.reduce<any>((result, validationError) => {
            const constrainKeys = Object.keys(validationError.constraints);

            if (constrainKeys.length > 1) {
                result[validationError.property] = (
                    <React.Fragment>
                        <ul style={{paddingLeft: 15, marginTop: 0}}>
                            {constrainKeys.map((constrainKey) => (
                                <li key={constrainKey}>{this.stripFirstWord(validationError.constraints[constrainKey])}.</li>
                            ))}
                        </ul>
                    </React.Fragment>
                );
            } else if (constrainKeys.length === 1) {
                result[validationError.property] = `${this.stripFirstWord(validationError.constraints[constrainKeys[0]])}.`;
            }

            return result;
        }, {});
    };

    onSubmit = async (values: FormikValues) => {
        const {auth, handleClaimClose, addClaim, updateClaim, t} = this.props;

        try {
            const claimant = this.getClaimant();

            if (claimant.tokenPurchaseLocation !== 'multi') {
                values.tokenPurchaseLocation = claimant.tokenPurchaseLocation;
            } else if (!values.tokenPurchaseLocation) {
                throw new Error(this.props.t('claims.validation.tokenPurchaseLocation').toString());
            }

            if (this.state.otherShow) {
                values.currency = this.state.otherText.toUpperCase();
            }

            if (this.isNewClaim()) {
                // insert
                const createdClaim = await createClaimApi(values, auth.token);
                addClaim(createdClaim);
            } else if (values.id.length > 0) {
                // update
                const updatedClaim = await updateClaimApi(values, auth.token);
                updateClaim(updatedClaim);
            } else {
                console.error('invalid add/edit claims state');
            }

            handleClaimClose();
        } catch (error) {
            console.error(error);
            this.showError(t('Form.failed').toString());
        }
    };

    ClaimDialogFormik = (props: FormikProps<IClaimsFormValues>) => {
        const {dialogOpen, handleClaimClose, t, classes} = this.props;
        const claimant = this.getClaimant();

        if (!this.isNewClaim() && !this.defaultCurrencies.includes(props.values.currency)) {
            props.values.currency = 'OTHER';
            // @FIXME:
            // this.setState({otherShow: true, otherError: false, otherHelper: null});
        }

        return (
            <Dialog open={dialogOpen} onClose={handleClaimClose} aria-labelledby="form-dialog-title">
                <DialogTitle style={{textAlign: 'center'}} id="form-dialog-title">
                    {this.isNewClaim() ? t('Claims.add') : t('Claims.edit')} {t('Claims.claim')}
                </DialogTitle>
                <Form>
                    <DialogContent>
                        <Field hidden name="claimantId" label="claimantId" err={props.errors.claimantId} />

                        <Grid container spacing={3}>
                            {claimant.tokenPurchaseLocation === 'multi' ? (
                                <Grid item xs={12}>
                                    <Field
                                        className={classes.row}
                                        name="tokenPurchaseLocation"
                                        label={t('Claims.choose')}
                                        err={props.errors.tokenPurchaseLocation}
                                        component={Select}
                                    >
                                        <MenuItem value="ico">{t('Claims.item.ico')}</MenuItem>
                                        <MenuItem value="secondaryMarket">{t('Claims.item.secondaryMarket')}</MenuItem>
                                        <MenuItem value="privatePerson">{t('Claims.item.privatePerson')}</MenuItem>
                                        <MenuItem value="presaleOrPrivatePlacement">{t('Claims.item.presaleOrPrivatePlacement')}</MenuItem>
                                        <MenuItem value="founders">{t('Claims.item.founders')}</MenuItem>
                                        <MenuItem value="interest">{t('Claims.item.interest')}</MenuItem>
                                        <MenuItem value="other">{t('Claims.item.other')}</MenuItem>
                                    </Field>
                                </Grid>
                            ) : null}

                            <Grid item xs={12}>
                                <Field name="basisOfClaims" label={t('Claims.basis')} err={props.errors.basisOfClaims} component={TextField} />
                            </Grid>

                            <Grid item xs={6}>
                                <Field
                                    placeholder="0.00"
                                    lang="en"
                                    name="amount"
                                    type="text"
                                    label={t('Claims.amount')}
                                    err={props.errors.amount}
                                    component={TextField}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <Typography>in</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Field name="currency" label={t('Claims.currency')} err={props.errors.currency} component={Select}>
                                    <MenuItem value="EUR">EUR</MenuItem>
                                    <MenuItem value="CHF">CHF</MenuItem>
                                    <MenuItem value="USD">USD</MenuItem>
                                    <MenuItem value="ETH">ETH</MenuItem>
                                    <MenuItem value="BTC">BTC</MenuItem>
                                    <MenuItem value="OTHER">OTHER</MenuItem>
                                </Field>
                            </Grid>

                            <Grid item xs={6}>
                                <Field
                                    placeholder="0.00"
                                    lang="en"
                                    name="amountChf"
                                    type="text"
                                    label={t('Claims.amountChf')}
                                    err={props.errors.amountChf}
                                    component={TextField}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <Typography>in CHF</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                {this.state.otherShow ? (
                                    <MText
                                        label={t('Claims.other')}
                                        onChange={(event) => this.handleOtherChange(event, props)}
                                        helperText={this.state.otherHelper}
                                        error={this.state.otherError}
                                    />
                                ) : null}
                            </Grid>

                            <Grid item xs={12}>
                                <Field name="comments" label={t('Claims.comments')} err={props.errors.comments} component={TextField} />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Grid container justify="space-between">
                            <Grid item>
                                <Button onClick={handleClaimClose} color="default" variant="contained">
                                    {t('Claims.cancel')}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    onClick={async () => this.onSubmit(props.values)}
                                    disabled={!props.isValid || this.state.otherError}
                                    type="button"
                                    color="primary"
                                    variant="contained"
                                >
                                    {this.isNewClaim() ? t('Claims.add') : t('Claims.edit')}
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogActions>
                </Form>
            </Dialog>
        );
    };

    isNewClaim() {
        const {values} = this.props;

        if (Object.keys(values).indexOf('id') > -1) {
            return false;
        }
        return true;
    }

    render() {
        return (
            <Formik
                initialValues={this.props.values}
                validate={this.validate}
                validationSchema={this.validationSchema}
                onSubmit={formikSubmitHandler(this.onSubmit)}
                enableReinitialize={true}
            >
                {this.ClaimDialogFormik}
            </Formik>
        );
    }
}

const StyledClaimsDialogComponent = withStyles(styles)(ClaimsDialogComponent);
const I18nClaimsDialogComponent = withNamespaces()(StyledClaimsDialogComponent);

const mapStateToProps = ({auth, claimants}: RootState) => ({
    auth,
    claimants
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    addClaim: (claim: IClaim) => dispatch(createClaimAction(claim)),
    updateClaim: (claim: IClaim) => dispatch(updateClaimAction(claim)),
    showSnackBar: (payload: ISnackBarState) => dispatch(showSnackBarAction(payload))
});

export const ClaimsDialog = connect(mapStateToProps, mapDispatchToProps)(I18nClaimsDialogComponent);
