import {Languages} from '../../../i18n';

export const personalInformationData = (lang: string) => {
    if (lang === Languages.DE) {
        return [
            [
                'Welche Felder muss ich ausfüllen?',
                `
                    <p>Wir benötigen einige personenbezogene Daten, um Ihre Forderungseingabe bearbeiten zu können. Die mit einem (*) gekennzeichneten Felder sind Pflichtfelder.</p>
                    Pflichtfelder:
                    <ul>
                        <li>Anrede</li>
                        <li>Vor- und Nachname</li>
                        <li>Telefonnummer</li>
                        <li>E-Mail Adresse (bereits eingegeben und gesperrt von Ihrer Registrierung)</li>
                        <li>Bevorzugte Sprache für die Korrespondenz</li>
                        <li>Adresse</li>
                    </ul>
                    Optionale Felder:
                    <ul>
                        <li>Titel</li>
                        <li>Firma (Wichtiger Hinweis: Wenn Sie im Feld „Firma“ eine Eingabe tätigen, wird die Firma und nicht Sie persönlich als Gläubigerin der angemeldeten Forderungen erfasst.)</li>
                        <li>Zusätzliche</li>
                    </ul>
                `
            ],
            [
                'Warum kann ich die Registrierung nicht fortsetzen?',
                'Bitte überprüfen Sie, ob Sie alle Pflichtfelder ausgefüllt haben. Wenn Sie Pflichtfelder vergessen haben, werden diese rot markiert.'
            ],
            [
                'Was passiert, wenn ich keinen Zugriff mehr auf meine ursprüngliche Registrierungs-E-Mail-Adresse habe?',
                'In diesem Fall verwenden Sie bitte eine neue E-Mail-Adresse und laden im Abschnitt “Zusätzliche Dokumente” eine schriftliche Erklärung hoch, die sowohl die ursprüngliche als auch die neue E-Mail-Adresse, sowie die Gründe für den nicht möglichen Zugriff auf die ursprüngliche Registrierungs-E-Mail-Adresse enthält. Geben Sie so viele Details wie möglich an (Screenshots, Kommunikation mit dem E-Mail-Anbieter, etc.).'
            ],
            [
                'Mein Weiter/Zurück Button hat sich grau verfärbt und ich kann den Prozess nicht fortsetzen',
                'Wenn Ihre Dateinamen oder Ihr eingegebener Text die vorgegebene Zeichenanzahl überschreitet kann es dazu kommen, dass sie den Prozess nicht fortführen können. In diesem Fall bitten wir Sie darum ihre Dateinamen sowie Texte zu kürzen und diese erneut im Portal hochzuladen.'
            ],
            [
                'Ich habe Probleme mit dem Portal, was kann ich von meiner Seite aus machen?',
                'Bitte stellen Sie sicher, dass Sie Ihren Browser-Cache geleert haben, bevor Sie das Portal nutzen.'
            ]
        ];
    }

    return [
        [
            'What information do I need to fill in?',
            '<p>We need some personal information in order to process your claim registration. Fields with a (*) are mandatory.</p>' +
                'Mandatory fields:' +
                '<ul><li>salutation</li><li>first and last name</li><li>phone number</li><li>e-mail address (already entered and locked from your registration)</li><li>preferred language for correspondence</li><li>address</li></ul>' +
                'Optional fields:' +
                '<ul><li>title</li><li>company (Important note: If you make an entry in the "Company" field, the company and not you personally will be entered as the creditor of the registered claims.)</li><li>additional</li></ul>'
        ],
        [
            'Why can I not continue the registration?',
            'Please check if you have filled out all required fields. If you did not enter data into a mandatory field, it will be highlighted in red.'
        ],
        [
            'What if I no longer have access to my contribution e-mail address?',
            'In this case please use a new address and upload a written statement with both the original and new e-mail address and the reasons for the inability to access the original contribution e-mail address under the section “Additional documents”. Provide as many details as possible (screenshots, communication with email provider, etc).'
        ],
        [
            'My Next/Back button has turned gray and I cannot continue the process',
            'If your file name or text exceeds the specified number of characters, you may not be able to continue the process. In this case we ask you to reduce your file names and texts and upload them to the portal again.'
        ],
        ['I have issues with the portal, what can I do from my side?', 'Please insure you cleared your browser cache before using the portal.']
    ];
};
