import React from 'react';
import {Dispatch} from 'redux';
import {connect} from 'react-redux';
import {withTranslation as withNamespaces, WithTranslation as WithNamespaces} from 'react-i18next';
import Web3 from 'web3';
import {green, red} from '@material-ui/core/colors';
import {CardContent, CardActions, Grid, Button, Typography, Table, TableHead, TableRow, TableCell, TableBody, Chip, CircularProgress} from '@material-ui/core';
import {tokenAbi} from '../../../../libs/erc20-abi';
import {BigNumber} from 'bignumber.js';
import config from '../../../../config';
import {RootState} from '../../../../store';
import {IAuthState} from '../../../../store/auth/auth.types';
import {IClaimantsState, IClaimant} from '../../../../store/claimants/claimants.types';
import {IAccountState, AppRoles} from '../../../../store/account/account.types';
import {patchClaimant} from '../../../../libs/api/user/user';
import {InstallMetaMask} from '../../../../components/InstallMetaMask';
import {selectClaimantId as selectClaimantIdAction, updateClaimant as updateClaimantAction} from '../../../../store/claimants/claimants.actions';

interface IMetaMaskSectionProps extends WithNamespaces {
    account: IAccountState;
    auth: IAuthState;
    claimants: IClaimantsState;
    depositWalletAddress: string;
    submitting: boolean;
    enableMetaMaskCheck: boolean;
    updateClaimant(claimant: IClaimant): void;
    selectClaimantId(claimantId: string | null): void;
    handleBackClick(): void;
    handleComplete(): void;
}

interface IEthWindow extends Window {
    ethereum: any;
}

declare var window: IEthWindow;
declare var ethereum: any;

// export const options = {transactionConfirmationBlocks: 1} as Web3ModuleOptions;

class MetaMaskSectionComponent extends React.Component<IMetaMaskSectionProps> {
    state = {
        claimantWalletAddress: this.props.t('SendTokensStep.connectMetaMask').toString(),
        tokenBalance: '0',
        depositBalance: '0',
        sendingTokens: false,
        symbol: '-',
        depositWalletAddress: this.props.depositWalletAddress
    };

    timeout: any = 0;

    getWeb3 = async (): Promise<Web3 | null> => {
        if (!window.ethereum) {
            return null;
        }

        try {
            await ethereum.enable();
            return new Web3(ethereum);
            // tslint:disable-next-line: no-unused
        } catch (error) {
            return null;
        }
    };

    balanceOf = async (ethereumAddress: string | null) => {
        try {
            const web3 = await this.getWeb3();

            if (!web3) {
                throw Error(this.props.t('SendTokensStep.noAccessMetaMask').toString());
            }

            const tokenContract = new web3.eth.Contract(tokenAbi as [], config.APP_TOKEN_CONTRACT);
            return tokenContract.methods.balanceOf(ethereumAddress).call();
        } catch (error) {
            console.error(error);
            // User denied account access or other...
            // @TODO: better error handling
            throw error;
        }
    };

    getSymbol = async () => {
        return config.APP_TOKEN_SYMBOL;
    };

    handleTransferTokens = async () => {
        const {claimantWalletAddress, depositWalletAddress} = this.state;

        try {
            this.setState({sendingTokens: true});

            const ethErc20TokenAmount = await this.balanceOf(claimantWalletAddress);
            const web3 = await this.getWeb3();

            if (!web3) {
                throw Error(this.props.t('SendTokensStep.noAccessMetaMask').toString());
            }

            const tokenContract = new web3.eth.Contract(tokenAbi as [], config.APP_TOKEN_CONTRACT);
            const receipt = await tokenContract.methods.transfer(depositWalletAddress, ethErc20TokenAmount).send({from: claimantWalletAddress});
            const txHash = receipt.transactionHash;

            // Receipt should includes the Transfer event
            if (Object.keys(receipt.events).length > 0 && receipt.events.Transfer) {
                await this.addTransaction(txHash, this.ethToDecimalNumber(ethErc20TokenAmount));
                this.setState({tokenBalance: '0', sendingTokens: false});
            } else {
                this.setState({sendingTokens: false});
            }
            // tslint:disable-next-line: no-unused
        } catch (error) {
            const ethErc20TokenAmount = await this.balanceOf(claimantWalletAddress);
            await this.addTransaction(null, this.ethToDecimalNumber(ethErc20TokenAmount));

            this.setState({sendingTokens: false});
        }
    };

    addTransaction = async (txHash: string | null, quantity: string) => {
        const {auth, updateClaimant} = this.props;
        const role = this.getRole();
        const claimant = this.getClaimant();

        const _values = {
            from: this.state.claimantWalletAddress,
            to: this.state.depositWalletAddress,
            quantity,
            txHash
        };

        if (role === AppRoles.CLAIMANT_CLAIMANT) {
            try {
                const updatedClaimant = await patchClaimant(_values, 'add-transaction', null, auth.token);
                updateClaimant(updatedClaimant);
            } catch (error) {
                // @TODO: implement error handling
                console.log(error);
            }
        } else if (role === AppRoles.CLAIMANT_LEGAL_REPRESENTATIVE) {
            try {
                const updatedClaimant = await patchClaimant(_values, 'add-transaction', claimant.id, auth.token);
                updateClaimant(updatedClaimant);
            } catch (error) {
                // @TODO: implement error handling
                console.log(error);
            }
        }
    };

    decimalToEthNumber = (decimalNumber: string) => {
        const bn = new BigNumber(decimalNumber);
        const ethBn = bn.multipliedBy(Math.pow(10, 18));
        return ethBn.toFixed();
    };

    ethToDecimalNumber = (ethNumber: string) => {
        const bn = new BigNumber(ethNumber);
        const decimalBn = bn.dividedBy(Math.pow(10, 18));
        return decimalBn.toFixed();
    };

    getRole = () => {
        const {account, t} = this.props;

        if (!account.properties) {
            throw new Error(t('Roles.invalid').toString());
        }

        return account.properties.role;
    };

    getClaimant = () => {
        const {claimants} = this.props;
        return claimants.selectedClaimantId ? claimants.allClaimants[claimants.selectedClaimantId] : claimants.allClaimants[claimants.byId[0]];
    };

    componentDidMount = async () => {
        const web3 = await this.getWeb3();

        // tslint:disable-next-line: early-exit
        if (web3) {
            this.timeout = setInterval(() => {
                // tslint:disable-next-line: no-floating-promises
                web3.eth
                    .getAccounts()
                    .then((accounts: string[]) => {
                        this.setState({claimantWalletAddress: accounts[0]});
                        return accounts[0];
                    })
                    .then(async (claimantWalletAddress) => {
                        const tokenBalance = (await this.balanceOf(claimantWalletAddress)) ? await this.balanceOf(claimantWalletAddress) : '0';
                        const depositBalance = (await this.balanceOf(this.state.depositWalletAddress))
                            ? await this.balanceOf(this.state.depositWalletAddress)
                            : '0';
                        const symbol = await this.getSymbol();

                        this.setState({tokenBalance, symbol, depositBalance});
                    })
                    .catch((error) => {
                        // @TODO: do proper error handling
                        console.log(error);
                    });
            }, 1000);
        }
    };

    componentWillUnmount = () => {
        clearInterval(this.timeout);
    };

    validateEthAddress = (value: string) => {
        return Boolean(value && value.search(/^0x[a-fA-F0-9]{40}$/) === 0);
    };

    sendTokens = () => {
        const {t} = this.props;
        const {claimantWalletAddress, depositWalletAddress, tokenBalance, symbol, depositBalance, sendingTokens} = this.state;
        const claimantBalance = this.ethToDecimalNumber(tokenBalance);
        const _depositBalance = this.ethToDecimalNumber(depositBalance);

        return (
            <Grid container direction="row" spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h6">1. {t('SendTokensStep.step1')}</Typography>
                </Grid>
                <Grid item xs={12} style={{marginBottom: 20}}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell />
                                <TableCell>{t('SendTokensStep.address')}</TableCell>
                                <TableCell>{t('SendTokensStep.balance')}</TableCell>
                                <TableCell>{t('SendTokensStep.symbol')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>{t('SendTokensStep.claimantWalletAddress')}</TableCell>
                                <TableCell>
                                    {!this.validateEthAddress(claimantWalletAddress) ? (
                                        <Chip style={{color: red[800]}} variant="outlined" label={claimantWalletAddress} />
                                    ) : (
                                        <Chip onClick={this.showOnEtherscan} variant="outlined" label={claimantWalletAddress} />
                                    )}
                                </TableCell>
                                <TableCell>
                                    {claimantBalance === '0' ? (
                                        <Chip style={{color: red[800]}} variant="outlined" label={claimantBalance} />
                                    ) : (
                                        <Chip variant="outlined" label={claimantBalance} />
                                    )}
                                </TableCell>
                                <TableCell>
                                    <Chip variant="outlined" label={symbol} />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>{t('SendTokensStep.depositWalletAddress')}</TableCell>
                                <TableCell>
                                    {!this.validateEthAddress(depositWalletAddress) ? (
                                        <Chip style={{color: red[800]}} variant="outlined" label={depositWalletAddress} />
                                    ) : (
                                        <Chip onClick={this.showOnEtherscan} variant="outlined" label={depositWalletAddress} />
                                    )}
                                </TableCell>
                                <TableCell>
                                    {_depositBalance === '0' ? (
                                        <Chip style={{color: red[800]}} variant="outlined" label={_depositBalance} />
                                    ) : (
                                        <Chip style={{color: green[800]}} variant="outlined" label={_depositBalance} />
                                    )}
                                </TableCell>
                                <TableCell>
                                    <Chip variant="outlined" label={symbol} />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h6">2. {t('SendTokensStep.step2')}</Typography>
                </Grid>
                <Grid item xs={12} style={{marginBottom: 20}}>
                    <Button disabled={sendingTokens || claimantBalance === '0'} onClick={this.handleTransferTokens} color="primary" variant="contained">
                        {sendingTokens ? `${t('SendTokensStep.wait')}...` : t('SendTokensStep.transfer')}
                    </Button>
                    {sendingTokens ? <CircularProgress style={{marginLeft: 10, verticalAlign: 'middle'}} size={26} /> : null}
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h6">3. {t('SendTokensStep.step3')}</Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography paragraph>
                        <b>{t('SendTokensStep.remark1')}:</b> {t('SendTokensStep.remark2')}
                    </Typography>
                </Grid>
            </Grid>
        );
    };

    showOnEtherscan = async (event: any) => {
        event.persist();

        let url = 'https://etherscan.io/address/';
        if (config.APP_TOKEN_NETWORK === 'rinkeby') {
            url = 'https://rinkeby.etherscan.io/address/';
        }

        const etherScanLink = `${url}${event.target.innerText}`;
        window.open(etherScanLink, '_blank');
    };

    render = () => {
        const {t} = this.props;
        const {claimantWalletAddress, depositWalletAddress, depositBalance, sendingTokens} = this.state;
        const balance = this.ethToDecimalNumber(depositBalance);

        return (
            <React.Fragment>
                <CardContent>{this.sendTokens()}</CardContent>

                <CardActions>
                    <Grid container justify="space-between">
                        <Grid item>
                            <Button onClick={this.props.handleBackClick} disabled={this.props.submitting} type="button" color="default" variant="contained">
                                {t('Navigation.back')}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                onClick={this.props.handleComplete}
                                disabled={
                                    this.props.submitting ||
                                    sendingTokens === true ||
                                    balance === '0' ||
                                    !this.validateEthAddress(claimantWalletAddress) ||
                                    !this.validateEthAddress(depositWalletAddress)
                                }
                                color="primary"
                                variant="contained"
                            >
                                {t('Navigation.finish')}
                            </Button>
                        </Grid>
                    </Grid>
                </CardActions>

                <InstallMetaMask enableMetaMaskCheck={this.props.enableMetaMaskCheck} />
            </React.Fragment>
        );
    };
}

const mapStateToProps = ({auth, account, claimants}: RootState) => ({
    auth,
    account,
    claimants
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    updateClaimant: (claimant: IClaimant) => dispatch(updateClaimantAction(claimant)),
    selectClaimantId: (claimantId: string) => dispatch(selectClaimantIdAction(claimantId))
});

const I18nMetaMaskSectionComponent = withNamespaces()(MetaMaskSectionComponent);

export const MetaMaskSection = connect(mapStateToProps, mapDispatchToProps)(I18nMetaMaskSectionComponent);
