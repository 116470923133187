import React, {Component, ReactNode} from 'react';
// import classNames from 'classnames';
import {Theme} from '@material-ui/core/styles/createMuiTheme';
import {AppBar, Tab, Table, TableBody, TableCell, TableRow, Tabs, Typography, withStyles} from '@material-ui/core';
// import {Property} from 'csstype';
import {withTranslation as withNamespaces, WithTranslation as WithNamespaces} from 'react-i18next';
import {IClaimant, IFile} from '../../store/claimants/claimants.types';
import {Files} from '../../components/Files';
import {IAuthState} from '../../store/auth/auth.types';
import {AppRoles} from '../../store/account/account.types';

const styles = (_theme: Theme) => ({});

export interface IClaimantInfoProps extends WithNamespaces {
    claimant: IClaimant;
    auth: IAuthState;
    role: unknown;
    body: ReactNode;
}

class ClaimantInfoComponent extends Component<IClaimantInfoProps> {
    state = {
        tab: 0
    };

    handleTabChange = (_event: React.ChangeEvent<{}>, newValue: number) => {
        this.setState({tab: newValue});
    };

    mapLanguage = (value: string) => {
        const {t} = this.props;

        const mapping = {
            de: t('CompleteStep.de'),
            en: t('CompleteStep.en')
        } as any;

        return mapping[value];
    };

    getFiles = (files: IFile[], type: string) => {
        const {auth, claimant} = this.props;

        // tslint:disable-next-line: strict-type-predicates
        if (!files || files[0] === null || files.length === 0) {
            return '-';
        }

        return <Files type={type} auth={auth} files={files} folder={claimant.id} />;
    };

    getDepositAddress() {
        const {claimant} = this.props;

        if (!claimant.generatedWallets || claimant.generatedWallets.length === 0) {
            return null;
        }

        return claimant.generatedWallets[0].ethereumAddress;
    }

    mapHonorificPrefix = (value: string) => {
        const {t} = this.props;

        const mapping = {
            mr: t('CompleteStep.mr'),
            mrs: t('CompleteStep.mrs')
        } as any;

        return mapping[value];
    };

    getPersonalData = () => {
        const {t, claimant} = this.props;

        return (
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell style={{fontWeight: 'bold'}}>{t('CompleteStep.honorificPrefix')}</TableCell>
                        <TableCell>{this.mapHonorificPrefix(claimant.honorificPrefix)}</TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell style={{fontWeight: 'bold'}}>{t('CompleteStep.honorificSuffix')}</TableCell>
                        <TableCell>{claimant.honorificSuffix}</TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell style={{fontWeight: 'bold'}}>{t('CompleteStep.givenName')}</TableCell>
                        <TableCell>{claimant.givenName}</TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell style={{fontWeight: 'bold'}}>{t('CompleteStep.familyName')}</TableCell>
                        <TableCell>{claimant.familyName}</TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell style={{fontWeight: 'bold'}}>{t('CompleteStep.company')}</TableCell>
                        <TableCell>{claimant.company}</TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell style={{fontWeight: 'bold'}}>{t('CompleteStep.additional')}</TableCell>
                        <TableCell>{claimant.additional}</TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell style={{fontWeight: 'bold'}}>{t('CompleteStep.phoneNumber')}</TableCell>
                        <TableCell>{claimant.phoneNumber}</TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell style={{fontWeight: 'bold'}}>{t('CompleteStep.email')}</TableCell>
                        <TableCell>{claimant.email}</TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell style={{fontWeight: 'bold'}}>{t('CompleteStep.preferredLanguage')}</TableCell>
                        <TableCell>{this.mapLanguage(claimant.preferredLanguage)}</TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell style={{fontWeight: 'bold'}}>{t('CompleteStep.address1')}</TableCell>
                        <TableCell>{claimant.address1}</TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell style={{fontWeight: 'bold'}}>{t('CompleteStep.address2')}</TableCell>
                        <TableCell>{claimant.address2}</TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell style={{fontWeight: 'bold'}}>{t('CompleteStep.postalCode')}</TableCell>
                        <TableCell>{claimant.postalCode}</TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell style={{fontWeight: 'bold'}}>{t('CompleteStep.addressLocality')}</TableCell>
                        <TableCell>{claimant.addressLocality}</TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell style={{fontWeight: 'bold'}}>{t('CompleteStep.addressCountry')}</TableCell>
                        <TableCell>{claimant.addressCountry}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        );
    };

    getUploads = () => {
        const {t, claimant, role} = this.props;

        return (
            <Table size="small">
                <TableBody>
                    {role === AppRoles.CLAIMANT_LEGAL_REPRESENTATIVE ? (
                        <TableRow>
                            <TableCell style={{fontWeight: 'bold'}}>{t('CompleteStep.powerOfAttorneyLetter')}</TableCell>
                            <TableCell>{this.getFiles(claimant.powerOfAttorneyLetter, 'powerOfAttorneyLetter')}</TableCell>
                        </TableRow>
                    ) : null}

                    <TableRow>
                        <TableCell style={{fontWeight: 'bold'}}>{t('CompleteStep.passport')}</TableCell>
                        <TableCell>{this.getFiles(claimant.passport, 'passport')}</TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell style={{fontWeight: 'bold'}}>{t('CompleteStep.purchaseDocuments')}</TableCell>
                        <TableCell>{this.getFiles(claimant.purchaseDocuments, 'purchaseDocuments')}</TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell style={{fontWeight: 'bold'}}>{t('CompleteStep.signedClaimsDocument')}</TableCell>
                        <TableCell>{this.getFiles([claimant.signedClaimsDocument], 'signedClaimsDocument')}</TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell style={{fontWeight: 'bold', minWidth: 300}}>{t('CompleteStep.additionalDocuments')}</TableCell>
                        <TableCell>{this.getFiles(claimant.additionalDocuments, 'additionalDocuments')}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        );
    };

    render() {
        const {t, body, claimant} = this.props;
        const {tab} = this.state;

        return (
            <>
                {claimant ? (
                    <AppBar position="static" color="default">
                        <Tabs value={this.state.tab} onChange={this.handleTabChange} indicatorColor="primary" textColor="primary" aria-label="claimant data">
                            <Tab label={t('CompleteStep.personalData')} />
                            <Tab label={t('CompleteStep.uploads')} />
                        </Tabs>
                    </AppBar>
                ) : null}

                <div
                    style={{
                        marginTop: 20,
                        marginBottom: 10,
                        textAlign: 'center',
                        border: '2px solid rgba(0, 0, 0, 0.54)',
                        padding: 20,
                        borderRadius: 5
                    }}
                >
                    {/* {getConfig.APP_READONLY_MODE ? (
                        <Typography variant="body2" style={{textAlign: 'center', marginBottom: 10}}>
                            <b>{t('ReadonlyMode.announcement')}</b> @FIXME:
                        </Typography>
                    ) : null} */}

                    {/* {claimant.locked ? (
                        <Typography variant="body2" style={{textAlign: 'center', marginBottom: 10}}>
                            <b>{t('LockedClaimant.info')}</b> @FIXME:s
                        </Typography>
                    ) : null} */}

                    <Typography variant="body1" style={{textAlign: 'center'}}>
                        {body}
                    </Typography>

                    {claimant ? (
                        <Typography style={{marginTop: 10, textAlign: 'center'}} variant="h6">
                            {t('CompleteStep.depositAddress')}: {this.getDepositAddress() || t('CompleteStep.noDepositAddress')}
                        </Typography>
                    ) : null}
                </div>

                {claimant && tab === 0 && this.getPersonalData()}
                {claimant && tab === 1 && this.getUploads()}
            </>
        );
    }
}

const I18nClaimantInfoComponent = withNamespaces()(ClaimantInfoComponent);

export const ClaimantInfo = withStyles(styles, {withTheme: true})(I18nClaimantInfoComponent);
