import {Theme} from '@material-ui/core/styles/createMuiTheme';
import {Property} from 'csstype';

export const styles = (theme: Theme) => ({
    card: {
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: '900px',
        marginBottom: '5vh'
    },
    cardHeader: {
        backgroundColor: theme.palette.primary.main
    },
    cardHeaderTitle: {
        color: theme.palette.primary.contrastText,
        fontSize: '1.7rem'
    },
    submitButton: {
        marginTop: theme.spacing(3)
    },
    subTitle: {
        marginTop: '20px'
    },
    field: {
        padding: theme.spacing(1.5)
    },
    button: {},
    table: {},
    formControl: {},
    dropZoneWrapper: {
        fontFamily: 'Roboto',
        backgroundColor: theme.palette.background.paper,
        border: '3px solid #F0F0F0',
        padding: 20,
        borderRadius: 5
    },
    dropZoneCentered: {
        marginBottom: 10,
        minHeight: 180
    },
    dropZoneParagraph: {
        fontFamily: 'Roboto'
    },
    row: {
        marginBottom: theme.spacing(2)
    },
    colLeft: {
        marginRight: 0,
        [theme.breakpoints.up('md')]: {
            marginRight: theme.spacing(1.5)
        }
    },
    colRight: {
        marginLeft: 0,
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(1.5)
        }
    },
    fullWidth: {
        width: '100%'
    },
    fab: {},
    textRow: {
        marginBottom: theme.spacing(2)
    },
    marginControls: {
        marginTop: theme.spacing(4)
    },
    warning: {
        color: theme.palette.error.dark,
        fontWeight: 'bold' as Property.FontWeight,
        marginBottom: theme.spacing(2)
    }
});

export interface IClassesProperty {
    warning: string;
    marginControls: string;
    card: string;
    cardHeader: string;
    cardHeaderTitle: string;
    submitButton: string;
    subTitle: string;
    field: string;
    button: string;
    table: string;
    formControl: string;
    dropZoneCentered: string;
    dropZoneParagraph: string;
    dropZoneWrapper: string;
    row: string;
    colLeft: string;
    colRight: string;
    fullWidth: string;
    fab: string;
    textRow: string;
}
