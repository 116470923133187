import {AccountActionTypes} from './../account/account.types';
import {Reducer, AnyAction} from 'redux';
import {IClaimantsState, AllClaimants, IClaimant, ClaimantsActionTypes} from './claimants.types';

const initialState: IClaimantsState = {
    isFetching: false,
    allClaimants: {},
    byId: [],
    selectedClaimantId: null
};

const allClaimants = (state: AllClaimants = initialState.allClaimants, action: AnyAction): AllClaimants => {
    switch (action.type) {
        case ClaimantsActionTypes.ADD_CLAIMANT:
        case ClaimantsActionTypes.UPDATE_CLAIMANT:
            const updatedClaimants = {...state};
            const claimant: IClaimant = action.payload;

            updatedClaimants[claimant.id] = claimant;

            return updatedClaimants;
        case AccountActionTypes.GET_RESPONSE:
            const claimants = action.payload.user.claimants;

            if (!claimants) {
                return state;
            }

            // tslint:disable-next-line: no-shadowed-variable
            const allClaimants: AllClaimants = {};

            // tslint:disable-next-line: no-shadowed-variable
            claimants.forEach((claimant: IClaimant) => {
                allClaimants[claimant.id] = claimant;
            });

            return allClaimants;
        default:
            return state;
    }
};

const byId = (state: string[] = initialState.byId, action: AnyAction): string[] => {
    switch (action.type) {
        case ClaimantsActionTypes.ADD_CLAIMANT:
            const claimant: IClaimant = action.payload;

            state.push(claimant.id);

            return state;
        case AccountActionTypes.GET_RESPONSE:
            const claimants = action.payload.user.claimants;

            if (!claimants) {
                return state;
            }

            // tslint:disable-next-line: no-shadowed-variable
            return claimants.map((claimant: IClaimant) => claimant.id);
        default:
            return state;
    }
};

const isFetching = (state = initialState.isFetching, action: AnyAction): boolean => {
    switch (action.type) {
        case AccountActionTypes.GET:
        case AccountActionTypes.GET_REQUEST:
            return true;
        case AccountActionTypes.GET_RESPONSE:
        case AccountActionTypes.GET_ERROR:
            return false;
        default:
            return state;
    }
};

const selectedClaimantId = (state = initialState.selectedClaimantId, action: AnyAction): string | null => {
    switch (action.type) {
        case ClaimantsActionTypes.SELECT_CLAIMANT:
            return action.payload;
        case ClaimantsActionTypes.UNSELECT_CLAIMANT:
            return null;
        default:
            return state;
    }
};

export const claimantsReducer: Reducer<IClaimantsState> = (state = initialState, action) => ({
    isFetching: isFetching(state.isFetching, action),
    allClaimants: allClaimants(state.allClaimants, action),
    selectedClaimantId: selectedClaimantId(state.selectedClaimantId, action),
    byId: byId(state.byId, action)
});
