import React from 'react';
import {withStyles, Grid, Card, CardHeader, CardContent, Button, MenuItem, Typography} from '@material-ui/core';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {RootState} from '../../../store';
import {IClaimantsState, ClaimantStatus, IClaimant, IFile} from '../../../store/claimants/claimants.types';
import {IAuthState} from '../../../store/auth/auth.types';
import {selectClaimantId as selectClaimantIdAction, updateClaimant as updateClaimantAction} from '../../../store/claimants/claimants.actions';
import {patchClaimant, uploadFile, deleteFile} from '../../../libs/api/user/user';
import {Select} from '../../../components/Select';
import {Form, Formik, Field, FormikProps, FormikValues, FormikErrors} from 'formik';
import * as Yup from 'yup';
import {formikSubmitHandler, ConvertValidationErrorsFunction} from '../../../utils';
import {styles, IClassesProperty} from '../styles';
import {IAccountState, AppRoles} from '../../../store/account/account.types';
import {DropzoneArea} from 'material-ui-dropzone';
import {Files} from '../../../components/Files';
import Helmet from 'react-helmet';
import {TextArea} from '../../../components/TextArea';
import {showSnackBar as showSnackBarAction} from '../../../store/snackbar/snackbar.actions';
import {ISnackBarState} from '../../../store/snackbar/snackbar.types';
import {withTranslation as withNamespaces, WithTranslation as WithNamespaces} from 'react-i18next';
import {setFaq as setFaqAction} from '../../../store/faq/faq.actions';
import {IFaqState} from '../../../store/faq/faq.types';

export interface ITokenPurchaseFormValues {
    otherTokenPurchase: string;
    tokenPurchaseLocation: string;
    purchaseDocuments: IFile[];
}
export interface ITokenPurchaseStepState {
    files: [];
    iFiles: [];
    errors: boolean;
}

export interface ITokenPurchaseStepProps extends WithNamespaces {
    classes: IClassesProperty;
    auth: IAuthState;
    account: IAccountState;
    claimants: IClaimantsState;
    updateClaimant(claimant: IClaimant): void;
    selectClaimantId(claimantId: string | null): void;
    showSnackBar(payload: ISnackBarState): void;
    setFaq(type: IFaqState): void;
}

class TokenPurchaseStepComponent extends React.Component<ITokenPurchaseStepProps> {
    _isMounted = false;

    state = {
        files: [],
        iFiles: [],
        errors: true,
        submitting: false
    };

    validationSchema = Yup.object().shape({});

    componentDidMount() {
        this._isMounted = true;

        const initialValues = this.getInitialValuesFromClaimant();
        const {setFaq} = this.props;

        setFaq({type: ClaimantStatus.TOKEN_PURCHASE});

        if (initialValues.tokenPurchaseLocation === 'other' && initialValues.otherTokenPurchase.length > 0 && initialValues.otherTokenPurchase.length < 255) {
            this.setState({errors: false});
        }

        if (initialValues.tokenPurchaseLocation !== 'other') {
            this.setState({errors: false});
        }

        this.setState({iFiles: initialValues.purchaseDocuments ? initialValues.purchaseDocuments : []});
    }

    componentWillUnmount = () => {
        this._isMounted = false;
    };

    setSubmitting = (submitting = true) => {
        if (this._isMounted) {
            this.setState({submitting});
        }
    };

    getClaimant = () => {
        const {claimants} = this.props;
        return claimants.selectedClaimantId ? claimants.allClaimants[claimants.selectedClaimantId] : claimants.allClaimants[claimants.byId[0]];
    };

    getInitialValuesFromClaimant = () => {
        const claimant = this.getClaimant();

        return {
            otherTokenPurchase: claimant.otherTokenPurchase ? claimant.otherTokenPurchase : '',
            tokenPurchaseLocation: claimant.tokenPurchaseLocation ? claimant.tokenPurchaseLocation : '',
            purchaseDocuments: claimant.purchaseDocuments
        };
    };

    getRole = () => {
        const {account, t} = this.props;

        if (!account.properties) {
            throw new Error(t('Roles.invalid').toString());
        }

        return account.properties.role;
    };

    showError = (error: string) => {
        this.props.showSnackBar({message: error, variant: 'error'});
    };

    onDelete = async (type: string, file: IFile) => {
        const {auth, updateClaimant, t} = this.props;

        try {
            const claimant = this.getClaimant();

            const values = {
                id: file.id || '',
                type,
                file: file.fileNameHashed,
                path: claimant.id,
                status: ClaimantStatus.TOKEN_PURCHASE
            };

            this.setSubmitting();

            const updatedClaimant = await deleteFile(values, auth.token);
            updateClaimant(updatedClaimant);

            this.setState({
                iFiles: updatedClaimant.purchaseDocuments
            });

            this.setSubmitting(false);
        } catch (error) {
            console.error(error);
            this.showError(t('FileComponent.deleteFailed').toString());
        }
    };

    handleBackClick = async (props: FormikProps<ITokenPurchaseFormValues>) => {
        const {files} = this.state;
        const {auth, updateClaimant, t} = this.props;

        try {
            const role = this.getRole();
            const claimant = this.getClaimant();
            const claimantValues = this.getInitialValuesFromClaimant();
            const values = props.values;
            let resetClaims = false;

            if (
                claimant.tokenPurchaseLocation &&
                claimant.tokenPurchaseLocation !== values.tokenPurchaseLocation &&
                (values.tokenPurchaseLocation === 'multi' || claimant.tokenPurchaseLocation === 'multi')
            ) {
                resetClaims = true;
            }

            let _values = {
                ...values,
                resetClaims,
                status: ClaimantStatus.PASSPORT,
                purchaseDocuments: claimantValues.purchaseDocuments
            };

            if (values.tokenPurchaseLocation === 'other' && (values.otherTokenPurchase.length === 0 || values.otherTokenPurchase.length >= 255)) {
                this.setState({errors: true});
            } else if (values.tokenPurchaseLocation.length === 0) {
                this.setState({errors: true});
            }

            this.setSubmitting();

            if (this.isFormSubmitDisabled(props)) {
                // Update claimant in DB
                if (role === AppRoles.CLAIMANT_CLAIMANT) {
                    const updatedClaimant = await patchClaimant({status: ClaimantStatus.PASSPORT}, 'step-only', null, auth.token);
                    updateClaimant(updatedClaimant);
                } else if (role === AppRoles.CLAIMANT_LEGAL_REPRESENTATIVE) {
                    const updatedClaimant = await patchClaimant({status: ClaimantStatus.PASSPORT}, 'step-only', claimant.id, auth.token);
                    updateClaimant(updatedClaimant);
                }
                // tslint:disable-next-line: no-collapsible-if
            } else {
                // File upload
                if (files.length > 0) {
                    const payload = new FormData();
                    payload.append('claimantId', claimant.id);
                    payload.append('docType', 'purchaseDocuments');

                    files.forEach((file) => {
                        payload.append('files', file);
                    });

                    const uploadedFiles = await uploadFile(payload, auth.token);

                    if (!uploadedFiles) {
                        throw new Error(t('Form.failed').toString());
                    }

                    _values = {
                        ...values,
                        resetClaims,
                        status: ClaimantStatus.PASSPORT,
                        purchaseDocuments: uploadedFiles
                    };

                    // Update claimant in DB
                    if (role === AppRoles.CLAIMANT_CLAIMANT) {
                        const updatedClaimant = await patchClaimant(_values, 'token-purchase', null, auth.token);
                        updateClaimant(updatedClaimant);
                    } else if (role === AppRoles.CLAIMANT_LEGAL_REPRESENTATIVE) {
                        const updatedClaimant = await patchClaimant(_values, 'token-purchase', claimant.id, auth.token);
                        updateClaimant(updatedClaimant);
                    }
                }
                // tslint:disable-next-line: no-collapsible-if
                else {
                    // Update claimant in DB
                    if (role === AppRoles.CLAIMANT_CLAIMANT) {
                        const updatedClaimant = await patchClaimant(_values, 'token-purchase', null, auth.token);
                        updateClaimant(updatedClaimant);
                    } else if (role === AppRoles.CLAIMANT_LEGAL_REPRESENTATIVE) {
                        const updatedClaimant = await patchClaimant(_values, 'token-purchase', claimant.id, auth.token);
                        updateClaimant(updatedClaimant);
                    }
                }
            }
            this.setSubmitting(false);
        } catch (error) {
            console.error(error);
            this.showError(t('Form.failed').toString());
        }
    };

    onSubmit = async (values: FormikValues) => {
        const {files} = this.state;
        const {auth, updateClaimant, t} = this.props;

        try {
            const role = this.getRole();
            const claimant = this.getClaimant();
            const claimantValues = this.getInitialValuesFromClaimant();
            let resetClaims = false;

            if (
                claimant.tokenPurchaseLocation &&
                claimant.tokenPurchaseLocation !== values.tokenPurchaseLocation &&
                (values.tokenPurchaseLocation === 'multi' || claimant.tokenPurchaseLocation === 'multi')
            ) {
                resetClaims = true;
            }

            if (!values.tokenPurchaseLocation) {
                return;
            }

            let _values = {
                ...values,
                resetClaims,
                status: ClaimantStatus.ADDITIONAL_DOCUMENTS,
                purchaseDocuments: claimantValues.purchaseDocuments
            };

            this.setSubmitting();

            // File upload
            if (files.length > 0) {
                const payload = new FormData();
                payload.append('claimantId', claimant.id);
                payload.append('docType', 'purchaseDocuments');

                files.forEach((file) => {
                    payload.append('files', file);
                });

                const uploadedFiles = await uploadFile(payload, auth.token);

                if (!uploadedFiles) {
                    throw new Error(t('Form.failed').toString());
                }

                _values = {
                    ...values,
                    resetClaims,
                    status: ClaimantStatus.ADDITIONAL_DOCUMENTS,
                    purchaseDocuments: uploadedFiles
                };
            }

            // Update claimant in DB
            if (role === AppRoles.CLAIMANT_CLAIMANT) {
                const updatedClaimant = await patchClaimant(_values, 'token-purchase', null, auth.token);
                updateClaimant(updatedClaimant);
            } else if (role === AppRoles.CLAIMANT_LEGAL_REPRESENTATIVE) {
                const updatedClaimant = await patchClaimant(_values, 'token-purchase', claimant.id, auth.token);
                updateClaimant(updatedClaimant);
            }
            this.setSubmitting(false);
        } catch (error) {
            console.error(error);
            this.showError(t('Form.failed').toString());
        }
    };

    validate = (values: ITokenPurchaseFormValues) => {
        const {t} = this.props;
        const errors: FormikErrors<ITokenPurchaseFormValues> = {};

        if (values.tokenPurchaseLocation === 'other' && (values.otherTokenPurchase.length === 0 || values.otherTokenPurchase.length >= 255)) {
            errors.otherTokenPurchase = t('TokenPurchaseStep.otherTokenPurchase').toString();
            this.setState({errors: true});
        } else if (values.tokenPurchaseLocation.length === 0) {
            errors.tokenPurchaseLocation = t('TokenPurchaseStep.tokenPurchaseLocation').toString();
            this.setState({errors: true});
        } else {
            this.setState({errors: false});
        }

        return errors;
    };

    onFileChange = (files: []) => {
        this.setState({
            files
        });
    };

    stripFirstWord = (str: string) => {
        return str.substr(str.indexOf(' ') + 1);
    };

    convertValidationErrors: ConvertValidationErrorsFunction<FormikValues> = (validationErrors) => {
        return validationErrors.reduce<any>((result, validationError) => {
            const constrainKeys = Object.keys(validationError.constraints);

            if (constrainKeys.length > 1) {
                result[validationError.property] = (
                    <React.Fragment>
                        <ul style={{paddingLeft: 15, marginTop: 0}}>
                            {constrainKeys.map((constrainKey) => (
                                <li key={constrainKey}>{this.stripFirstWord(validationError.constraints[constrainKey])}.</li>
                            ))}
                        </ul>
                    </React.Fragment>
                );
            } else if (constrainKeys.length === 1) {
                result[validationError.property] = `${this.stripFirstWord(validationError.constraints[constrainKeys[0]])}.`;
            }

            return result;
        }, {});
    };

    handleFilesRejected = (_files: []) => {
        this.props.showSnackBar({message: `${this.props.t('Upload.invalid')}.`, variant: 'error'});
    };

    mapHonorificPrefix = (value: string) => {
        const {t} = this.props;

        const mapping = {
            mr: t('TokenPurchaseStep.pmr'),
            mrs: t('TokenPurchaseStep.pmrs')
        } as any;

        return mapping[value];
    };

    getFileUploadComponent = () => {
        const {t} = this.props;
        const claimant = this.getClaimant();

        const honorificPrefix = claimant.honorificPrefix ? `${this.mapHonorificPrefix(claimant.honorificPrefix)} ` : '';
        let salutation = t('TokenPurchaseStep.claimant.dropzoneText');

        if (this.getRole() === AppRoles.CLAIMANT_LEGAL_REPRESENTATIVE) {
            salutation = t('TokenPurchaseStep.legalRep.dropzoneText', {name: `${honorificPrefix}${claimant.givenName} ${claimant.familyName}`});
        }

        // Hack to resolve issues with i18n and missing error message if file count exceed, but file size is ok
        const DZ = DropzoneArea as any;

        return (
            <div className={this.props.classes.dropZoneWrapper}>
                <DZ
                    getFileLimitExceedMessage={() => t('Upload.getFileLimitExceedMessage10')}
                    getFileAddedMessage={() => t('Upload.getFileAddedMessage')}
                    getFileRemovedMessage={() => t('Upload.getFileRemovedMessage')}
                    getDropRejectMessage={() => t('Upload.getDropRejectMessage')}
                    showAlerts={true}
                    onDropRejected={this.handleFilesRejected}
                    onChange={this.onFileChange}
                    acceptedFiles={['image/*', 'application/pdf']}
                    dropzoneText={salutation.toString()}
                    filesLimit={10}
                    maxFileSize={10000000}
                    dropzoneClass={this.props.classes.dropZoneCentered}
                    showPreviewsInDropzone={false}
                    showPreviews={true}
                    dropzoneParagraphClass={this.props.classes.dropZoneParagraph}
                    showFileNamesInPreview={true}
                />
            </div>
        );
    };

    isFormSubmitDisabled = (_props: FormikProps<ITokenPurchaseFormValues>) => {
        const {iFiles, files, errors} = this.state;

        return (iFiles.length === 0 && files.length === 0) || errors === true;
    };

    TokenPurchaseForm = (props: FormikProps<ITokenPurchaseFormValues>) => {
        const {auth, classes, t} = this.props;
        const {iFiles, files} = this.state;
        const claimant = this.getClaimant();
        const role = this.getRole();
        const label = role === AppRoles.CLAIMANT_LEGAL_REPRESENTATIVE ? t('TokenPurchaseStep.legalRep.head1') : t('TokenPurchaseStep.claimant.head1');

        return (
            <Form>
                <CardContent>
                    <Typography variant="h6">{label}</Typography>
                    <Field
                        className={classes.row}
                        name="tokenPurchaseLocation"
                        label={t('TokenPurchaseStep.label')}
                        err={props.errors.tokenPurchaseLocation}
                        component={Select}
                    >
                        <MenuItem value="ico">{t('TokenPurchaseStep.item.ico')}</MenuItem>
                        <MenuItem value="secondaryMarket">{t('TokenPurchaseStep.item.secondaryMarket')}</MenuItem>
                        <MenuItem value="privatePerson">{t('TokenPurchaseStep.item.privatePerson')}</MenuItem>
                        <MenuItem value="presaleOrPrivatePlacement">{t('TokenPurchaseStep.item.presaleOrPrivatePlacement')}</MenuItem>
                        <MenuItem value="founders">{t('TokenPurchaseStep.item.founders')}</MenuItem>
                        <MenuItem value="multi">{t('TokenPurchaseStep.item.multi')}</MenuItem>
                        <MenuItem value="other">{t('TokenPurchaseStep.item.other')}</MenuItem>
                    </Field>

                    {props.values.tokenPurchaseLocation && props.values.tokenPurchaseLocation === 'other' ? (
                        <div style={{marginBottom: 10}}>
                            <Field name="otherTokenPurchase" label={t('TokenPurchaseStep.otherTokenPurchaseLabel')} component={TextArea} />
                        </div>
                    ) : null}

                    {files.length > 0 && iFiles.length > 0 ? <Typography className={classes.warning}>{t('Upload.replaceInfo')}!</Typography> : null}
                    {files.length === 0 && iFiles.length > 0 ? (
                        <Files type="purchaseDocuments" onDelete={this.onDelete} auth={auth} files={iFiles} folder={claimant.id} />
                    ) : null}

                    <Field name="purchaseDocuments" label={t('TokenPurchaseStep.selectFile')} type="file" component={this.getFileUploadComponent} />

                    {role === AppRoles.CLAIMANT_CLAIMANT ? (
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography variant="h6" style={{marginTop: 20}}>
                                    {t('TokenPurchaseStep.claimant.head1')}
                                </Typography>
                                <Typography variant="body2" component="div" style={{marginTop: 10}}>
                                    {t('TokenPurchaseStep.claimant.p1')} <b>{t('TokenPurchaseStep.claimant.p2')}</b> {t('TokenPurchaseStep.claimant.p3')}.
                                </Typography>
                                <Typography variant="body2" component="div">
                                    <ol>
                                        <li>
                                            {t('TokenPurchaseStep.claimant.li1')}{' '}
                                            <a rel="noopener noreferrer" href="/Envion_NoPurchaseDocuments.pdf" target="_blank">
                                                {t('TokenPurchaseStep.claimant.li2')}
                                            </a>{' '}
                                            {t('TokenPurchaseStep.claimant.li3')}{' '}
                                            <a rel="noopener noreferrer" href="/Envion_KeineErwerbsdokumente.pdf" target="_blank">
                                                {t('TokenPurchaseStep.claimant.li4')}
                                            </a>{' '}
                                            {t('TokenPurchaseStep.claimant.li5')}.
                                        </li>
                                        <li>{t('TokenPurchaseStep.claimant.li6')}.</li>
                                        <li>{t('TokenPurchaseStep.claimant.li7')}.</li>
                                    </ol>
                                </Typography>
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography variant="h6" style={{marginTop: 20}}>
                                    {t('TokenPurchaseStep.legalRep.head1')}
                                </Typography>
                                <Typography variant="body2" component="div" style={{marginTop: 10}}>
                                    {t('TokenPurchaseStep.legalRep.p1')} <b>{t('TokenPurchaseStep.legalRep.p2')}</b> {t('TokenPurchaseStep.legalRep.p3')}.
                                </Typography>
                                <Typography variant="body2" component="div">
                                    <ol>
                                        <li>
                                            {t('TokenPurchaseStep.legalRep.li1')}{' '}
                                            <a rel="noopener noreferrer" href="/Envion_NoPurchaseDocuments.pdf" target="_blank">
                                                {t('TokenPurchaseStep.legalRep.li2')}
                                            </a>{' '}
                                            {t('TokenPurchaseStep.legalRep.li3')}{' '}
                                            <a rel="noopener noreferrer" href="/Envion_KeineErwerbsdokumente.pdf" target="_blank">
                                                {t('TokenPurchaseStep.legalRep.li4')}
                                            </a>{' '}
                                            {t('TokenPurchaseStep.legalRep.li5')}.
                                        </li>
                                        <li>{t('TokenPurchaseStep.legalRep.li6')}.</li>
                                        <li>{t('TokenPurchaseStep.legalRep.li7')}.</li>
                                    </ol>
                                </Typography>
                            </Grid>
                        </Grid>
                    )}

                    <Grid container justify="space-between" alignItems="flex-end" style={{marginTop: 20}}>
                        <Grid item>
                            <Button
                                disabled={this.state.submitting}
                                onClick={async () => this.handleBackClick(props)}
                                type="button"
                                color="default"
                                variant="contained"
                            >
                                {t('Navigation.back')}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button disabled={this.state.submitting || this.isFormSubmitDisabled(props)} type="submit" color="primary" variant="contained">
                                {t('Navigation.next')}
                            </Button>
                        </Grid>
                    </Grid>
                </CardContent>
            </Form>
        );
    };

    render() {
        const {classes, t} = this.props;

        return (
            <React.Fragment>
                <Helmet>
                    <title>{t('TokenPurchaseStep.title')}</title>
                </Helmet>
                <Card className={classes.card}>
                    <CardHeader
                        classes={{
                            root: classes.cardHeader,
                            title: classes.cardHeaderTitle
                        }}
                        title={t('TokenPurchaseStep.title')}
                        style={{textAlign: 'center'}}
                    />
                    <Formik
                        initialValues={this.getInitialValuesFromClaimant()}
                        validate={this.validate}
                        validationSchema={this.validationSchema}
                        onSubmit={formikSubmitHandler(this.onSubmit, {convertValidationErrors: this.convertValidationErrors})}
                        enableReinitialize={true}
                    >
                        {this.TokenPurchaseForm}
                    </Formik>
                </Card>
            </React.Fragment>
        );
    }
}

const StyledTokenPurchaseStepComponent = withStyles(styles)(TokenPurchaseStepComponent);
const I18nTokenPurchaseStepComponent = withNamespaces()(StyledTokenPurchaseStepComponent);

const mapStateToProps = ({auth, account, claimants}: RootState) => ({
    auth,
    account,
    claimants
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    updateClaimant: (claimant: IClaimant) => dispatch(updateClaimantAction(claimant)),
    selectClaimantId: (claimantId: string) => dispatch(selectClaimantIdAction(claimantId)),
    showSnackBar: (payload: ISnackBarState) => dispatch(showSnackBarAction(payload)),
    setFaq: (type: IFaqState) => dispatch(setFaqAction(type))
});

export const TokenPurchaseStep = connect(mapStateToProps, mapDispatchToProps)(I18nTokenPurchaseStepComponent);
