import React, {Component} from 'react';
import {Dispatch} from 'redux';
import {connect} from 'react-redux';
import {initializeApplication as initializeApplicationAction} from '../../store/application/application.actions';
import {MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';
import {ThemeOptions} from '@material-ui/core/styles/createMuiTheme';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

const getMuiThemeOptions = (): ThemeOptions => {
    // Allow usage of breakpoints in create mui theme
    // tslint:disable:no-unused
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const breakpoints = createBreakpoints({});

    return {
        palette: {
            common: {black: '#000', white: '#fff'},
            background: {paper: '#fff', default: '#fafafa'},
            primary: {
                light: '#3CBFF4',
                main: '#3584c5',
                dark: '#006391',
                contrastText: '#f2f2f2'
            },
            secondary: {
                light: '#fffb50',
                main: '#3e90c2',
                dark: '#006391',
                contrastText: '#f2f2f2'
            },
            error: {
                light: '#e57373',
                main: '#f44336',
                dark: '#d32f2f',
                contrastText: '#ffffff'
            },
            text: {
                primary: 'rgba(0, 0, 0, 0.87)',
                secondary: 'rgba(0, 0, 0, 0.54)',
                disabled: 'rgba(0, 0, 0, 0.38)',
                hint: 'rgba(0, 0, 0, 0.38)'
            }
        },
        overrides: {
            MuiAppBar: {
                // @TODO: Check why the background color is not applied to the app bar component, if the color property is set to default.
                colorDefault: {
                    backgroundColor: '#f5f5f5'
                }
            },
            MuiFormControl: {
                root: {
                    margin: 0,
                    minWidth: 120,
                    width: '100%',
                    color: 'rgba(0, 24, 62, 1)'
                }
            },
            MuiCardActions: {
                root: {
                    padding: 16
                }
            },
            MuiDialogActions: {
                root: {
                    padding: 16
                }
            },
            MuiTypography: {
                h1: {
                    textTransform: 'uppercase',
                    fontSize: '2rem',
                    fontWeight: 400
                },
                h2: {
                    fontSize: '1.5rem',
                    fontWeight: 400,
                    marginTop: '25px',
                    marginBottom: 0
                }
            },
            MuiButton: {
                textPrimary: {
                    color: '#1d8ee0'
                }
            }
        }
    };
};

const theme = createMuiTheme(getMuiThemeOptions());

export interface IShellComponentProps {
    children: React.ReactNode;
}

interface IAuthCallbackDispatchProps {
    initializeApplication(): void;
}

export class RootComponent extends Component<IShellComponentProps & IAuthCallbackDispatchProps> {
    componentDidMount() {
        const {initializeApplication} = this.props;

        initializeApplication();
    }

    render() {
        const {children} = this.props;
        return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    initializeApplication: () => dispatch(initializeApplicationAction())
});

const Root = connect(null, mapDispatchToProps)(RootComponent);

export {Root};
