import {action} from 'typesafe-actions';
import {ClaimsActionTypes, IClaim} from './claims.types';

export const getClaims = (claimantId: string) => action(ClaimsActionTypes.GET, claimantId);
export const getClaimsRequest = () => action(ClaimsActionTypes.GET_REQUEST);
export const getClaimsResponse = (user: IClaim) => action(ClaimsActionTypes.GET_RESPONSE, {user});
export const getClaimsError = (error: any) => action(ClaimsActionTypes.GET_ERROR, {error});

export const selectClaimId = (claimId: string) => action(ClaimsActionTypes.SELECT_CLAIM, claimId);
export const unselectClaim = action(ClaimsActionTypes.UNSELECT_CLAIM);
export const createClaim = (claim: IClaim) => action(ClaimsActionTypes.ADD_CLAIM, claim);
export const updateClaim = (claim: IClaim) => action(ClaimsActionTypes.UPDATE_CLAIM, claim);
export const deleteClaimById = (claimId: string) => action(ClaimsActionTypes.DELETE_CLAIM, claimId);
