import React, {Component} from 'react';
import {Dispatch} from 'redux';
import {Redirect} from 'react-router';
import {Card, CardHeader, CardContent, Grid, withStyles, Typography} from '@material-ui/core';
import {styles, IClassesProperty} from '../styles';
import moment from 'moment';
import {connect} from 'react-redux';
import {RootState} from '../../../store';
import {IAccountState} from '../../../store/account/account.types';
import {withTranslation as withNamespaces, WithTranslation as WithNamespaces} from 'react-i18next';

interface IPersonalInformationProps extends WithNamespaces {
    classes: IClassesProperty;
    account: IAccountState;
}

class PersonalInformationComponent extends Component<IPersonalInformationProps> {
    render() {
        const {account, t, classes} = this.props;

        if (!account.properties) {
            return <Redirect to="/login" />;
        }

        const lastLogin = account.properties.lastLogin
            ? moment(account.properties.lastLogin).format('MMMM Do YYYY, HH:mm:ss')
            : t('PersonalInformationComponent.never');

        return (
            <Card className={classes.card}>
                <CardHeader classes={{root: classes.cardHeader, title: classes.cardHeaderTitle}} title={t('PersonalInformationComponent.head')} />
                <CardContent>
                    <Grid container alignItems="stretch" direction="column" spacing={3}>
                        <Grid item>
                            <Typography variant="caption">{t('PersonalInformationComponent.mail')}</Typography>
                            <Typography variant="body1">{account.properties.email}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="caption">{t('PersonalInformationComponent.lastLogin')}</Typography>
                            <Typography variant="body1">{lastLogin}</Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        );
    }
}

export const StyledPersonalInformationComponent = withStyles(styles)(PersonalInformationComponent);
const I18nPersonalInformationComponent = withNamespaces()(StyledPersonalInformationComponent);

const mapStateToProps = ({account}: RootState) => ({
    account
});

const mapDispatchToProps = (_dispatch: Dispatch) => ({});

export const PersonalInformation = connect(mapStateToProps, mapDispatchToProps)(I18nPersonalInformationComponent);
