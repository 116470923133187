import React, {FunctionComponent} from 'react';
import {withRouter, RouteComponentProps} from 'react-router-dom';
import {Dispatch} from 'redux';
import {connect} from 'react-redux';
import {RootState} from '../../../store';
import {AppRoles, IAccountState} from '../../../store/account/account.types';
import {logout as logoutAction} from '../../../store/auth/auth.actions';
import {Property} from 'csstype';
import {withStyles} from '@material-ui/core/styles';
import {Theme} from '@material-ui/core/styles/createMuiTheme';
import Popover from '@material-ui/core/Popover';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {LinkButton} from '../../../components/LinkButton';
import Gravatar from 'react-gravatar';
import AccountCircle from '@material-ui/icons/AccountCircle';
import {withTranslation as withNamespaces, WithTranslation as WithNamespaces} from 'react-i18next';
import getConfig from '../../../config';

const styles = (theme: Theme) => ({
    account: {
        display: 'flex',
        margin: theme.spacing(2)
    },
    actions: {
        display: 'flex',
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
        flexDirection: 'row' as Property.FlexDirection,
        justifyContent: 'flex-end',
        backgroundColor: '#f5f5f5'
    },
    accountProfileImage: {
        width: '100px',
        height: '100px',
        borderRadius: '50px'
    },
    accountDetails: {
        marginLeft: theme.spacing(2)
    },
    accountButton: {
        marginTop: theme.spacing(2)
    }
});

export interface IUserPopoverComponentProps extends RouteComponentProps, WithNamespaces {
    classes: {
        account: string;
        actions: string;
        accountProfileImage: string;
        accountDetails: string;
        accountButton: string;
    };
    anchorEl: any;
    open: boolean;
    account: IAccountState;
    logout(): void;
    onClose(): void;
}

const UserPopoverComponent: FunctionComponent<IUserPopoverComponentProps> = (props) => {
    const {classes, anchorEl, open, onClose, logout, account, t, history} = props;

    const logoutAndRedirect = () => {
        logout();
        history.push('/');

    };

    return (
        <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center'
            }}
        >
            <div className={classes.account}>
                {account.properties ? (
                    <Gravatar className={classes.accountProfileImage} email={account.properties.email} size={100} default="mm" />
                ) : (
                    <AccountCircle className={classes.accountProfileImage} />
                )}
                <div className={classes.accountDetails}>
                    <Typography variant="caption" component="div">
                        {account.properties ? account.properties.email : null}
                    </Typography>

                    {!getConfig.APP_READONLY_MODE || (getConfig.APP_READONLY_MODE && account.properties && account.properties.role !== AppRoles.CLAIMANT_UNDECIDED) ? (
                        <LinkButton variant="contained" color="primary" to="/account" onClick={onClose} className={classes.accountButton}>
                            {t('UserPopoverComponent.account')}
                        </LinkButton>
                    ) : null}
                </div>
            </div>
            <Divider />
            <div className={classes.actions}>
                <Button variant="outlined" onClick={() => logoutAndRedirect()}>
                    {t('UserPopoverComponent.logout')}
                </Button>
            </div>
        </Popover>
    );
};

const StyledUserPopoverComponent = withStyles(styles)(UserPopoverComponent);
const I18nUserPopoverComponent = withNamespaces()(StyledUserPopoverComponent);

const mapStateToProps = ({account}: RootState) => ({
    account
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    logout: () => dispatch(logoutAction())
});

export const UserPopover = withRouter(connect(mapStateToProps, mapDispatchToProps)(I18nUserPopoverComponent));
