import {combineReducers, AnyAction, Reducer} from 'redux';
import {connectRouter} from 'connected-react-router';
import {getHistory} from '../history';

import {accountReducer} from './account/account.reducer';
import {authReducer} from './auth/auth.reducer';
import {notificationReducer} from './notification/notification.reducer';
import {RootState} from '.';
import {AuthActionTypes} from './auth/auth.types';
import {claimantsReducer} from './claimants/claimants.reducer';
import {claimsReducer} from './claims/claims.reducer';
import {snackbarReducer} from './snackbar/snackbar.reducer';
import {faqReducer} from './faq/faq.reducer';

const appReducer = combineReducers({
    router: connectRouter(getHistory()),
    account: accountReducer,
    auth: authReducer,
    notification: notificationReducer,
    claimants: claimantsReducer,
    claims: claimsReducer,
    snack: snackbarReducer,
    faq: faqReducer
});

// tslint:disable-next-line: no-unnecessary-type-annotation
export const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
    if (action.type === AuthActionTypes.AUTH_LOGOUT_SUCCESS) {
        // tslint:disable-next-line: no-parameter-reassignment
        state = undefined;
    }

    return appReducer(state, action);
};
