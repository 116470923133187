import React from 'react';
import {Dispatch} from 'redux';
import {connect} from 'react-redux';
import {RootState} from '../../store';
import {IAuthState} from '../../store/auth/auth.types';
import {logout as logoutAction} from '../../store/auth/auth.actions';
import {IAccountState, AppRoles} from '../../store/account/account.types';
import classNames from 'classnames';
import {Theme} from '@material-ui/core/styles/createMuiTheme';
import {CssBaseline, withStyles, WithStyles, createStyles, Toolbar, AppBar, IconButton, BottomNavigationAction, BottomNavigation} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import QuestionAnswer from '@material-ui/icons/QuestionAnswer';
import {DRAWER_WIDTH} from './shell-constants';
import {Drawer} from './Drawer';
import {Logo} from './Logo';
import {TopMenu} from './TopMenu';
import {FAQ} from '../../components/FAQ';
import {IClaimantsState} from '../../store/claimants/claimants.types';
import {SnackBar} from '../../components/SnackBar';
import {withTranslation as withNamespaces, WithTranslation as WithNamespaces} from 'react-i18next';
import {LockIcon} from '../../components/LockIcon';
import {Countdown} from '../../components/Countdown';
import moment from 'moment';
import getConfig from '../../config';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            display: 'flex'
        },
        appBar: {
            transition: theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen
            })
        },
        appBarShift: {
            width: `calc(100% - ${DRAWER_WIDTH}px)`,
            marginLeft: DRAWER_WIDTH,
            transition: theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen
            })
        },
        grow: {
            flexGrow: 1
        },
        hide: {
            display: 'none'
        },
        logoWithMenu: {
            marginLeft: 12
        },
        logoWithoutMenu: {
            marginLeft: 20
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(3)
        },
        contentWithDrawer: {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen
            }),
            marginLeft: -DRAWER_WIDTH
        },
        contentShift: {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen
            }),
            marginLeft: 0
        },
        bottomNavigation: {
            position: 'fixed',
            zIndex: 100,
            width: '100%',
            bottom: 0,
            backgroundColor: '#f5f5f5'
        },
        children: {
            marginBottom: 60
        },
        counterWrapper: {
            zIndex: -1,
            marginTop: 5,
            marginBottom: 5,
            textAlign: 'center',
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                marginLeft: '-66px'
            }
        },
        counterText: {
            color: theme.palette.primary.dark,
            fontWeight: 'bold'
        },
        readOnlyHeader: {
            textAlign: 'center',
            width: '100%',
            color: theme.palette.primary.dark
        }
    });

interface IAuthCallbackStateProps {
    auth: IAuthState;
    account: IAccountState;
}

interface IAuthCallbackDispatchProps {
    logout(): void;
}

export interface IShellComponentProps extends WithStyles<typeof styles>, IAuthCallbackStateProps, IAuthCallbackDispatchProps, WithNamespaces {
    children: React.ReactNode;
    enableHeaderAndDrawer?: boolean;
    disableNavigation?: boolean;
    claimants: IClaimantsState;
}

class ShellComponent extends React.Component<IShellComponentProps> {
    static defaultProps = {
        enableHeaderAndDrawer: false,
        disableNavigation: false
    };

    state = {
        open: false,
        faqOpen: false
    };

    handleDrawerOpen = () => {
        this.setState({open: true});
    };

    handleDrawerClose = () => {
        this.setState({open: false});
    };

    handleFAQOpen = () => {
        this.setState({faqOpen: true});
    };

    handleFAQClose = () => {
        this.setState({faqOpen: false});
    };

    isDrawerEnabled() {
        const {disableNavigation, auth, account} = this.props;
        return Boolean(!disableNavigation && auth.isAuthenticated && account.properties && account.properties.role === AppRoles.CLAIMANT_LEGAL_REPRESENTATIVE);
    }

    getRole = () => {
        const {account, t} = this.props;

        if (!account.properties) {
            throw new Error(t('Roles.invalid').toString());
        }

        return account.properties.role;
    };

    getEid = () => {
        const {claimants, auth, account} = this.props;

        if (!auth.isAuthenticated || !account.properties) {
            return null;
        }

        if (this.getRole() === AppRoles.CLAIMANT_LEGAL_REPRESENTATIVE && claimants.selectedClaimantId === null) {
            return null;
        }

        const claimant = claimants.selectedClaimantId ? claimants.allClaimants[claimants.selectedClaimantId] : claimants.allClaimants[claimants.byId[0]];

        if (!claimant) {
            return null;
        }

        return `ID: EN${claimant.eId.toString().padStart(6, '0')}`;
    };

    render() {
        const {enableHeaderAndDrawer, children, t, classes} = this.props;
        const isDrawerEnabled = this.isDrawerEnabled();

        let isCounterEnabled = false;
        if (getConfig.APP_COUNTDOWN && getConfig.APP_COUNTDOWN.length > 0) {
            isCounterEnabled = true;
        }
        const isReadOnlyEnabled = getConfig.APP_READONLY_MODE;

        let spacing = 70;

        if (isCounterEnabled && !isReadOnlyEnabled) {
            spacing = 100;
        }
        if (isCounterEnabled && isReadOnlyEnabled) {
            spacing = 130;
        }

        const readOnlyHeader = (
            <div className={classes.readOnlyHeader}>
                <span style={{verticalAlign: 'middle'}}>
                    <LockIcon locked />
                </span>
                <span style={{marginLeft: 10, marginRight: 10}}>{t('ReadonlyMode.title')}</span>
            </div>
        );

        if (enableHeaderAndDrawer) {
            const {open} = this.state;

            return (
                <div className={classes.root}>
                    <CssBaseline />

                    <AppBar
                        position="fixed"
                        color="default"
                        className={classNames(classes.appBar, {
                            [classes.appBarShift]: open
                        })}
                    >
                        <Toolbar>
                            {isDrawerEnabled && (
                                <IconButton
                                    color="inherit"
                                    aria-label="Open drawer"
                                    onClick={this.handleDrawerOpen}
                                    className={classNames(open && classes.hide)}
                                >
                                    <MenuIcon />
                                </IconButton>
                            )}
                            <Logo className={isDrawerEnabled ? classes.logoWithMenu : classes.logoWithoutMenu} />

                            {/* <Grid container spacing={0} direction="row" alignItems="center" justify="center">
                                <Grid item xs={11} style={{textAlign: 'center'}}>
                                    <Typography>{this.getEid()}</Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    &nbsp;
                                </Grid>
                            </Grid> */}

                            <div className={classes.counterWrapper}>
                                {isReadOnlyEnabled ? readOnlyHeader : null}
                                {isCounterEnabled ? (
                                    <>
                                        <div className={classes.counterText}>{t('Countdown.info')}</div>
                                        <Countdown countDownDate={moment.utc(getConfig.APP_COUNTDOWN).format('YYYY-MM-DDTHH:mm:ss[Z]')} />
                                    </>
                                ) : null}
                            </div>

                            <div className={classes.grow} />
                            <TopMenu />
                        </Toolbar>
                    </AppBar>

                    {this.isDrawerEnabled() && <Drawer open={open} handleDrawerClose={this.handleDrawerClose} />}

                    <main
                        className={classNames(classes.content, {
                            [classes.contentWithDrawer]: isDrawerEnabled,
                            [classes.contentShift]: isDrawerEnabled && open
                        })}
                    >
                        <Toolbar style={{minHeight: spacing}} />

                        <div className={classes.children}>{children}</div>
                    </main>

                    <BottomNavigation className={classes.bottomNavigation} showLabels>
                        <BottomNavigationAction onClick={this.handleFAQOpen} style={{color: 'black'}} label="FAQ" value="FAQ" icon={<QuestionAnswer />} />
                    </BottomNavigation>

                    <SnackBar />
                    <FAQ handleFAQClose={this.handleFAQClose} faqOpen={this.state.faqOpen} />
                </div>
            );
        }

        return (
            <React.Fragment>
                <SnackBar />
                <main>{children}</main>
            </React.Fragment>
        );
    }
}

export const StyledShellComponent = withStyles(styles)(ShellComponent);
const I18nShellComponent = withNamespaces()(StyledShellComponent);

const mapStateToProps = ({auth, account, claimants}: RootState) => ({
    auth,
    account,
    claimants
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    logout: () => dispatch(logoutAction())
});

const Shell = connect(mapStateToProps, mapDispatchToProps)(I18nShellComponent);

export {Shell};
