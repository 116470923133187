// tslint:disable:jsx-no-lambda react-this-binding-issue
import React, {Component} from 'react';
import {Dispatch} from 'redux';
import {connect} from 'react-redux';
import {withRouter, Route, Redirect, RouteComponentProps} from 'react-router';
import {LocationDescriptor} from 'history';
import {Shell} from '../Shell';
import {RootState} from '../../store';
import {IAccountState /*, AppRoles*/} from '../../store/account/account.types';
import {getAccount as getAccountAction} from '../../store/account/account.actions';
import {IAuthState} from '../../store/auth/auth.types';
import {withStyles, Grid, CircularProgress, Typography} from '@material-ui/core';
import {Theme} from '@material-ui/core/styles/createMuiTheme';
import {withTranslation as withNamespaces, WithTranslation as WithNamespaces} from 'react-i18next';

export const styles = (theme: Theme) => ({
    errorMessageContainer: {
        marginTop: theme.spacing(4)
    },
    errorMessage: {
        fontSize: '1.7rem'
    }
});

export enum AccessRestriction {
    private,
    public
}

interface IAppRouteComponentStateProps {
    account: IAccountState;
    auth: IAuthState;
}

interface IAppRouteComponentProps {
    classes: {
        errorMessageContainer: string;
        errorMessage: string;
    };

    /**
     * A React component to render only when the location matches
     * Warning: <AppRoute component> takes precedence over <AppRoute redirect> so don’t use both in the same <AppRoute>.
     */
    component?: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any> | null;

    /**
     * Redirect target of the route
     * Warning: <AppRoute component> takes precedence over <AppRoute redirect> so don’t use both in the same <AppRoute>.
     */
    redirectTo?: LocationDescriptor | null;

    accessRestriction?: AccessRestriction | null;
    accessRestrictionRedirect?: LocationDescriptor | null;
    disableHeaderAndDrawer?: boolean;
    disableNavigation?: boolean;

    path?: string | string[];
    exact?: boolean;
    sensitive?: boolean;
    strict?: boolean;

    getAccount(): void;
}

interface IAppRouteComponentState {
    isInitialized: boolean;
}

class AppRouteComponent extends Component<
    IAppRouteComponentProps & IAppRouteComponentStateProps & RouteComponentProps<any> & WithNamespaces,
    IAppRouteComponentState
> {
    static defaultProps = {
        component: null,
        redirectTo: null,
        accessRestriction: null,
        accessRestrictionRedirect: null,
        disableHeaderAndDrawer: false,
        disableNavigation: false
    };

    state = {
        isInitialized: false
    };

    componentDidMount() {
        const {auth, account, getAccount} = this.props;

        if (auth.isAuthenticated && !account.properties) {
            getAccount();
        }

        this.setState({isInitialized: true});
    }

    render() {
        const {
            redirectTo,
            component,
            accessRestriction,
            accessRestrictionRedirect,
            disableHeaderAndDrawer,
            disableNavigation,
            auth,
            account,
            classes,
            t,
            ...rest
        } = this.props;

        // tslint:disable-next-line no-shadowed-variable
        const ComponentToRender = component;

        return (
            <Route
                {...rest}
                render={(props) => {
                    if (accessRestriction === AccessRestriction.private && !auth.isAuthenticated) {
                        if (accessRestrictionRedirect) {
                            return <Redirect to={accessRestrictionRedirect} />;
                        }
                        return null;
                    }

                    if (accessRestriction === AccessRestriction.public && auth.isAuthenticated) {
                        if (accessRestrictionRedirect) {
                            return <Redirect to={accessRestrictionRedirect} />;
                        }
                        return null;
                    }

                    if (!this.state.isInitialized || (accessRestriction === AccessRestriction.private && account.isFetching)) {
                        return (
                            <Grid container spacing={0} direction="column" alignItems="center" justify="center" style={{minHeight: '100vh'}}>
                                <Grid item>
                                    <CircularProgress size={60} />
                                </Grid>
                            </Grid>
                        );
                    }

                    if (this.state.isInitialized && accessRestriction === AccessRestriction.private && !account.properties) {
                        return (
                            <Shell enableHeaderAndDrawer={!disableHeaderAndDrawer} disableNavigation={disableNavigation}>
                                <Grid container direction="row" spacing={2} className={classes.errorMessageContainer}>
                                    <Grid item xs={12}>
                                        <Typography align="center" className={classes.errorMessage}>
                                            {t('AppRoute.fetchAccountDataError.p1')}
                                        </Typography>
                                        <Typography align="center" className={classes.errorMessage}>
                                            {t('AppRoute.fetchAccountDataError.p2')}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Shell>
                        );
                    }

                    if (accessRestriction === AccessRestriction.private && auth.isAuthenticated && account.properties && !account.properties.emailVerified) {
                        return <Redirect to="/verify-email-address" />;
                    }

                    if (ComponentToRender) {
                        return (
                            <Shell enableHeaderAndDrawer={!disableHeaderAndDrawer} disableNavigation={disableNavigation}>
                                <ComponentToRender {...props} />
                            </Shell>
                        );
                    }

                    if (redirectTo) {
                        return <Redirect to={redirectTo} />;
                    }

                    return null;
                }}
            />
        );
    }
}

const mapStateToProps = ({auth, account}: RootState) => ({
    auth,
    account
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    getAccount: () => dispatch(getAccountAction())
});

const StyledAppRouteComponent = withStyles(styles)(AppRouteComponent);

const I18nAppRouteComponent = withNamespaces()(StyledAppRouteComponent);

export const AppRoute = withRouter(connect(mapStateToProps, mapDispatchToProps)(I18nAppRouteComponent));
