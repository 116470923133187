import {Languages} from '../../../i18n';
import sendTokens from './send-tokens.png';

export const sendTokensData = (lang: string) => {
    if (lang === Languages.DE) {
        return [
            [
                'Generell',
                'Im Rahmen der Anmeldung von Forderungen aus EVN-Tokens bzw. deren Erwerb sind Sie bzw. Ihr Mandant verpflichtet, die angemeldeten EVN-Tokens der Konkursverwaltung einzureichen. Zu diesem Zweck stellen wir Ihnen eine Ethereum-Einlieferadresse zur Verfügung, an welche Sie bzw. Ihr Mandant die angemeldeten Tokens senden müssen.'
            ],
            [
                'Ich habe MetaMask und Zugriff darauf.',
                `
                    <p>Jeder Investor, der im Rahmen des ICO mit Ether bezahlt hat, ist während des Verkaufsprozesses aufgefordert worden, in MetaMask zwecks Auslieferung der EVN-Tokens ein Konto einzurichten. Wenn Sie im Rahmen des ICO ein Konto in MetaMask eingerichtet haben, wählen Sie diese Option aus und folgen Sie den beschriebenen Schritten, um Ihre EVN-Tokens einzuliefern. Diese Option ist nur verfügbar, wenn Sie sich selbst vertreten.</p>
                    <p><b>Sie müssen ein kleines Ether-Guthaben an Ether auf Ihrem Konto haben, um die Transaktionsgebühren (GAS) bezahlen zu können.</b></p>
                `
            ],
            [
                'Ich habe mein MetaMask Passwort und / oder meine Phrase verloren.',
                'Wenn Sie sich nicht mehr in Ihr MetaMask-Konto einloggen können, wählen Sie bitte die dritte Option und erklären Sie, warum Sie keinen Zugang mehr haben.'
            ],
            [
                'Ich habe meine EVN-Tokens in einem anderen Wallet oder auf einer Börsenplattform.',
                'Falls Sie Ihre EVN-Tokens in einem anderen Wallet als MetaMask gespeichert haben oder diese derzeit an einer Börse deponiert sind, wählen Sie bitte diese Option und senden Sie Ihre EVN-Tokens an die angegebene Einlieferadresse.'
            ],
            [
                'Ich habe keine EVN-Tokens und / oder ich kann sie nicht einliefern.',
                'Wenn Sie die EVN-Tokens noch nie erhalten haben und/oder diese nicht einliefern können, notieren Sie sich bitte die Einlieferadresse und erklären Sie so genau wie möglich, warum Sie keine EVN-Tokens haben und/oder diese nicht einliefern können.'
            ],
            [
                'Der Mandant, den ich vertrete, hat EVN-Tokens einzuliefern.',
                'Wir haben eine Einlieferadressse für Ihren Mandanten generiert. Bitte seien Sie dafür besorgt, dass Ihr Mandant die EVN-Tokens an diese Adresse einliefert.'
            ],
            [
                'Der von mir vertretene Mandant hat keine EVN-Tokens und / oder ist nicht in der Lage, diese zu einzuliefern.',
                'Wenn Ihr Mandant die EVN-Tokens noch nie erhalten hat und/oder diese nicht einliefern kann, notieren Sie sich bitte die Einlieferadresse und erklären Sie so genau wie möglich, warum Ihr Mandant keine EVN-Tokens hat und/oder diese nicht einliefern kann.'
            ],
            [
                'Während der Übertragung der EVN-Tokens erhalte ich eine Fehlermeldung.',
                'Es ist möglich, dass ein Übertragungsversuch zu einer Fehlermeldung führt. Bitte stellen Sie sicher, dass Sie genügend Ether auf Ihrem Konto haben, um die Transaktionsgebühren (GAS) zu decken und eine Überweisung durchführen zu können.'
            ],
            [
                'Ich habe MetaMask und bin sicher, dass ich die richtige Ethereum-Adresse verwende, kann aber meine EVN-Tokens immer noch nicht sehen.',
                `
                    Führen Sie die folgenden Schritte durch:
                    <ol>
                        <li>Öffnen Sie MetaMask und loggen Sie sich ein,</li>
                        <li>Öffnen Sie das Menü (oben links),</li>
                        <li>Scrollen Sie nach unten und klicken Sie auf "Token hinzufügen",</li>
                        <li>
                            Klicken Sie auf "Custom Token" und füllen Sie das Formular mit den folgenden Informationen aus:
                            <ol>
                                <li>Token Contract Adresse → <b>0xd780ae2bf04cd96e577d3d014762f831d97129d0</b></li>
                                <li>Tokensymbol → <b>EVN</b></li>
                                <li>Dezimalangabe der Präzision → <b>18</b></li>
                            </ol>
                        </li>
                        <li>Klicken Sie auf "Weiter",</li>
                        <li>Klicken Sie auf  "Token hinzufügen".</li>
                    </ol>
                    Bitte beachten Sie:<br />
                    Sie müssen ein kleines Ether-Guthaben an Ether auf Ihrem Konto haben, um Transaktionsgebühren (GAS) bezahlen zu können.
                `
            ],
            [
                'MetaMask Plugin',
                `
                    <p>Um Ihre EVN-Tokens zu übertragen, unterstützen wir das MetaMask-Plugin, das für alle oben genannten Browser verfügbar ist. Dies ist notwendig für alle Antragsteller, die ihre EVN-Tokens bereits erhalten haben und diese in ihrem MetaMask-Wallet aufbewahren.</p>
                    <p>Sie können MetaMask auf dieser Website herunterladen: <a target="_blank" href="https://metamask.io/">https://metamask.io/</a></p>
                `
            ],
            [
                'Überprüfen Ihres Bestands an EVN-Tokens',
                `
                    Sofern Sie eine Ethereum-Adresse haben, die Sie während des envion ICOs genutzt oder nach dem KYC-Prozess erhalten haben, können Sie Ihren aktuellen Token-Status auf der folgenden Website überprüfen:<br />
                    Etherscan: <a href="https://etherscan.io" target="_blank">https://etherscan.io/</a>
                    <ol>
                        <li>Fügen Sie Ihre Ethereum-Adresse in die Suchleiste oben ein und drücken Sie Enter, und</li>
                        <li>
                            Überprüfen Sie die Dropdown-Liste der Token wie unten gezeigt, um Ihren Bestand an EVN-Tokens einzusehen.<br />
                            <br />
                            <img width="600" src="${sendTokens}" />
                        </li>
                    </ol>
                `
            ],
            [
                'Probleme beim einsenden der Tokens durch überschneidende Ethereum apps',
                'Es ist möglich, dass mehrere Chrome-Plugins Probleme mit MetaMask verursachen. Daher wäre es hilfreich, wenn Sie mögliche Web3-bezogene Plugins ausschalten würden, bevor Sie versuchen, die Token zu senden. Sie haben immer die Möglichkeit Option 2 zu wählen und eine direkte Überweisung an die von Ihnen angegebene Wallet-Adresse vorzunehmen.'
            ],
            [
                'Das Kommentarfeld ist nicht gross genug',
                'Wenn das Kommentarfeld nicht gross genug ist, um die erforderlichen Informationen einzugeben, laden Sie bitte ein separates Dokument im Abschnitt "Zusätzliche Dokumente" hoch und geben Sie die Information "siehe separates Dokument" im Kommentarfeld an.'
            ],
            [
                'Ich habe Probleme mit dem Portal, was kann ich von meiner Seite aus machen?',
                'Bitte stellen Sie sicher, dass Sie Ihren Browser-Cache geleert haben, bevor Sie das Portal nutzen.'
            ]
        ];
    }

    return [
        [
            'General',
            'All persons who register claims against envion on the portal from the purchase of EVN tokens and who have also received these tokens are obliged to transfer these tokens in their full extent to the bankruptcy administration. In order to do so, we will provide you with an Ethereum deposit address to which you or your client(s) must send the registered tokens.'
        ],
        [
            'I have my tokens in my MetaMask account and I have access to it',
            'Each person who participated in the ICO and paid with ether, was required to install MetaMask in order to receive EVN tokens. If you use MetaMask and have access to the account that you used during the envion ICO, choose this action and follow the steps described to deposit your EVN tokens. <b>Please note that you are required to have a small balance of Ether on your account in order to pay for transaction fees (GAS).</b>'
        ],
        [
            'I have lost my MetaMask password/seed phrase',
            'If you cannot login into your MetaMask account anymore, please choose the third option and explain that and why you no longer have access.'
        ],
        [
            'I have my tokens in a different wallet or exchange',
            'In case you have stored your EVN tokens in another wallet than MetaMask or have them currently deposited on an exchange, please select this option and we will provide you with a deposit address. Please send all of the EVN tokens to the provided deposit address.'
        ],
        [
            'I have no EVN tokens and / or I am not able to transfer them',
            'If you have never received your EVN tokens and/or are unable to transfer them, please explain as precisely as possible why you do not have EVN tokens and/or are unable to transfer them.'
        ],
        [
            'The client that I represent has EVN tokens to transfer',
            'We have generated the token deposit address for your client. Please make sure that your client transfers the EVN tokens to this address.'
        ],
        [
            'The client that I represent has no EVN tokens and / or is not able to transfer them',
            'If your client(s) have never received the EVN tokens and/or are unable to transfer them, please explain as precisely as possible why your claimant(s) do not have EVN tokens and/or are unable to transfer them.'
        ],
        [
            'During the token transfer I get an error message',
            'It is possible that a transfer attempt results in an error message. Please check that you have enough Ether in your account to cover the transaction fees (GAS) to be able to execute a transfer.'
        ],
        [
            'I have MetaMask and I am certain I have the correct Ethereum address but still cannot see my envion tokens',
            `
            Check the following steps:
            <ol>
                <li>Open MetaMask and  log in,</li>
                <li>Open Menu (top left),</li>
                <li>Scroll down and click “Add Token”,</li>
                <li>
                    Click “Custom Token” and fill out the form with the following information:
                    <ol>
                        <li>Token Contract Address → <b>0xd780ae2bf04cd96e577d3d014762f831d97129d0</b></li>
                        <li>Token Symbol → <b>EVN</b></li>
                        <li>Decimals of Precision → <b>18</b></li>
                    </ol>
                </li>
                <li>Click “Next”,</li>
                <li>Click “Add Token”.</li>
            </ol>
            Please note:<br />
            You are required to have a small balance of Ether in your account in order to pay for the transaction fees (GAS).
            `
        ],
        [
            'MetaMask plugin',
            'To enable you to transfer your EVN tokens, our portal supports the MetaMask plugin, which works with all browsers mentioned above. This transfer is required for all claimants who have received their EVN tokens and keep them in their MetaMask wallet.<br />You can download MetaMask at the following address: <a href="https://metamask.io" target="_blank">https://metamask.io/</a>'
        ],
        [
            'Checking your balance of EVN tokens',
            `
            If you have an Ethereum address that you provided in the frame of the ICO or received after the KYC process, you can check and review your current token status via the following website:<br />
            Etherscan: <a href="https://etherscan.io" target="_blank">https://etherscan.io/</a>
            <ol>
                <li>Add your Ethereum address into the search bar on top and press enter</li>
                <li>
                    Check the token drop-down as shown below to see your envion balance.<br />
                    <br />
                    <img width="600" src="${sendTokens}" />
                </li>
            </ol>
            `
        ],
        [
            'Problems with delivering tokens by overlapping Ethereum apps',
            'It is possible that multiple chrome plugins cause issues with MetaMask. Therefore it would help if you switch off any potential Web3 related plugins before trying to send the tokens. In any case if nothing works you can always choose option 2 and make a direct transfer to your designated wallet address.'
        ],
        [
            'The comment field is not wide enough',
            'If the comment field is not wide enough to enter the required information, please upload a separate document in the "Additional documents" section and indicate "see separate document" in the comment field.'
        ],
        ['I have issues with the portal, what can I do from my side?', 'Please insure you cleared your browser cache before using the portal.']
    ];
};
