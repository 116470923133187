import React, {Component} from 'react';
import {Helmet} from 'react-helmet';
import {Formik, Form, Field} from 'formik';
import * as Yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';
import config from '../../config';
import {Theme} from '@material-ui/core/styles/createMuiTheme';
import {withStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import {TextField} from '../../components/TextField';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import FormHelperText from '@material-ui/core/FormHelperText';
import {changePasswordRequest} from '../../libs/api/user/user';
import {withTranslation as withNamespaces, WithTranslation as WithNamespaces} from 'react-i18next';
import {Link} from '../../components/Link';

import CardMedia from '@material-ui/core/CardMedia';
import logo from '../../images/logo-cut.png';
import {getCurrentLanguage} from '../../i18n';

const styles = (theme: Theme) => ({
    media: {
        height: 80,
        backgroundSize: 'auto 50px',
        marginTop: 20
    },
    card: {
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: '500px',
        [theme.breakpoints.up('sm')]: {
            marginTop: '5vh'
        }
    },
    textField: {
        width: '100%'
    },
    recaptcha: {
        marginTop: theme.spacing(2)
    },
    cardActionLink: {
        display: 'block',
        marginTop: '7px',
        marginBottom: '8px'
    }
});

export interface IForgotPasswordComponentProps extends WithNamespaces {
    classes: {
        media: string;
        card: string;
        textField: string;
        recaptcha: string;
        cardActionLink: string;
    };
}

export interface IForgotPasswordComponentState {
    submitted: boolean;
    error: string | null;
}

interface IForgotPasswordFormValues {
    email: string;
    recaptcha: string;
}

class ForgotPasswordComponent extends Component<IForgotPasswordComponentProps, IForgotPasswordComponentState> {
    validationSchema = Yup.object().shape({
        email: Yup.string()
            .email(this.props.t('ForgotPasswordComponent.validation.mail').toString())
            .required(this.props.t('ForgotPasswordComponent.validation.mailRequired').toString()),
        recaptcha: Yup.string()
            .nullable()
            .required(this.props.t('ForgotPasswordComponent.validation.recaptcha').toString())
    });

    state = {
        submitted: false,
        error: null
    };

    submit = async (values: IForgotPasswordFormValues, {setSubmitting}: any) => {
        try {
            await changePasswordRequest(values, getCurrentLanguage(), values.recaptcha);

            setSubmitting(false);
            this.setState({
                submitted: true,
                error: null
            });
        } catch (error) {
            console.log('Error', error);
            setSubmitting(false);
            this.setState({
                submitted: false,
                error: this.props.t('ForgotPasswordComponent.error').toString()
            });
        }
    };

    render() {
        const {classes, t} = this.props;
        const {submitted} = this.state;
        return (
            <React.Fragment>
                <Helmet>
                    <title>{t('ForgotPasswordComponent.title')}</title>
                </Helmet>

                <Card className={classes.card}>
                    <CardMedia className={classes.media} image={logo} />
                    <CardHeader titleTypographyProps={{align: 'center'}} title={t('ForgotPasswordComponent.title')} />
                    {submitted ? (
                        <CardContent>
                            <Typography paragraph>{t('ForgotPasswordComponent.submitted1')}</Typography>
                            <Typography>{t('ForgotPasswordComponent.submitted2')}</Typography>
                        </CardContent>
                    ) : (
                        <Formik
                            onSubmit={async (values, {setSubmitting}) => {
                                await this.submit(values, {setSubmitting});
                            }}
                            initialValues={{email: '', password: '', recaptcha: ''}}
                            validationSchema={this.validationSchema}
                        >
                            {({handleSubmit, /*handleChange, handleBlur, values,*/ errors, touched, setFieldValue, isSubmitting}) => (
                                <Form onSubmit={handleSubmit}>
                                    <CardContent>
                                        <Typography>{t('ForgotPasswordComponent.info')}</Typography>
                                        <Field
                                            name="email"
                                            label={t('ForgotPasswordComponent.email')}
                                            type="email"
                                            className={classes.textField}
                                            margin="normal"
                                            component={TextField}
                                        />
                                        <div className={classes.recaptcha}>
                                            <ReCAPTCHA sitekey={config.APP_GOOGLE_RECAPTCHA_SITE_KEY} onChange={(value) => setFieldValue('recaptcha', value)} />
                                            {errors.recaptcha && touched.recaptcha && (
                                                <FormHelperText component="div" error>
                                                    {errors.recaptcha}
                                                </FormHelperText>
                                            )}
                                        </div>
                                    </CardContent>
                                    <CardActions>
                                        <Grid container direction="row-reverse" justify="space-between" alignItems="flex-end">
                                            <Grid item>
                                                <Button type="submit" variant="contained" color="primary" disabled={isSubmitting}>
                                                    {isSubmitting ? t('ForgotPasswordComponent.sending') : t('ForgotPasswordComponent.submit')}
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Link to="/" className={classes.cardActionLink}>
                                                    {t('Navigation.back')}
                                                </Link>
                                            </Grid>
                                        </Grid>
                                    </CardActions>
                                </Form>
                            )}
                        </Formik>
                    )}
                </Card>
            </React.Fragment>
        );
    }
}

const StyledForgotPassword = withStyles(styles)(ForgotPasswordComponent);
export const ForgotPassword = withNamespaces()(StyledForgotPassword);
