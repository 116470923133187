import {Languages} from '../../../i18n';

export const claimsData = (lang: string) => {
    if (lang === Languages.DE) {
        return [
            [
                'Wichtiger Hinweis',
                'Im Falle von Änderungen beachten Sie bitte, dass Sie ein neues Antragsformular generieren und neu unterzeichnet im PDF-Format hochladen müssen.'
            ],
            [
                'Wie melde ich eine Forderung an?',
                `
                    <p>Bitte füllen Sie alle Felder mit den erforderlichen Informationen aus. Stellen Sie sicher, dass Sie für jede Forderung eine neue Zeile anlegen. In der ersten Zeile ist die Forderung aus den Tokens bzw. aus deren Erwerb geltend zu machen ("Grundforderung"). Wenn Sie auch Zinsen geltend machen, fügen Sie diese bitte ebenfalls als Forderung auf einer separaten Zeile hinzu.</p>
                    <ul>
                        <li>
                            <b>Forderungsgrund</b><br />
                            In diesem Feld ist die Grundlage der angemeldeten Forderung dazulegen.<br />
                            Beispiel: Erwerb von 2'500 EVN-Tokens.
                        </li>
                        <li>
                            <b>Gekauft via</b><br />
                            Dieses Feld steht Ihnen nur zur Verfügung, wenn Sie im Abschnitt Tokenerwerb angegeben haben, dass Sie bzw. Ihr Mandant die EVN-Tokens aus verschiedenen Quellen erworben haben.
                        </li>
                        <li>
                            <b>Betrag</b><br />
                            Melden Sie den Anspruch aus den Tokens bzw. deren Erwerb, in der Währung an, in welcher diese erworben wurden und / oder in welcher der Anspruch daraus geltend gemacht wird (USD, EUR, BTC, ETH, etc.).
                        </li>
                        <li>
                            <b>Betrag in CHF</b><br />
                            Beträge in fremder Währung (FIAT / Kryptowährungen) sind per 14. November 2018 in Schweizer Franken umzurechnen. Bei der Umrechnung von FIAT-Währungen ist der Devisenmittelkurs in Anwendung zu bringen. Gemäss diesem entsprach USD 1.00 am 14. November 2018 dem Betrag von CHF 1.008764. Sodann entsprach EUR 1.00 am 14. November 2018 CHF 0.877578.
                        </li>
                        <li>
                            <b>Kommentare</b><br />
                            Geben Sie zusätzliche Informationen zur Beschreibung Ihres Anspruchs bzw. desjenigen Ihres Mandanten an.<br />
                            Beispiel: Erwerb von EVN-Tokens im Rahmen des ICO.
                        </li>
                    </ul>
                `
            ],
            [
                'Zinsansprüche müssen auf einer separaten Zeile hinzugefügt werden.',
                'In einer weiteren, der Grundforderung folgenden Zeile sollten die Zinsen, die Sie geltend machen möchten, als weitere Forderung hinzugefügt werden. Der Grund für die Verzinsung, der Beginn des Zinslaufs und der Zinssatz müssen im Feld "Kommentare" eingegeben werden. In jedem Fall können Zinsansprüche nur bis zum Zeitpunkt der Eröffnung des Konkursverfahrens am 14. November 2018 berücksichtigt werden.'
            ],
            [
                'Mehrfacher Erwerb von Token',
                'Wenn Sie bzw. Ihr Mandant mehrfach Tokens, d.h. zu verschiedenen Zeitpunkten, gekauft haben, bitten wir Sie, für jede einzelne Transaktion eine Grundforderung anzumelden. Wenn Sie Zinsen auf der jeweiligen Grundforderung anmelden möchten, ist dies auf der darauffolgenden Zeile zu tun (siehe oben).'
            ],
            [
                'Mein Weiter/Zurück Button hat sich grau verfärbt und ich kann den Prozess nicht fortsetzen',
                'Wenn Ihre Dateinamen oder Ihr eingegebener Text die vorgegebene Zeichenanzahl überschreitet kann es dazu kommen, dass sie den Prozess nicht fortführen können. In diesem Fall bitten wir Sie darum ihre Dateinamen sowie Texte zu kürzen und diese erneut im Portal hochzuladen.'
            ],
            [
                'Das Kommentarfeld ist nicht gross genug',
                'Wenn das Kommentarfeld nicht gross genug ist, um die erforderlichen Informationen einzugeben, laden Sie bitte ein separates Dokument im Abschnitt "Zusätzliche Dokumente" hoch und geben Sie die Information "siehe separates Dokument" im Kommentarfeld an.'
            ],
            [
                'Ich habe Probleme mit dem Portal, was kann ich von meiner Seite aus machen?',
                'Bitte stellen Sie sicher, dass Sie Ihren Browser-Cache geleert haben, bevor Sie das Portal nutzen.'
            ]
        ];
    }

    return [
        ['Important note', 'In case of amendments, please note that you need to generate another claim form and upload it newly signed (in pdf format).'],
        [
            'How do I register a claim?',
            `
                <p>Please fill out all fields with the required information. Ensure that you create a new line for each claim that you have. If you plan to claim interest as well, please also add this as a claim on a separate line.</p>
                <ul>
                    <li>
                        <b>Basis of claim</b><br />
                        This field must indicate the basis of the claim which is to be registered.<br />
                        Example: Purchase of 2'500 EVN tokens.
                    </li>
                    <li>
                        <b>Purchased via</b><br />
                        This field is only available if you have indicated in the token purchase section that you or your claimant(s) have purchased the tokens from multiple sources.
                    </li>
                    <li>
                        <b>Amount / Currency</b><br />
                        Register the claim that you or your client's asserts from the tokens or their acquisition in the currency in which he purchased them and/or in which he asserts the claim (USD, EUR, BTC, ETH, CHF, etc.).
                    </li>
                    <li>
                        <b>Amount in CHF</b><br />
                        Amounts in foreign currencies (FIAT / cryptocurrencies) are to be converted into Swiss francs as per 14 November 2018. FIAT currencies must be converted at the average exchange rate. At the prevailing rates on 14 November 2018, USD 1.00 was CHF 1.008764 and EUR 1.00 was CHF 1.1395.
                    </li>
                    <li>
                        <b>Comments</b><br />
                        Please provide further information on your or your client's claim here.<br />
                        Example: Purchase of EVN tokens within the framework of the ICO.
                    </li>
                </ul>
            `
        ],
        [
            'Interest claims need to be added on a separate line',
            'Any interest on the basic claim is to be claimed in a further line below. The reason for the interest, the start of the interest run and the interest rate must be entered in the "Comments" field. In any case, interest can only be paid up to the date of the bankruptcy opening on 14 November 2018.'
        ],
        [
            'Multiple purchase of tokens',
            'If you have purchased tokens several times, i.e. on different occasions, we ask you to register a basic claim for each individual transaction. If you wish to claim interest on the respective basic claim, you must do so in the line following the basic claim (see above).'
        ],
        [
            'My Next/Back button has turned gray and I cannot continue the process',
            'If your file name or text exceeds the specified number of characters, you may not be able to continue the process. In this case we ask you to reduce your file names and texts and upload them to the portal again.'
        ],
        [
            'The comment field is not wide enough',
            'If the comment field is not wide enough to enter the required information, please upload a separate document in the "Additional documents" section and indicate "see separate document" in the comment field.'
        ],
        ['I have issues with the portal, what can I do from my side?', 'Please insure you cleared your browser cache before using the portal.']
    ];
};
