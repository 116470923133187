import React, {Component} from 'react';
import {Dispatch} from 'redux';
import {connect} from 'react-redux';
import {withTranslation as withNamespaces, WithTranslation as WithNamespaces} from 'react-i18next';
import {Form, Formik, Field, FormikProps, FormikValues, FormikErrors} from 'formik';
import * as Yup from 'yup';
import {formikSubmitHandler, ConvertValidationErrorsFunction} from '../../../utils';
import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {Button, Typography, MenuItem, Checkbox, FormControlLabel} from '@material-ui/core';

import {styles, IClassesProperty} from '../styles';
import {RootState} from '../../../store';
import {IAuthState} from '../../../store/auth/auth.types';
import {TextField} from '../../../components/TextField';
import {Select} from '../../../components/Select';
import {authenticated as authenticatedAction} from '../../../store/auth/auth.actions';
import {Redirect} from 'react-router';
import {showSnackBar as showSnackBarAction} from '../../../store/snackbar/snackbar.actions';
import {ISnackBarState} from '../../../store/snackbar/snackbar.types';

import {COUNTRIES} from '../../../libs/countries';
import {setUserAsLegalRepresentative} from '../../../libs/api/user/user';
import {i18n, getCurrentLanguage, Languages} from '../../../i18n';

export interface ILegalRepresentativeFormProps extends WithNamespaces {
    classes: IClassesProperty;
    auth: IAuthState;
    showSnackBar(payload: ISnackBarState): void;
    authenticated(token: string, expiresAt: number, loadUserProperties?: boolean): void;
}

interface ILegalRepresentativeFormValues {
    honorificPrefix: string;
    honorificSuffix: string;
    givenName: string;
    familyName: string;
    company: string;
    additional: string;
    phoneNumber: string;
    preferredLanguage: string;
    address1: string;
    address2: string;
    postalCode: string;
    addressLocality: string;
    addressCountry: string;
}

class LegalRepresentativeFormComponent extends Component<ILegalRepresentativeFormProps> {
    _isMounted = false;

    state = {
        confirmedPowerOfAttorneyLetter: false,
        redirect: false,
        submitting: false
    };

    validationSchema = Yup.object().shape({
        honorificPrefix: Yup.string().required(`${this.props.t('LegalRepForm.validation.honorificPrefix')}.`),
        honorificSuffix: Yup.string(),
        givenName: Yup.string().required(`${this.props.t('LegalRepForm.validation.givenName')}.`),
        familyName: Yup.string().required(`${this.props.t('LegalRepForm.validation.familyName')}.`),
        company: Yup.string(),
        additional: Yup.string(),
        phoneNumber: Yup.string().required(`${this.props.t('LegalRepForm.validation.phoneNumber')}.`),
        preferredLanguage: Yup.string().required(`${this.props.t('LegalRepForm.validation.preferredLanguage')}.`),
        address1: Yup.string()
            .required(`${this.props.t('LegalRepForm.validation.address1')}.`)
            .max(40, `${this.props.t('LegalRepForm.validation.addressMax')}.`),
        address2: Yup.string().max(40, `${this.props.t('LegalRepForm.validation.addressMax')}.`),
        postalCode: Yup.string().required(`${this.props.t('LegalRepForm.validation.postalCode')}.`),
        addressLocality: Yup.string().required(`${this.props.t('LegalRepForm.validation.addressLocality')}.`),
        addressCountry: Yup.string().required(`${this.props.t('LegalRepForm.validation.addressCountry')}.`)
    });

    componentDidMount = () => {
        this._isMounted = true;
    };

    componentWillUnmount = () => {
        this._isMounted = false;
    };

    onSubmit = async (values: FormikValues) => {
        const {auth, authenticated} = this.props;

        if (this._isMounted) {
            this.setState({submitting: true});
        }

        const {token, expiresAt} = await setUserAsLegalRepresentative(values, auth.token);

        authenticated(token, expiresAt, true);

        if (this._isMounted) {
            this.setState({redirect: true, submitting: false});
        }
    };

    showError = (error: Error) => {
        this.props.showSnackBar({message: error.message, variant: 'error'});
    };

    stripFirstWord = (str: string) => {
        return str.substr(str.indexOf(' ') + 1);
    };

    convertValidationErrors: ConvertValidationErrorsFunction<FormikValues> = (validationErrors) => {
        return validationErrors.reduce<any>((result, validationError) => {
            const constrainKeys = Object.keys(validationError.constraints);

            if (constrainKeys.length > 1) {
                result[validationError.property] = (
                    <React.Fragment>
                        <ul style={{paddingLeft: 15, marginTop: 0}}>
                            {constrainKeys.map((constrainKey) => (
                                <li key={constrainKey}>{this.stripFirstWord(validationError.constraints[constrainKey])}.</li>
                            ))}
                        </ul>
                    </React.Fragment>
                );
            } else if (constrainKeys.length === 1) {
                result[validationError.property] = `${this.stripFirstWord(validationError.constraints[constrainKeys[0]])}.`;
            }

            return result;
        }, {});
    };

    validate = (values: ILegalRepresentativeFormValues) => {
        const errors: FormikErrors<ILegalRepresentativeFormValues> = {};

        if (getCurrentLanguage() !== values.preferredLanguage) {
            i18n.changeLanguage(values.preferredLanguage).catch(() => console.log('Failed to change language.'));
        }

        return errors;
    };

    clickPowerOfAttorneyLetters = (event: any) => {
        this.setState({confirmedPowerOfAttorneyLetter: event.target.checked});
    };

    renderForm = (props: FormikProps<ILegalRepresentativeFormValues>) => {
        const {classes, t} = this.props;
        const lang: 'en' | 'de' = getCurrentLanguage();

        return (
            <Form>
                <Grid container direction="column" spacing={2}>
                    <Grid container direction="row">
                        <Grid item className={classes.field}>
                            <Typography className={classes.subTitle} variant="h6">
                                {t('LegalRepForm.head1')}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row">
                        <Grid item md={6} xs={12} className={classes.field}>
                            <Field name="honorificPrefix" label={`${t('LegalRepForm.salutation')} *`} err={props.errors.honorificPrefix} component={Select}>
                                <MenuItem value="mr">{t('LegalRepForm.mr')}</MenuItem>
                                <MenuItem value="mrs">{t('LegalRepForm.mrs')}</MenuItem>
                            </Field>
                        </Grid>
                        <Grid item md={6} xs={12} className={classes.field}>
                            <Field name="honorificSuffix" label={t('LegalRepForm.honorificSuffix')} type="text" component={TextField} />
                        </Grid>
                    </Grid>
                    <Grid container direction="row">
                        <Grid item md={6} xs={12} className={classes.field}>
                            <Field name="givenName" label={`${t('LegalRepForm.givenName')} *`} type="text" component={TextField} />
                        </Grid>
                        <Grid item md={6} xs={12} className={classes.field}>
                            <Field name="familyName" label={`${t('LegalRepForm.familyName')} *`} type="text" component={TextField} />
                        </Grid>
                    </Grid>
                    <Grid container direction="row">
                        <Grid item md={6} xs={12} className={classes.field}>
                            <Field name="company" label={t('LegalRepForm.company')} type="text" component={TextField} />
                        </Grid>
                        <Grid item md={6} xs={12} className={classes.field}>
                            <Field name="additional" label={t('LegalRepForm.additional')} type="text" component={TextField} />
                        </Grid>
                    </Grid>
                    <Grid container direction="row">
                        <Grid item md={6} xs={12} className={classes.field}>
                            <Field name="phoneNumber" label={`${t('LegalRepForm.phoneNumber')} *`} type="text" component={TextField} />
                        </Grid>
                        <Grid item md={6} xs={12} className={classes.field}>
                            <Field
                                name="preferredLanguage"
                                label={`${t('LegalRepForm.preferredLanguage')} *`}
                                err={props.errors.preferredLanguage}
                                component={Select}
                            >
                                <MenuItem value={Languages.EN}>{t('language.en')}</MenuItem>
                                <MenuItem value={Languages.DE}>{t('language.de')}</MenuItem>
                            </Field>
                        </Grid>
                    </Grid>
                    <Grid container direction="row">
                        <Grid item className={classes.field}>
                            <Typography className={classes.subTitle} variant="h6">
                                {t('LegalRepForm.head2')}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row">
                        <Grid item md={8} xs={12} className={classes.field}>
                            <Field name="address1" label={`${t('LegalRepForm.address1')} *`} type="text" component={TextField} />
                        </Grid>
                    </Grid>
                    <Grid container direction="row">
                        <Grid item md={8} xs={12} className={classes.field}>
                            <Field name="address2" label={`${t('LegalRepForm.address2')}`} type="text" component={TextField} />
                        </Grid>
                    </Grid>
                    <Grid container direction="row">
                        <Grid item md={6} xs={12} className={classes.field}>
                            <Field name="postalCode" label={`${t('LegalRepForm.postalCode')} *`} type="text" component={TextField} />
                        </Grid>
                        <Grid item md={6} xs={12} className={classes.field}>
                            <Field name="addressLocality" label={`${t('LegalRepForm.addressLocality')} *`} type="text" component={TextField} />
                        </Grid>
                    </Grid>
                    <Grid container direction="row">
                        <Grid item md={6} xs={12} className={classes.field}>
                            <Field name="addressCountry" label={`${t('LegalRepForm.addressCountry')} *`} err={props.errors.addressCountry} component={Select}>
                                {Object.keys(COUNTRIES[lang]).map((key) => (
                                    <MenuItem key={key} value={key}>
                                        {COUNTRIES[lang][key]}
                                    </MenuItem>
                                ))}
                            </Field>
                        </Grid>
                    </Grid>
                    <Grid container direction="row">
                        <Grid item md={12} xs={12} className={classes.field}>
                            <FormControlLabel
                                control={<Checkbox checked={this.state.confirmedPowerOfAttorneyLetter} onChange={this.clickPowerOfAttorneyLetters} />}
                                label={t('LegalRepForm.confirmedPowerOfAttorneyLetter')}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container justify="flex-end">
                    <Grid item>
                        <Button
                            disabled={this.state.submitting || !this.state.confirmedPowerOfAttorneyLetter || !props.isValid}
                            type="submit"
                            color="primary"
                            variant="contained"
                        >
                            {t('Navigation.next')}
                        </Button>
                    </Grid>
                </Grid>
            </Form>
        );
    };

    render() {
        if (this.state.redirect) {
            return <Redirect to="/" />;
        }

        const initialValues = {
            honorificPrefix: '',
            honorificSuffix: '',
            givenName: '',
            familyName: '',
            company: '',
            additional: '',
            phoneNumber: '',
            preferredLanguage: '',
            address1: '',
            address2: '',
            postalCode: '',
            addressLocality: '',
            addressCountry: ''
        };

        return (
            <Formik
                initialValues={initialValues}
                validate={this.validate}
                validationSchema={this.validationSchema}
                onSubmit={formikSubmitHandler(this.onSubmit, {
                    convertValidationErrors: this.convertValidationErrors,
                    forbidden: this.showError,
                    badRequest: this.showError,
                    unknownError: this.showError
                })}
                enableReinitialize={true}
            >
                {this.renderForm}
            </Formik>
        );
    }
}

const StyledLegalRepresentativeFormComponent = withStyles(styles)(LegalRepresentativeFormComponent);
const I18nLegalRepresentativeFormComponent = withNamespaces()(StyledLegalRepresentativeFormComponent);

const mapStateToProps = ({auth}: RootState) => ({
    auth
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    authenticated: (token: string, expiresAt: number, loadUserProperties?: boolean) => dispatch(authenticatedAction(token, expiresAt, loadUserProperties)),
    showSnackBar: (payload: ISnackBarState) => dispatch(showSnackBarAction(payload))
});

export const LegalRepresentativeForm = connect(mapStateToProps, mapDispatchToProps)(I18nLegalRepresentativeFormComponent);
