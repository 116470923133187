export const getToken = (): string | null => {
    return localStorage.getItem('token');
};

export const calculateExpiresAtDate = (expiresIn: number) => {
    return expiresIn * 1000 + new Date().getTime();
};

export const getExpiresAtDate = (): number | null => {
    const expiresAt = localStorage.getItem('expires_at');
    if (expiresAt) {
        return parseInt(expiresAt, 10);
    }
    return null;
};

export const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('expires_at');
    localStorage.removeItem('user_profile');
};

export const isAuthenticated = () => {
    const expiresAt = getExpiresAtDate();

    if (expiresAt) {
        return new Date().getTime() < expiresAt;
    }

    return false;
};

export const setSession = (token: string, expiresAt: number) => {
    localStorage.setItem('token', token);
    localStorage.setItem('expires_at', expiresAt.toString());
};
