import {Theme} from '@material-ui/core/styles/createMuiTheme';
import {Property} from 'csstype';

export const styles = (theme: Theme) => ({
    card: {
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: '800px',
        marginBottom: '5vh'
    },
    cardHeader: {
        backgroundColor: theme.palette.primary.main
    },
    cardHeaderTitle: {
        color: theme.palette.primary.contrastText,
        fontSize: '1.7rem'
    },
    submitButton: {
        marginTop: theme.spacing(3)
    },
    subTitle: {
        marginTop: '20px'
    },
    field: {
        padding: theme.spacing(1.5)
    },
    dropZoneWrapper: {
        fontFamily: 'Roboto',
        backgroundColor: theme.palette.background.paper,
        border: '3px solid #F0F0F0',
        padding: 20,
        margin: 10,
        borderRadius: 5
    },
    dropZoneCentered: {
        marginBottom: 10,
        minHeight: 180
    },
    dropZoneParagraph: {
        fontFamily: 'Roboto'
    },
    warning: {
        color: theme.palette.error.dark,
        fontWeight: 'bold' as Property.FontWeight,
        marginBottom: theme.spacing(2)
    }
});

export interface IClassesProperty {
    warning: string;
    dropZoneWrapper: string;
    dropZoneCentered: string;
    dropZoneParagraph: string;
    card: string;
    cardHeader: string;
    cardHeaderTitle: string;
    submitButton: string;
    subTitle: string;
    field: string;
}
