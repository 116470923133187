import React from 'react';
import {withStyles, WithStyles, createStyles} from '@material-ui/core/styles';
import {Accordion, AccordionDetails, AccordionSummary} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// @TODO: improve type definitions

const styles = () =>
    createStyles({
        root: {
            width: '100%'
        },
        icon: {
            color: '#000000de',
            fontSize: '50px'
        },
        header: {
            backgroundColor: 'rgba(172, 179, 174, 0.38)'
        },
        content: {
            backgroundColor: '#F0F1F0',
            paddingTop: '20px',
            paddingBottom: '20px'
        },
        heading: {
            paddingRight: '0 !important',
            minWidth: '90%',
            fontWeight: 'bold',
            flexBasis: '33.33%',
            flexShrink: 0
        }
    });

export interface IControlledExpansionPanelsProps extends WithStyles<typeof styles> {
    payload: any;
}

class ControlledExpansionPanels extends React.Component<IControlledExpansionPanelsProps> {
    state = {
        expanded: null
    };

    handleChange = (panel: any) => (_event: any, expanded: boolean) => {
        this.setState({
            expanded: expanded ? panel : false
        });
    };

    getPanel(index: number, headline: string, content: string) {
        const {classes} = this.props;
        const {expanded} = this.state;

        return (
            <Accordion key={index} expanded={expanded === `panel${index}`} onChange={this.handleChange(`panel${index}`)}>
                <AccordionSummary className={classes.header} expandIcon={<ExpandMoreIcon className={classes.icon} />}>
                    <Typography className={classes.heading}>{headline}</Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.content}>
                    <Typography component="div">
                        {/* tslint:disable-next-line: react-no-dangerous-html */}
                        <div dangerouslySetInnerHTML={{__html: content}} />
                    </Typography>
                </AccordionDetails>
            </Accordion>
        );
    }

    getPanels() {
        const {payload} = this.props;

        return payload.map((panel: any, index: number) => {
            return this.getPanel(index, panel[0], panel[1]);
        });
    }

    render() {
        const {classes} = this.props;

        return <div className={classes.root}>{this.getPanels()}</div>;
    }
}

export default withStyles(styles)(ControlledExpansionPanels);
