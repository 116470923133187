import React from 'react';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {Button, Typography, MenuItem, withStyles, Grid, Card, CardHeader, CardContent} from '@material-ui/core';
import {Select} from '../../../components/Select';
import {Form, Formik, Field, FormikProps, FormikValues, FormikErrors} from 'formik';
import {formikSubmitHandler, ConvertValidationErrorsFunction} from '../../../utils';
import * as Yup from 'yup';
import {RootState} from '../../../store';
import {IAccountState, AppRoles} from '../../../store/account/account.types';
import {patchClaimant} from '../../../libs/api/user/user';
import {updateClaimant as updateClaimantAction, selectClaimantId as selectClaimantIdAction} from '../../../store/claimants/claimants.actions';
import {IClaimantsState, IClaimant, ClaimantStatus} from '../../../store/claimants/claimants.types';
import {IAuthState} from '../../../store/auth/auth.types';
import {styles, IClassesProperty} from '../styles';
import Helmet from 'react-helmet';
import {withTranslation as withNamespaces, WithTranslation as WithNamespaces} from 'react-i18next';
import {setFaq as setFaqAction} from '../../../store/faq/faq.actions';
import {IFaqState} from '../../../store/faq/faq.types';
import {showSnackBar as showSnackBarAction} from '../../../store/snackbar/snackbar.actions';
import {ISnackBarState} from '../../../store/snackbar/snackbar.types';
import getConfig from '../../../config';

interface IMeetingClaimantsFormValues {
    attendClaimantMeeting: boolean | string;
}

export interface IMeetingOfClaimantsProps extends WithNamespaces {
    classes: IClassesProperty;
    auth: IAuthState;
    account: IAccountState;
    claimants: IClaimantsState;
    selectClaimantId(claimantId: string | null): void;
    updateClaimant(claimant: IClaimant): void;
    setFaq(type: IFaqState): void;
    showSnackBar(payload: ISnackBarState): void;
}

class MeetingOfClaimantsComponent extends React.Component<IMeetingOfClaimantsProps> {
    _isMounted = false;

    state = {
        errors: true,
        submitting: false
    };

    validationSchema = Yup.object().shape({});

    componentDidMount() {
        this._isMounted = true;

        const initialValues = this.getInitialValuesFromClaimant();
        const {setFaq} = this.props;

        setFaq({type: ClaimantStatus.MEETING_OF_CLAIMANTS});

        if (initialValues.attendClaimantMeeting) {
            this.setState({errors: false});
        }
    }

    componentWillUnmount = () => {
        this._isMounted = false;
    };

    setSubmitting = (submitting = true) => {
        if (this._isMounted) {
            this.setState({submitting});
        }
    };

    getRole = () => {
        const {account, t} = this.props;

        if (!account.properties) {
            throw new Error(t('Roles.invalid').toString());
        }

        return account.properties.role;
    };

    showError = (error: string) => {
        this.props.showSnackBar({message: error, variant: 'error'});
    };

    handleBackClick = async (values: FormikValues) => {
        const {auth, updateClaimant, t} = this.props;

        try {
            const role = this.getRole();
            const claimant = this.getClaimant();

            let attendClaimantMeeting = null;
            switch (values.attendClaimantMeeting) {
                case 'true':
                    attendClaimantMeeting = true;
                    break;
                case 'false':
                    attendClaimantMeeting = false;
                    break;
                default:
                    attendClaimantMeeting = null;
            }

            if (!getConfig.APP_MEETING_OF_CLAIMANTS) {
                attendClaimantMeeting = false;
            }

            const _values = {
                attendClaimantMeeting,
                status: ClaimantStatus.ADDITIONAL_DOCUMENTS
            };

            this.setSubmitting();

            if (attendClaimantMeeting === null) {
                // Update claimant in DB
                if (role === AppRoles.CLAIMANT_CLAIMANT) {
                    const updatedClaimant = await patchClaimant(_values, 'step-only', null, auth.token);
                    updateClaimant(updatedClaimant);
                } else if (role === AppRoles.CLAIMANT_LEGAL_REPRESENTATIVE) {
                    const updatedClaimant = await patchClaimant(_values, 'step-only', claimant.id, auth.token);
                    updateClaimant(updatedClaimant);
                }
                return;
            }

            if (role === AppRoles.CLAIMANT_CLAIMANT) {
                const updatedClaimant = await patchClaimant(_values, 'meeting-of-claimant', null, auth.token);
                updateClaimant(updatedClaimant);
            } else if (role === AppRoles.CLAIMANT_LEGAL_REPRESENTATIVE) {
                const updatedClaimant = await patchClaimant(_values, 'meeting-of-claimant', claimant.id, auth.token);
                updateClaimant(updatedClaimant);
            }
            this.setSubmitting(false);
        } catch (error) {
            console.error(error);
            this.showError(t('Form.failed').toString());
        }
    };

    onSubmit = async (values: FormikValues) => {
        const {auth, updateClaimant, t} = this.props;

        try {
            const role = this.getRole();

            let attendClaimantMeeting = null;
            switch (values.attendClaimantMeeting) {
                case 'true':
                    attendClaimantMeeting = true;
                    break;
                case 'false':
                    attendClaimantMeeting = false;
                    break;
                default:
                    attendClaimantMeeting = null;
            }

            if (!getConfig.APP_MEETING_OF_CLAIMANTS) {
                attendClaimantMeeting = false;
            }

            const _values = {
                attendClaimantMeeting,
                status: ClaimantStatus.CLAIMS
            };

            const claimant = this.getClaimant();

            this.setSubmitting();

            if (role === AppRoles.CLAIMANT_CLAIMANT) {
                const updatedClaimant = await patchClaimant(_values, 'meeting-of-claimant', null, auth.token);
                updateClaimant(updatedClaimant);
            } else if (role === AppRoles.CLAIMANT_LEGAL_REPRESENTATIVE) {
                const updatedClaimant = await patchClaimant(_values, 'meeting-of-claimant', claimant.id, auth.token);
                updateClaimant(updatedClaimant);
            }
            this.setSubmitting(false);
        } catch (error) {
            console.error(error);
            this.showError(t('Form.failed').toString());
        }
    };

    getClaimant = () => {
        const {claimants} = this.props;
        return claimants.selectedClaimantId ? claimants.allClaimants[claimants.selectedClaimantId] : claimants.allClaimants[claimants.byId[0]];
    };

    getInitialValuesFromClaimant = () => {
        const claimant = this.getClaimant();

        switch (claimant.attendClaimantMeeting) {
            case true:
                return {attendClaimantMeeting: 'true'};
            case false:
                return {attendClaimantMeeting: 'false'};
            default:
                return {attendClaimantMeeting: ''};
        }
    };

    validate = (values: IMeetingClaimantsFormValues) => {
        const errors: FormikErrors<IMeetingClaimantsFormValues> = {};

        if (!getConfig.APP_MEETING_OF_CLAIMANTS) {
            return errors;
        }

        if (typeof values.attendClaimantMeeting === 'string') {
            if (values.attendClaimantMeeting.length === 0) {
                errors.attendClaimantMeeting = this.props.t('MeetingOfClaimantsStep.decision').toString();
                this.setState({errors: true});
            } else {
                this.setState({errors: false});
            }
        } else {
            this.setState({errors: false});
        }

        return errors;
    };

    stripFirstWord = (str: string) => {
        return str.substr(str.indexOf(' ') + 1);
    };

    convertValidationErrors: ConvertValidationErrorsFunction<FormikValues> = (validationErrors) => {
        return validationErrors.reduce<any>((result, validationError) => {
            const constrainKeys = Object.keys(validationError.constraints);

            if (constrainKeys.length > 1) {
                result[validationError.property] = (
                    <React.Fragment>
                        <ul style={{paddingLeft: 15, marginTop: 0}}>
                            {constrainKeys.map((constrainKey) => (
                                <li key={constrainKey}>{this.stripFirstWord(validationError.constraints[constrainKey])}.</li>
                            ))}
                        </ul>
                    </React.Fragment>
                );
            } else if (constrainKeys.length === 1) {
                result[validationError.property] = `${this.stripFirstWord(validationError.constraints[constrainKeys[0]])}.`;
            }

            return result;
        }, {});
    };

    renderForm = (props: FormikProps<IMeetingClaimantsFormValues>) => {
        const {classes, t} = this.props;

        return (
            <Form>
                <Grid container direction="column" spacing={2}>
                    <Grid container direction="row">
                        <Grid item className={classes.field}>
                            <Typography className={classes.subTitle} variant="h6">
                                {getConfig.APP_MEETING_OF_CLAIMANTS ? t('MeetingOfClaimantsStep.headline') : t('MeetingOfClaimantsStep.headlineDisabled')}
                            </Typography>
                        </Grid>
                    </Grid>
                    {getConfig.APP_MEETING_OF_CLAIMANTS ? (
                        <Grid item xs={6} className={classes.field}>
                            <Field
                                name="attendClaimantMeeting"
                                label={t('MeetingOfClaimantsStep.label')}
                                err={props.errors.attendClaimantMeeting}
                                component={Select}
                            >
                                <MenuItem value="true">{t('MeetingOfClaimantsStep.yes')}</MenuItem>
                                <MenuItem value="false">{t('MeetingOfClaimantsStep.no')}</MenuItem>
                            </Field>
                        </Grid>
                    ) : null}
                </Grid>

                <Grid container justify="flex-end" className={classes.marginControls}>
                    <Grid container justify="space-between">
                        <Grid item>
                            <Button
                                disabled={this.state.submitting}
                                onClick={async () => this.handleBackClick(props.values)}
                                type="button"
                                color="default"
                                variant="contained"
                            >
                                {t('Navigation.back')}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                disabled={getConfig.APP_MEETING_OF_CLAIMANTS && (this.state.submitting || this.state.errors)}
                                type="submit"
                                color="primary"
                                variant="contained"
                            >
                                {t('Navigation.next')}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Form>
        );
    };

    render() {
        const {classes, t} = this.props;
        const initialValues = this.getInitialValuesFromClaimant();

        return (
            <React.Fragment>
                <Helmet>
                    <title>{t('MeetingOfClaimantsStep.title')}</title>
                </Helmet>

                <Card className={classes.card}>
                    <CardHeader
                        classes={{
                            root: classes.cardHeader,
                            title: classes.cardHeaderTitle
                        }}
                        title={t('MeetingOfClaimantsStep.title')}
                        style={{textAlign: 'center'}}
                    />

                    <CardContent>
                        <Formik
                            initialValues={initialValues}
                            validate={this.validate}
                            validationSchema={this.validationSchema}
                            onSubmit={formikSubmitHandler(this.onSubmit, {convertValidationErrors: this.convertValidationErrors})}
                            enableReinitialize={true}
                        >
                            {this.renderForm}
                        </Formik>
                    </CardContent>
                </Card>
            </React.Fragment>
        );
    }
}

const StyledMeetingOfClaimantsComponent = withStyles(styles)(MeetingOfClaimantsComponent);
const I18nMeetingOfClaimantsComponent = withNamespaces()(StyledMeetingOfClaimantsComponent);

const mapStateToProps = ({auth, account, claimants}: RootState) => ({
    auth,
    account,
    claimants
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    updateClaimant: (claimant: IClaimant) => dispatch(updateClaimantAction(claimant)),
    selectClaimantId: (claimantId: string) => dispatch(selectClaimantIdAction(claimantId)),
    setFaq: (type: IFaqState) => dispatch(setFaqAction(type)),
    showSnackBar: (payload: ISnackBarState) => dispatch(showSnackBarAction(payload))
});

export const MeetingOfClaimantsStep = connect(mapStateToProps, mapDispatchToProps)(I18nMeetingOfClaimantsComponent);
