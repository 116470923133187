import {Languages} from '../../../i18n';

export const reviewData = (lang: string) => {
    if (lang === Languages.DE) {
        return [
            [
                'Ich bin ein Gläubigervertreter und habe das Anmeldeverfahren für meine(n) Mandanten durchlaufen, wie gestaltet sich das weitere Vorgehen?',
                'Als Gläubigervertreter sehen Sie die für Ihre(n) Mandanten zugeteilte(n) Einlieferadressen. Bitte stellen Sie sicher, dass Ihr(e) Mandanten die EVN-Tokens auf diese Adresse einliefern.'
            ],
            ['Ich habe den Prozess zu schnell abgeschlossen, kann ich noch etwas ändern / korrigieren?', 'Bitte kontaktieren Sie unseren Support per E-Mail.'],
            [
                'Ich habe Probleme mit dem Portal, was kann ich von meiner Seite aus machen?',
                'Bitte stellen Sie sicher, dass Sie Ihren Browser-Cache geleert haben, bevor Sie das Portal nutzen.'
            ]
        ];
    }

    return [
        [
            'I am a legal representative and I have completed the process for my client(s). What is the further procedure?',
            'As a legal representative you can see the deposit address that was assigned to your client(s). Please make sure that your client(s) transfer the EVN tokens to this address.'
        ],
        ['I have completed the process too quickly, can I still change / correct something?', 'Please contact our support via e-mail.'],
        ['I have issues with the portal, what can I do from my side?', 'Please insure you cleared your browser cache before using the portal.']
    ];
};
