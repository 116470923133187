import React, {FunctionComponent} from 'react';
import {NavLink} from 'react-router-dom';
import {withStyles} from '@material-ui/core/styles';
import {Theme} from '@material-ui/core/styles/createMuiTheme';
import MuiDrawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import {DRAWER_WIDTH} from '../shell-constants';

const HomeLink = React.forwardRef((props, ref) => <NavLink to="/" exact={false} ref={ref as any} {...props} />);

const styles = (theme: Theme) => ({
    drawer: {
        width: DRAWER_WIDTH,
        flexShrink: 0
    },
    drawerPaper: {
        width: DRAWER_WIDTH
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: '0 8px',
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end'
    }
});

export interface IDrawerComponentProps {
    classes: {
        drawer: string;
        drawerPaper: string;
        drawerHeader: string;
    };
    theme: Theme;
    open: boolean;
    handleDrawerClose(): void;
}

const DrawerComponent: FunctionComponent<IDrawerComponentProps> = (props) => {
    const {classes, theme, open, handleDrawerClose} = props;

    return (
        <MuiDrawer
            className={classes.drawer}
            variant="persistent"
            anchor="left"
            open={open}
            classes={{
                paper: classes.drawerPaper
            }}
        >
            <div className={classes.drawerHeader}>
                <IconButton onClick={handleDrawerClose}>{theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}</IconButton>
            </div>
            <Divider />
            <List>
                <ListItem button key={'Home'} component={HomeLink}>
                    <ListItemText primary={'Claimants overview'} />
                </ListItem>
            </List>
        </MuiDrawer>
    );
};

export const Drawer = withStyles(styles, {withTheme: true})(DrawerComponent);
