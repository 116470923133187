export enum ClaimantsActionTypes {
    SELECT_CLAIMANT = '@@claimants/SELECT_CLAIMANT',
    UNSELECT_CLAIMANT = '@@claimants/UNSELECT_CLAIMANT',
    ADD_CLAIMANT = '@@claimants/ADD_CLAIMANT',
    UPDATE_CLAIMANT = '@@claimants/UPDATE_CLAIMANT'
}

export interface IFile {
    id?: string;
    fileNameOriginal: string;
    fileNameHashed: string;
    mimeType: string;
}

export interface IClaimantTransactionDto {
    id?: string;
    from: string;
    to: string;
    txHash: string | null;
    quantity: string;
}

export interface IAuditTrail {
    id: string;
    description: string;
    action: string;
    agent: string;
    category: string;
    createdAt: string;
    updatedAt: string;
}

export interface IClaimantGeneratedWallet {
    id: string;
    claimantId: string;
    ethereumAddress: string;
}

export interface IClaimant {
    userId: string;
    eId: number;
    id: string;
    auditTrail: IAuditTrail[];
    status: ClaimantStatus;
    legalRepresentativeUserId: string;
    createdAt: string;
    updatedAt: string;
    exportedAt: string;
    locked: boolean;
    givenName: string;
    familyName: string;
    honorificPrefix: string;
    honorificSuffix: string;
    lockCriticalProperties: boolean;
    hideSendTokenStep: boolean;
    company: string;
    additional: string;
    phoneNumber: string;
    email: string;
    preferredLanguage: string;
    address1: string;
    address2: string;
    postalCode: string;
    addressLocality: string;
    addressCountry: string;
    claimantWalletAddress: string;
    depositWalletAddress: string;
    accountHolderName: string;
    iban: string;
    bic: string;
    attendClaimantMeeting: boolean;
    otherTokenPurchase: string;
    reason: string;
    sendTokensChoice: 'metamask' | 'different' | 'noTokens';
    tokenPurchaseLocation: 'ico' | 'secondaryMarket' | 'privatePerson' | 'presaleOrPrivatePlacement' | 'founders' | 'multi' | 'other';
    signedClaimsDocument: IFile;
    passport: IFile[];
    purchaseDocuments: IFile[];
    powerOfAttorneyLetter: IFile[];
    additionalDocuments: IFile[];
    generatedWallets: IClaimantGeneratedWallet[];
    transactions: IClaimantTransactionDto[];
}

export enum ClaimantStatus {
    PERSONAL_INFORMATION = 'PERSONAL_INFORMATION',
    POWER_OF_ATTORNEY = 'POWER_OF_ATTORNEY',
    PASSPORT = 'PASSPORT',
    ADDITIONAL_DOCUMENTS = 'ADDITIONAL_DOCUMENTS',
    MEETING_OF_CLAIMANTS = 'MEETING_OF_CLAIMANTS',
    CLAIMS = 'CLAIMS',
    CHECK_ACCESS_TO_TOKENS = 'CHECK_ACCESS_TO_TOKENS',
    SIGN_CLAIMS_FORM = 'SIGN_CLAIMS_FORM',
    TOKEN_PURCHASE = 'TOKEN_PURCHASE',
    SEND_TOKENS = 'SEND_TOKENS',
    REVIEW = 'REVIEW',
    COMPLETE = 'COMPLETE',
    INCOMPLETE = 'INCOMPLETE'
}

// tslint:disable-next-line: interface-over-type-literal
export type AllClaimants = {[id: string]: IClaimant};

export interface IClaimantsState {
    isFetching: boolean;
    allClaimants: AllClaimants;
    byId: string[];
    selectedClaimantId: string | null;
}
