import React, {FunctionComponent} from 'react';
import {Link as RouterLink, LinkProps as RouterLinkProps} from 'react-router-dom';
import MUIButton, {ButtonProps as MUIButtonProps} from '@material-ui/core/Button';

export interface ILinkButtonProps {
    children: React.ReactNode;
}

export const LinkButton: FunctionComponent<ILinkButtonProps & MUIButtonProps & RouterLinkProps> = ({to, children, ...rest}) => {
    return (
        <MUIButton
            component={React.forwardRef<HTMLAnchorElement, Partial<RouterLinkProps>>((props, ref) => (
                <RouterLink to={to} {...props} ref={ref as (node: HTMLAnchorElement | null) => void} />
            ))}
            {...rest}
        >
            {children}
        </MUIButton>
    );
};
