import countries from 'i18n-iso-countries';
import * as en from 'i18n-iso-countries/langs/en.json';
import * as de from 'i18n-iso-countries/langs/de.json';

const EN = en as any;
const DE = de as any;

countries.registerLocale(EN.default);
countries.registerLocale(DE.default);

interface ICountries {
    en: any;
    de: any;
}

export const COUNTRIES: ICountries = {
    en: countries.getNames('en'),
    de: countries.getNames('de')
};
