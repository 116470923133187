import {takeEvery, all, put, call, select} from 'redux-saga/effects';
import {verifyEmailAddress, verifyEmailAddressRequest, verifyEmailAddressResponse, verifyEmailAddressError} from '../account.actions';
import {AccountActionTypes} from '../account.types';
import {logout} from '../../auth/auth.actions';
import {RootState} from '../../';
import {userApi} from '../../../libs/api';

export const getAccessToken = (state: RootState) => state.auth.token;

export function* verifyEmailAddressSaga() {
    // tslint:disable-next-line: no-unnecessary-type-annotation
    yield takeEvery(AccountActionTypes.VERIFY_EMAIL, function*(action: ReturnType<typeof verifyEmailAddress>) {
        const {id} = action.payload;

        try {
            yield put(verifyEmailAddressRequest(id));

            const accessToken = yield select(getAccessToken);

            const response = yield call(userApi.verifyEmailAddress, accessToken, id);

            yield put(verifyEmailAddressResponse(id, response.data));
        } catch (error) {
            if (error.response && error.response.status === 401) {
                return yield all([put(logout()), put(verifyEmailAddressError(id, error))]);
            }
            return yield put(verifyEmailAddressError(id, error));
        }
    });
}
