import {Languages} from '../../../i18n';

export const additionalDocumentsData = (lang: string) => {
    if (lang === Languages.DE) {
        return [
            [
                'Was ist mit zusätzlichen Dokumenten gemeint?',
                'Falls Sie zusätzliche Dokumente wie z.B. Erwerbsbelege, Kreditkarten- oder Kontoauszüge, Gesetzesauszüge oder andere Dokumente besitzen, von denen Sie glauben, dass sie Ihren Anspruch bzw. den Ihres Mandanten belegen können, laden Sie diese bitte im Abschnitt “Zusätzliche Dokumente” hoch.'
            ],
            [
                'Welches Format wird unterstützt?',
                'Bitte laden Sie Ihr Dokument als PNG oder in Form eines JPG oder PDF mit einer maximalen Größe von 3MB hoch.'
            ],
            [
                'Ich habe versehentlich das falsche Dokument hochgeladen, was soll ich tun?',
                'Nachdem Sie Ihr Dokument hochgeladen haben, sehen Sie daneben ein "Mülleimer"-Symbol. Drücken Sie dieses und das Dokument wird gelöscht. Wenn Sie bereits auf "Weiter" geklickt haben, können Sie mit der Schaltfläche "Zurück" zu der Seite zurückkehren, auf der Sie ein bestimmtes Dokument ersetzen/löschen möchten. Das Dokument wird in der oberen Zeile angezeigt. Klicken Sie auf das Dokument, um die Dokumentenvorschau zu öffnen. Anschließend können Sie das Dokument löschen, indem Sie auf die Schaltfläche "Löschen", unten rechts klicken.'
            ],
            ['Wie viele Dokumente kann ich hochladen?', 'Sie können hier maximal 10 Dokumente hochladen.'],
            [
                'Mein Weiter/Zurück Button hat sich grau verfärbt und ich kann den Prozess nicht fortsetzen',
                'Wenn Ihre Dateinamen oder Ihr eingegebener Text die vorgegebene Zeichenanzahl überschreitet kann es dazu kommen, dass sie den Prozess nicht fortführen können. In diesem Fall bitten wir Sie darum ihre Dateinamen sowie Texte zu kürzen und diese erneut im Portal hochzuladen.'
            ],
            [
                'Ich habe Probleme mit dem Portal, was kann ich von meiner Seite aus machen?',
                'Bitte stellen Sie sicher, dass Sie Ihren Browser-Cache geleert haben, bevor Sie das Portal nutzen.'
            ]
        ];
    }

    return [
        [
            'What do you mean by additional documents?',
            'If you have additional documents such as purchase receipts, credit card or bank statements, legal statements or other documents that you believe can support your claim or that of your client(s), please upload them to the "Additional documents" section.'
        ],
        ['Which format will be supported?', 'Please upload your document in the form of either PNG, JPG or PDF with with a maximum size of 3MB.'],
        [
            'I have accidentally uploaded the wrong document, what should I do?',
            'After you have uploaded your document you will see a “bin” icon next to it, press that and the document will be deleted. If you have already clicked “next”, you can use the “back” button to go back to the page where you would like to replace/delete a particular document. The document will appear in the upper line. Click it to open the document viewer, and you can then delete the document by pressing the “delete” button on the bottom right.'
        ],
        ['How many documents can I upload?', 'You can upload a maximum of 10 documents here.'],
        [
            'My Next/Back button has turned gray and I cannot continue the process',
            'If your file name or text exceeds the specified number of characters, you may not be able to continue the process. In this case we ask you to reduce your file names and texts and upload them to the portal again.'
        ],
        ['I have issues with the portal, what can I do from my side?', 'Please insure you cleared your browser cache before using the portal.']
    ];
};
