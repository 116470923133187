import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {Snackbar, SnackbarContent, withStyles, IconButton} from '@material-ui/core';
import classNames from 'classnames';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import CloseIcon from '@material-ui/icons/Close';
import {Theme} from '@material-ui/core/styles/createMuiTheme';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import {RootState} from '../../store';
import {ISnackBarState} from '../../store/snackbar/snackbar.types';
import {hideSnackBar as hideSnackBarAction} from '../../store/snackbar/snackbar.actions';

const styles = (theme: Theme) => ({
    success: {
        backgroundColor: green[600]
    },
    error: {
        backgroundColor: theme.palette.error.dark
    },
    info: {
        backgroundColor: theme.palette.primary.dark
    },
    warning: {
        backgroundColor: amber[700]
    },
    icon: {
        fontSize: 20
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1)
    },
    message: {
        display: 'flex',
        alignItems: 'center'
    }
});

export interface ISnackBarProps {
    classes: {
        success: string;
        error: string;
        info: string;
        warning: string;
        icon: string;
        iconVariant: string;
        message: string;
    };
    snack: ISnackBarState;
    hideSnackBar(): void;
}

const variantIcon: any = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon
};

class SnackBarComponent extends Component<ISnackBarProps> {
    handleClose = () => {
        this.props.hideSnackBar();
    };

    renderSnackBarContent = () => {
        const {classes, snack} = this.props;

        if (!snack || !snack.variant) {
            return <></>;
        }

        const Icon = variantIcon[snack.variant];

        return (
            <SnackbarContent
                className={classes[snack.variant]}
                aria-describedby="client-snackbar"
                message={
                    <span id="client-snackbar" className={classes.message}>
                        <Icon className={classNames(classes.icon, classes.iconVariant)} />
                        {snack.message}
                    </span>
                }
                action={[
                    <IconButton key="close" aria-label="Close" color="inherit" onClick={this.handleClose}>
                        <CloseIcon className={classes.icon} />
                    </IconButton>
                ]}
            />
        );
    };

    render = () => {
        const {snack} = this.props;

        if (!snack || !snack.variant) {
            return null;
        }

        return (
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                open={true}
                autoHideDuration={6000}
                onClose={this.handleClose}
            >
                {this.renderSnackBarContent()}
            </Snackbar>
        );
    };
}

const mapStateToProps = (state: RootState) => ({
    snack: state.snack
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    hideSnackBar: () => dispatch(hideSnackBarAction())
});

const StyledSnackBarComponent = withStyles(styles, {withTheme: true})(SnackBarComponent);

// tslint:disable-next-line: prettier
export const SnackBar = connect(mapStateToProps, mapDispatchToProps)(StyledSnackBarComponent);
