import React, {Component} from 'react';
import {withTranslation as withNamespaces, WithTranslation as WithNamespaces} from 'react-i18next';
import {withStyles, Button, Grid} from '@material-ui/core';
import {connect} from 'react-redux';
import {RootState} from '../../../store';
import {IAuthState} from '../../../store/auth/auth.types';
import {Dispatch} from 'redux';
import {authenticated as authenticatedAction} from '../../../store/auth/auth.actions';
import {Redirect} from 'react-router';
import {setUserAsClaimant} from '../../../libs/api/user/user';

const styles = () => ({});

export interface INonLegalRepresentativeFormProps extends WithNamespaces {
    auth: IAuthState;
    authenticated(token: string, expiresAt: number, loadUserProperties?: boolean): void;
}

class NonLegalRepresentativeFormComponent extends Component<INonLegalRepresentativeFormProps> {
    _isMounted = false;

    state = {
        redirect: false,
        submitting: false
    };

    componentDidMount = () => {
        this._isMounted = true;
    };

    componentWillUnmount = () => {
        this._isMounted = false;
    };

    submitClicked = async () => {
        const {auth, authenticated} = this.props;

        if (this._isMounted) {
            this.setState({submitting: true});
        }

        const {token, expiresAt} = await setUserAsClaimant(auth.token);

        authenticated(token, expiresAt, true);

        if (this._isMounted) {
            this.setState({redirect: true, submitting: false});
        }
    };

    render() {
        const {t} = this.props;

        if (this.state.redirect) {
            return <Redirect to="/" />;
        }

        return (
            <Grid container justify="flex-end">
                <Grid item>
                    <Button disabled={this.state.submitting} onClick={this.submitClicked} variant="contained" color="primary">
                        {t('Navigation.next')}
                    </Button>
                </Grid>
            </Grid>
        );
    }
}

const StyledNonLegalRepresentativeFormComponent = withStyles(styles)(NonLegalRepresentativeFormComponent);
const I18nNonLegalRepresentativeFormComponent = withNamespaces()(StyledNonLegalRepresentativeFormComponent);

const mapStateToProps = ({auth}: RootState) => ({
    auth
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    authenticated: (token: string, expiresAt: number, loadUserProperties?: boolean) => dispatch(authenticatedAction(token, expiresAt, loadUserProperties))
});

export const NonLegalRepresentativeForm = connect(mapStateToProps, mapDispatchToProps)(I18nNonLegalRepresentativeFormComponent);
