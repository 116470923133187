// tslint:disable: react-no-dangerous-html

import React from 'react';
import {Dispatch} from 'redux';
import {connect} from 'react-redux';
import {withStyles, Card, CardHeader, CardContent, CardActions, Grid} from '@material-ui/core';
import {RootState} from '../../../store';
import {IAccountState, AppRoles} from '../../../store/account/account.types';
import {IAuthState} from '../../../store/auth/auth.types';
import {styles, IClassesProperty} from '../styles';
import Helmet from 'react-helmet';
import {withTranslation as withNamespaces, WithTranslation as WithNamespaces} from 'react-i18next';
import {setFaq as setFaqAction} from '../../../store/faq/faq.actions';
import {IFaqState} from '../../../store/faq/faq.types';
import {LockIcon} from '../../../components/LockIcon';
import {ClaimantInfo} from '../../../components/ClaimantInfo';
import {IClaimantsState} from '../../../store/claimants/claimants.types';
import {LinkButton} from '../../../components/LinkButton';

export interface ILockedClaimantProps extends WithNamespaces {
    claimants: IClaimantsState;
    classes: IClassesProperty;
    auth: IAuthState;
    account: IAccountState;
    setFaq(type: IFaqState): void;
}

class LockedClaimantComponent extends React.Component<ILockedClaimantProps> {
    componentDidMount() {
        this.props.setFaq({type: null});
    }

    getClaimant = () => {
        const {claimants} = this.props;
        return claimants.selectedClaimantId ? claimants.allClaimants[claimants.selectedClaimantId] : claimants.allClaimants[claimants.byId[0]];
    };

    getRole = () => {
        const {account, t} = this.props;

        if (!account.properties) {
            throw new Error(t('Roles.invalid').toString());
        }

        return account.properties.role;
    };

    getBody = () => {
        const {t} = this.props;
        return (
            <span
                dangerouslySetInnerHTML={{
                    __html: t('LockedClaimant.info', {
                        mail: '<a target="_blank" href="mailto:envion.support@wenger-plattner.ch">envion.support@wenger-plattner.ch</a>',
                        interpolation: {escapeValue: false}
                    })
                }}
            />
        );
    };

    render() {
        const {classes, t, auth} = this.props;
        const claimant = this.getClaimant();
        const role = this.getRole();

        const _title = (
            <>
                <span style={{verticalAlign: 'middle'}}>
                    <LockIcon locked />
                </span>
                <span style={{marginLeft: 10, marginRight: 10}}>{t('LockedClaimant.title')}</span>
            </>
        );

        return (
            <React.Fragment>
                <Helmet>
                    <title>{t('LockedClaimant.title')}</title>
                </Helmet>

                <Card className={classes.card}>
                    <CardHeader
                        classes={{
                            root: classes.cardHeader,
                            title: classes.cardHeaderTitle
                        }}
                        title={_title}
                        style={{textAlign: 'center'}}
                    />

                    <CardContent>
                        <ClaimantInfo claimant={claimant} auth={auth} role={role} body={this.getBody()} />
                    </CardContent>

                    {role === AppRoles.CLAIMANT_LEGAL_REPRESENTATIVE ? (
                        <CardActions>
                            <Grid direction="row-reverse" container justify="space-between" alignItems="flex-end">
                                <Grid item>
                                    <LinkButton to="/" color="primary" variant="contained">
                                        {t('CompleteStep.back')}
                                    </LinkButton>
                                </Grid>
                            </Grid>
                        </CardActions>
                    ) : null}
                </Card>
            </React.Fragment>
        );
    }
}

const StyledLockedClaimantComponent = withStyles(styles)(LockedClaimantComponent);
const I18nLockedClaimantComponent = withNamespaces()(StyledLockedClaimantComponent);

const mapStateToProps = ({auth, account, claimants}: RootState) => ({
    claimants,
    auth,
    account
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    setFaq: (type: IFaqState) => dispatch(setFaqAction(type))
});

export const LockedClaimant = connect(mapStateToProps, mapDispatchToProps)(I18nLockedClaimantComponent);
