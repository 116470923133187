import React, {Component} from 'react';
import {withStyles, Grid} from '@material-ui/core';
import {PasswordChange} from './PasswordChange';
import {PersonalInformation} from './PersonalInformation';
import {RootState} from '../../store';
import {connect} from 'react-redux';
import {IAuthState} from '../../store/auth/auth.types';
import {setFaq as setFaqAction} from '../../store/faq/faq.actions';
import {IFaqState} from '../../store/faq/faq.types';
import {Dispatch} from 'redux';
import getConfig from '../../config';
import {IAccountState, AppRoles} from '../../store/account/account.types';
import {withTranslation as withNamespaces, WithTranslation as WithNamespaces} from 'react-i18next';

const styles = () => ({
    container: {
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: '800px'
    }
});

export interface IAccountProps extends WithNamespaces {
    classes: {
        container: string;
    };
    auth: IAuthState;
    account: IAccountState;
    setFaq(type: IFaqState): void;
}

class AccountComponent extends Component<IAccountProps> {
    componentDidMount() {
        const {setFaq} = this.props;

        setFaq({type: null});
    }

    getRole = () => {
        const {account, t} = this.props;

        if (!account.properties) {
            throw new Error(t('Roles.invalid').toString());
        }

        return account.properties.role;
    };

    render() {
        const {classes} = this.props;

        return (
            <React.Fragment>
                <Grid container className={classes.container} direction="column" spacing={2}>
                    <PersonalInformation />

                    {!getConfig.APP_READONLY_MODE ||
                    (this.getRole() !== AppRoles.CLAIMANT_UNDECIDED && this.getRole() !== AppRoles.CLAIMANT_LEGAL_REPRESENTATIVE_INCOMPLETE) ? (
                        <PasswordChange />
                    ) : null}
                </Grid>
            </React.Fragment>
        );
    }
}

const mapStateToProps = ({auth, account}: RootState) => ({
    auth,
    account
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
    setFaq: (type: IFaqState) => dispatch(setFaqAction(type))
});

export const StyledAccountComponent = withStyles(styles)(AccountComponent);
const I18nAccountComponent = withNamespaces()(StyledAccountComponent);

export const Account = connect(mapStateToProps, mapDispatchToProps)(I18nAccountComponent);
