import React, {Component} from 'react';
import {Dialog, DialogTitle, DialogActions, DialogContent, Button, Typography, Grid} from '@material-ui/core';
import Web3 from 'web3';
import {withTranslation as withNamespaces, WithTranslation as WithNamespaces} from 'react-i18next';

interface IEthWindow extends Window {
    ethereum: any;
}

declare var window: IEthWindow;
declare var ethereum: any;

// export const options = {transactionConfirmationBlocks: 1} as Web3ModuleOptions;

interface IInstallMetaMaskProps extends WithNamespaces {
    enableMetaMaskCheck: boolean | undefined;
}

export const getWeb3 = async (): Promise<Web3 | null> => {
    if (!window.ethereum) {
        return null;
    }

    try {
        await ethereum.request({method: 'eth_requestAccounts'});
        // @ts-ignore
        return new Web3(ethereum);
        // tslint:disable-next-line: no-unused
    } catch (error) {
        return null;
    }
};

class InstallMetaMaskComponent extends Component<IInstallMetaMaskProps> {
    state = {
        isMetaMaskInstalled: true
    };

    componentDidMount = async () => {
        this.setState({isMetaMaskInstalled: await this.isMetaMaskInstalled()});
    };

    isMetaMaskInstalled = async () => {
        const web3 = await getWeb3();
        return web3 !== null;
    };

    checkIfNowInstalled = () => {
        window.location.reload(); // necessary to load web3/ethereum into window
    };

    render() {
        const {enableMetaMaskCheck, t, ...other} = this.props;

        if (enableMetaMaskCheck && !this.state.isMetaMaskInstalled) {
            return (
                <Dialog disableBackdropClick={true} open={true} onClose={this.checkIfNowInstalled} aria-labelledby="simple-dialog-title" {...other}>
                    <DialogTitle id="metamask-dialog-title">{t('InstallMetaMaskComponent.title')}</DialogTitle>

                    <DialogContent>
                        <Typography gutterBottom>{t('InstallMetaMaskComponent.p1')}</Typography>
                        <Typography gutterBottom>
                            {t('InstallMetaMaskComponent.p2')}{' '}
                            <a href="https://metamask.io/" target="_blank" rel="noopener noreferrer">
                                {t('InstallMetaMaskComponent.p3')}
                            </a>
                            .
                        </Typography>
                        <Typography>{t('InstallMetaMaskComponent.p4')}</Typography>
                    </DialogContent>

                    <DialogActions>
                        <Grid container direction="row-reverse" justify="space-between" alignItems="flex-end">
                            <Grid item>
                                <Button variant="contained" color="primary" onClick={this.checkIfNowInstalled}>
                                    {t('InstallMetaMaskComponent.check')}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button variant="contained" color="default" onClick={() => this.setState({isMetaMaskInstalled: true})}>
                                    {t('InstallMetaMaskComponent.cancel')}
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogActions>
                </Dialog>
            );
        }

        return null;
    }
}

const I18nInstallMetaMask = withNamespaces()(InstallMetaMaskComponent);
export {I18nInstallMetaMask as InstallMetaMask};
