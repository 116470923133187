import {Languages} from '../../../i18n';

export const meetingOfClaimantsData = (lang: string) => {
    if (lang === Languages.DE) {
        return [
            [
                'Sind die von mir gemachten Angaben zu meiner (Nicht-) Teilnahme bzw. derjenigen meines Mandanten an der ersten Gläubigerversammlung verbindlich?',
                'Die Informationen, die Sie uns in diesem Zusammenhang zur Verfügung stellen, dienen ausschließlich zu Informationszwecken und zur Planung und Organisation der Gläubigerversammlung.'
            ],
            [
                'Muss ich bzw. mein Mandant an der Gläubigerversammlung teilnehmen?',
                'Nein, die Teilnahme ist freiwillig, aber aus organisatorischen Gründen hilft es uns, die voraussichtliche Anzahl Teilnehmer zu kennen.'
            ],
            [
                'Wann und wo findet die erste Gläubigerversammlung statt?',
                'Die erste Gläubigerversammlung wird in der Schweiz abgehalten. Wir werden Sie zu gegebener Zeit näher informieren.'
            ],
            [
                'Ich habe Probleme mit dem Portal, was kann ich von meiner Seite aus machen?',
                'Bitte stellen Sie sicher, dass Sie Ihren Browser-Cache geleert haben, bevor Sie das Portal nutzen.'
            ]
        ];
    }

    return [
        [
            "Is the information I provide regarding my (non-)participation or that of my client(s) in the first creditors' meeting binding?",
            "The information you provide to us in this context is for informational purposes only and is intended to help plan and organize the creditors' meeting."
        ],
        [
            "Do I or my client(s) have to attend the first creditors' meeting?",
            'No, participation is voluntary, but for organisational reasons it helps us to know the expected number of participants.'
        ],
        [
            "When and where does the first creditors' meeting take place?",
            "The first creditors' meeting will be held in Switzerland. We will inform you in due course."
        ],
        ['I have issues with the portal, what can I do from my side?', 'Please insure you cleared your browser cache before using the portal.']
    ];
};
