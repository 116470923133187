import React from 'react';
import {withStyles, Grid} from '@material-ui/core';
import {IFile} from '../../store/claimants/claimants.types';
import {IAuthState} from '../../store/auth/auth.types';
import {File} from './File';

const styles = () => ({});

export interface IFilesProps {
    classes: {};
    files: IFile[];
    folder: string;
    type: string;
    auth: IAuthState;
    onDelete?(type: string, file: IFile): void;
}

class FilesComponent extends React.Component<IFilesProps> {
    render() {
        const {files, onDelete, type, folder, auth} = this.props;
        const path = `${folder}/${type}`;

        if (onDelete) {
            return (
                <Grid container>
                    {files.map((file: IFile) => (
                        <Grid item key={file.fileNameHashed} xs={12}>
                            <File onDelete={async () => onDelete(type, file)} auth={auth} file={file} folder={path} />
                        </Grid>
                    ))}
                </Grid>
            );
        }

        return (
            <Grid container>
                {files.map((file: IFile) => (
                    <Grid item key={file.fileNameHashed} xs={12}>
                        <File auth={auth} file={file} folder={path} />
                    </Grid>
                ))}
            </Grid>
        );
    }
}

const StyledFilesComponent = withStyles(styles)(FilesComponent);

export const Files = StyledFilesComponent;
