import React from 'react';
import {Dispatch} from 'redux';
import {connect} from 'react-redux';
import {withStyles, Grid, Card, CardHeader, CardContent, CardActions, Button, Typography} from '@material-ui/core';
import {styles, IClassesProperty} from '../styles';
import {RootState} from '../../../store';
import {IClaimantsState, IClaimant, ClaimantStatus, IFile} from '../../../store/claimants/claimants.types';
import {IAuthState} from '../../../store/auth/auth.types';
import {IAccountState, AppRoles} from '../../../store/account/account.types';
import {patchClaimant, uploadFile, deleteFile} from '../../../libs/api/user/user';
import {selectClaimantId as selectClaimantIdAction, updateClaimant as updateClaimantAction} from '../../../store/claimants/claimants.actions';
import {Formik, Field, FormikProps, FormikErrors} from 'formik';
import {formikSubmitHandler} from '../../../utils';
import * as Yup from 'yup';
import {DropzoneArea} from 'material-ui-dropzone';
import {Files} from '../../../components/Files';
import Helmet from 'react-helmet';
import {showSnackBar as showSnackBarAction} from '../../../store/snackbar/snackbar.actions';
import {ISnackBarState} from '../../../store/snackbar/snackbar.types';
import {withTranslation as withNamespaces, WithTranslation as WithNamespaces} from 'react-i18next';
import {setFaq as setFaqAction} from '../../../store/faq/faq.actions';
import {IFaqState} from '../../../store/faq/faq.types';

export interface IPassportStepProps extends WithNamespaces {
    classes: IClassesProperty;
    account: IAccountState;
    auth: IAuthState;
    claimants: IClaimantsState;
    selectClaimantId(claimantId: string | null): void;
    updateClaimant(claimant: IClaimant): void;
    showSnackBar(payload: ISnackBarState): void;
    setFaq(type: IFaqState): void;
}

interface IFormValues {
    passport: IFile[];
}

class ClaimantPassportStepComponent extends React.Component<IPassportStepProps> {
    _isMounted = false;

    state = {
        files: [],
        iFiles: [],
        submitting: false
    };

    validationSchema = Yup.object().shape({});

    componentDidMount() {
        this._isMounted = true;

        const initialValues = this.getInitialValuesFromClaimant();
        const {setFaq} = this.props;

        setFaq({type: ClaimantStatus.PASSPORT});
        this.setState({iFiles: initialValues.passport ? initialValues.passport : []});
    }

    componentWillUnmount = () => {
        this._isMounted = false;
    };

    setSubmitting = (submitting = true) => {
        if (this._isMounted) {
            this.setState({submitting});
        }
    };

    getClaimant = () => {
        const {claimants} = this.props;
        return claimants.selectedClaimantId ? claimants.allClaimants[claimants.selectedClaimantId] : claimants.allClaimants[claimants.byId[0]];
    };

    getInitialValuesFromClaimant = () => {
        const claimant = this.getClaimant();

        return {
            passport: claimant.passport
        };
    };

    getRole = () => {
        const {account, t} = this.props;

        if (!account.properties) {
            throw new Error(t('Roles.invalid').toString());
        }

        return account.properties.role;
    };

    showError = (error: string) => {
        this.props.showSnackBar({message: error, variant: 'error'});
    };

    onDelete = async (type: string, file: IFile) => {
        const {auth, updateClaimant, t} = this.props;

        try {
            const claimant = this.getClaimant();

            const values = {
                id: file.id || '',
                type,
                file: file.fileNameHashed,
                path: claimant.id,
                status: ClaimantStatus.PASSPORT
            };

            this.setSubmitting();

            const updatedClaimant = await deleteFile(values, auth.token);
            updateClaimant(updatedClaimant);

            this.setState({
                iFiles: updatedClaimant.passport
            });

            this.setSubmitting(false);
        } catch (error) {
            console.error(error);
            this.showError(t('FileComponent.deleteFailed').toString());
        }
    };

    handleBackClick = async () => {
        const {auth, updateClaimant, t} = this.props;
        const {files} = this.state;

        try {
            const role = this.getRole();
            const claimant = this.getClaimant();
            const claimantValues = this.getInitialValuesFromClaimant();

            let status = ClaimantStatus.PERSONAL_INFORMATION;

            if (role === AppRoles.CLAIMANT_LEGAL_REPRESENTATIVE) {
                status = ClaimantStatus.POWER_OF_ATTORNEY;
            }

            let values = {
                ...claimantValues,
                status
            };

            this.setSubmitting();

            // File upload
            if (files.length > 0) {
                const payload = new FormData();
                payload.append('claimantId', claimant.id);
                payload.append('docType', 'passport');

                files.forEach((file) => {
                    payload.append('files', file);
                });

                const uploadedFiles = await uploadFile(payload, auth.token);

                if (!uploadedFiles) {
                    throw new Error(t('Upload.failed').toString());
                }

                values = {
                    status,
                    passport: uploadedFiles
                };

                // Update claimant in DB
                if (role === AppRoles.CLAIMANT_CLAIMANT) {
                    const updatedClaimant = await patchClaimant(values, 'passport', null, auth.token);
                    updateClaimant(updatedClaimant);
                } else if (role === AppRoles.CLAIMANT_LEGAL_REPRESENTATIVE) {
                    const updatedClaimant = await patchClaimant(values, 'passport', claimant.id, auth.token);
                    updateClaimant(updatedClaimant);
                }
            }
            // tslint:disable-next-line: no-collapsible-if
            else {
                // Update claimant in DB
                if (role === AppRoles.CLAIMANT_CLAIMANT) {
                    const updatedClaimant = await patchClaimant(values, 'step-only', null, auth.token);
                    updateClaimant(updatedClaimant);
                } else if (role === AppRoles.CLAIMANT_LEGAL_REPRESENTATIVE) {
                    const updatedClaimant = await patchClaimant(values, 'step-only', claimant.id, auth.token);
                    updateClaimant(updatedClaimant);
                }
            }
            this.setSubmitting(false);
        } catch (error) {
            console.error(error);
            this.showError(t('Upload.failed').toString());
        }
    };

    onSubmit = async () => {
        const {auth, updateClaimant, t} = this.props;
        const {files} = this.state;

        try {
            const role = this.getRole();
            const claimant = this.getClaimant();
            const claimantValues = this.getInitialValuesFromClaimant();

            let values = {
                ...claimantValues,
                status: ClaimantStatus.TOKEN_PURCHASE
            };

            this.setSubmitting();

            // File upload
            if (files.length > 0) {
                const payload = new FormData();
                payload.append('claimantId', claimant.id);
                payload.append('docType', 'passport');

                files.forEach((file) => {
                    payload.append('files', file);
                });

                const uploadedFiles = await uploadFile(payload, auth.token);

                if (!uploadedFiles) {
                    throw new Error(t('Upload.failed').toString());
                }

                values = {
                    status: ClaimantStatus.TOKEN_PURCHASE,
                    passport: uploadedFiles
                };
            }

            // Update claimant in DB
            if (role === AppRoles.CLAIMANT_CLAIMANT) {
                const updatedClaimant = await patchClaimant(values, 'passport', null, auth.token);
                updateClaimant(updatedClaimant);
            } else if (role === AppRoles.CLAIMANT_LEGAL_REPRESENTATIVE) {
                const updatedClaimant = await patchClaimant(values, 'passport', claimant.id, auth.token);
                updateClaimant(updatedClaimant);
            }
            this.setSubmitting(false);
        } catch (error) {
            console.error(error);
            this.showError(t('Upload.failed').toString());
        }
    };

    validate = (_values: IFormValues) => {
        const errors: FormikErrors<IFormValues> = {};
        // tslint:disable-next-line: no-var-before-return
        return errors;
    };

    handleChange = (files: []) => {
        this.setState({
            files
        });
    };

    handleFilesRejected = (_files: []) => {
        this.props.showSnackBar({message: `${this.props.t('Upload.invalid')}.`, variant: 'error'});
    };

    mapHonorificPrefix = (value: string) => {
        const {t} = this.props;

        const mapping = {
            mr: t('ClaimantPassportStep.pmr'),
            mrs: t('ClaimantPassportStep.pmrs')
        } as any;

        return mapping[value];
    };

    getFileUploadComponent = () => {
        const {t} = this.props;
        const claimant = this.getClaimant();

        const honorificPrefix = claimant.honorificPrefix ? `${this.mapHonorificPrefix(claimant.honorificPrefix)} ` : '';
        let salutation = t('ClaimantPassportStep.claimant.dropzoneText');

        if (this.getRole() === AppRoles.CLAIMANT_LEGAL_REPRESENTATIVE) {
            salutation = t('ClaimantPassportStep.legalRep.dropzoneText', {name: `${honorificPrefix}${claimant.givenName} ${claimant.familyName}`});
        }

        // Hack to resolve issues with i18n and missing error message if file count exceed, but file size is ok
        const DZ = DropzoneArea as any;

        return (
            <div className={this.props.classes.dropZoneWrapper}>
                <DZ
                    getFileLimitExceedMessage={() => t('Upload.getFileLimitExceedMessage2')}
                    getFileAddedMessage={() => t('Upload.getFileAddedMessage')}
                    getFileRemovedMessage={() => t('Upload.getFileRemovedMessage')}
                    getDropRejectMessage={() => t('Upload.getDropRejectMessage')}
                    showAlerts={true}
                    onDropRejected={this.handleFilesRejected}
                    onChange={this.handleChange}
                    acceptedFiles={['image/*', 'application/pdf']}
                    dropzoneText={salutation.toString()}
                    filesLimit={2}
                    maxFileSize={10000000}
                    dropzoneClass={this.props.classes.dropZoneCentered}
                    showPreviewsInDropzone={false}
                    showPreviews={true}
                    dropzoneParagraphClass={this.props.classes.dropZoneParagraph}
                    showFileNamesInPreview={true}
                />
            </div>
        );
    };

    renderForm = (_props: FormikProps<IFormValues>) => {
        return <Field name="passport" label={this.props.t('Upload.label')} type="file" component={this.getFileUploadComponent} />;
    };

    render() {
        const {classes, auth, t} = this.props;
        const {files, iFiles} = this.state;

        const initialValues = this.getInitialValuesFromClaimant();
        const claimant = this.getClaimant();
        const title =
            this.getRole() === AppRoles.CLAIMANT_LEGAL_REPRESENTATIVE ? t('ClaimantPassportStep.legalRep.title') : t('ClaimantPassportStep.claimant.title');

        return (
            <React.Fragment>
                <Helmet>
                    <title>{title}</title>
                </Helmet>

                <Card className={classes.card}>
                    <CardHeader
                        classes={{
                            root: classes.cardHeader,
                            title: classes.cardHeaderTitle
                        }}
                        title={title}
                        style={{textAlign: 'center'}}
                    />

                    <CardContent>
                        {files.length > 0 && iFiles.length > 0 ? <Typography className={classes.warning}>{t('Upload.replaceInfo')}!</Typography> : null}
                        {files.length === 0 && iFiles.length > 0 ? (
                            <Files type="passport" onDelete={this.onDelete} auth={auth} files={iFiles} folder={claimant.id} />
                        ) : null}

                        <Formik
                            initialValues={initialValues}
                            validate={this.validate}
                            validationSchema={this.validationSchema}
                            onSubmit={formikSubmitHandler(this.onSubmit)}
                            enableReinitialize={true}
                        >
                            {this.renderForm}
                        </Formik>
                    </CardContent>

                    <CardActions>
                        <Grid container justify="space-between">
                            <Grid item>
                                <Button disabled={this.state.submitting} onClick={this.handleBackClick} type="button" color="default" variant="contained">
                                    {t('Navigation.back')}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    onClick={this.onSubmit}
                                    disabled={this.state.submitting || (iFiles.length === 0 && files.length === 0)}
                                    type="submit"
                                    color="primary"
                                    variant="contained"
                                >
                                    {t('Navigation.next')}
                                </Button>
                            </Grid>
                        </Grid>
                    </CardActions>
                </Card>
            </React.Fragment>
        );
    }
}

const StyledClaimantPassportStepComponent = withStyles(styles)(ClaimantPassportStepComponent);
const I18nClaimantPassportStepComponent = withNamespaces()(StyledClaimantPassportStepComponent);

const mapStateToProps = ({auth, account, claimants}: RootState) => ({
    auth,
    account,
    claimants
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    updateClaimant: (claimant: IClaimant) => dispatch(updateClaimantAction(claimant)),
    selectClaimantId: (claimantId: string) => dispatch(selectClaimantIdAction(claimantId)),
    showSnackBar: (payload: ISnackBarState) => dispatch(showSnackBarAction(payload)),
    setFaq: (type: IFaqState) => dispatch(setFaqAction(type))
});

export const ClaimantPassportStep = connect(mapStateToProps, mapDispatchToProps)(I18nClaimantPassportStepComponent);
