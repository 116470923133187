import React, {Component} from 'react';
import {Helmet} from 'react-helmet';
import {withTranslation as withNamespaces, WithTranslation as WithNamespaces} from 'react-i18next';
import {withRouter, RouteComponentProps, Redirect} from 'react-router';
import {Dispatch} from 'redux';
import {connect} from 'react-redux';
import {RootState} from '../../store';
import {IAccountState} from '../../store/account/account.types';
import {logout as logoutAction} from '../../store/auth/auth.actions';
import {IAuthState} from '../../store/auth/auth.types';
import {verifyEmailAddress as verifyEmailAddressAction} from '../../store/account/account.actions';
import * as queryString from 'query-string';
import {Property} from 'csstype';
import {Theme} from '@material-ui/core/styles/createMuiTheme';
import {withStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import {LinkButton} from '../../components/LinkButton';
import {Grid} from '@material-ui/core';
import {ReadOnly} from '../../components/ReadOnly';
import getConfig from '../../config';

import CardMedia from '@material-ui/core/CardMedia';
import logo from '../../images/logo-cut.png';

const styles = (theme: Theme) => ({
    media: {
        height: 80,
        backgroundSize: 'auto 50px',
        marginTop: 20
    },
    card: {
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: '500px',
        [theme.breakpoints.up('sm')]: {
            marginTop: '5vh'
        }
    },
    textField: {
        width: '100%'
    },
    progressContainer: {
        display: 'flex',
        flexDirection: 'column' as Property.FlexDirection,
        alignItems: 'center',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(4)
    }
});

export interface IVerifyEmailAddressComponentProps extends WithNamespaces {
    classes: {
        media: string;
        card: string;
        textField: string;
        progressContainer: string;
    };
    accountState: IAccountState;
    authState: IAuthState;
    verifyEmailAddress(id: string): void;
    logout(): void;
}

class VerifyEmailAddressComponent extends Component<IVerifyEmailAddressComponentProps & RouteComponentProps> {
    componentDidMount() {
        const {verifyEmailAddress, logout} = this.props;
        const ticketId = this.getTicketId();

        if (!ticketId) {
            return;
        }

        try {
            verifyEmailAddress(ticketId);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                logout();
            }
            // @TODO: Display error message
        }
    }

    getTicketId(): string | null {
        const {id} = queryString.parse(this.props.location.search);

        if (typeof id === 'string') {
            return id;
        }

        return null;
    }

    renderContent() {
        const {classes, accountState, authState, t} = this.props;

        if (accountState.isFetching) {
            return (
                <div className={classes.progressContainer}>
                    <CircularProgress />
                </div>
            );
        }

        if (accountState.properties && accountState.properties.emailVerified) {
            return (
                <React.Fragment>
                    <Typography paragraph>{t('VerifyEmailAddress.success.content', {email: accountState.properties.email})}</Typography>

                    <Grid container direction="row-reverse" justify="space-between" alignItems="flex-end" style={{marginTop: 20}}>
                        <Grid item>
                            <LinkButton variant="contained" color="primary" to="/">
                                {t('VerifyEmailAddress.success.submitButton')}
                            </LinkButton>
                        </Grid>
                    </Grid>
                </React.Fragment>
            );
        }

        if (authState.isAuthenticated) {
            return (
                <React.Fragment>
                    <Typography paragraph>{t('VerifyEmailAddress.pending.p1')}</Typography>
                    <Typography>{t('VerifyEmailAddress.pending.p2')}</Typography>
                </React.Fragment>
            );
        }

        return <Redirect to="/" />;
    }

    render() {
        const {classes, t} = this.props;

        if (getConfig.APP_READONLY_MODE) {
            return <ReadOnly />;
        }

        return (
            <React.Fragment>
                <Helmet>
                    <title>{t('VerifyEmailAddress.title')}</title>
                </Helmet>

                <Card className={classes.card}>
                    <CardMedia className={classes.media} image={logo} />
                    <CardHeader titleTypographyProps={{align: 'center'}} title={t('VerifyEmailAddress.title')} />
                    <CardContent>{this.renderContent()}</CardContent>
                </Card>
            </React.Fragment>
        );
    }
}

const StyledVerifyEmailAddressComponent = withStyles(styles)(VerifyEmailAddressComponent);

const I18nVerifyEmailAddressComponent = withNamespaces()(StyledVerifyEmailAddressComponent);

const mapStateToProps = ({account, auth}: RootState) => ({
    accountState: account,
    authState: auth
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    verifyEmailAddress: (token: string) => dispatch(verifyEmailAddressAction(token)),
    logout: () => dispatch(logoutAction())
});

export const VerifyEmailAddress = withRouter(connect(mapStateToProps, mapDispatchToProps)(I18nVerifyEmailAddressComponent));
