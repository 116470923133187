import {Reducer, AnyAction} from 'redux';
import {AccountActionTypes, IAccountState, AccountProperties, IAccountProperties} from './account.types';

const initialState: IAccountState = {
    isFetching: false,
    properties: null
};

const properties = (state: AccountProperties = null, action: AnyAction): AccountProperties => {
    switch (action.type) {
        case AccountActionTypes.GET_RESPONSE:
            const account: IAccountProperties = action.payload.user;

            return {
                email: account.email,
                emailVerified: account.emailVerified,
                hasAcceptedLegalNotice: account.hasAcceptedLegalNotice,
                lastLogin: account.lastLogin,
                role: account.role,
                legalRepresentativeDetails: account.legalRepresentativeDetails
            };
        case AccountActionTypes.VERIFY_EMAIL_RESPONSE:
            return {...state, ...action.payload.user};
        case AccountActionTypes.APPLY_FORM_RESULT:
            return {...state, ...action.payload};
        default:
            return state;
    }
};

const isFetching = (state = false, action: AnyAction): boolean => {
    switch (action.type) {
        case AccountActionTypes.GET:
        case AccountActionTypes.GET_REQUEST:
        case AccountActionTypes.VERIFY_EMAIL_REQUEST:
            return true;
        case AccountActionTypes.GET_RESPONSE:
        case AccountActionTypes.GET_ERROR:
        case AccountActionTypes.VERIFY_EMAIL_RESPONSE:
        case AccountActionTypes.VERIFY_EMAIL_ERROR:
            return false;
        default:
            return state;
    }
};

export const accountReducer: Reducer<IAccountState> = (state = initialState, action) => ({
    isFetching: isFetching(state.isFetching, action),
    properties: properties(state.properties, action)
});
