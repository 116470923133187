import React from 'react';
import {
    withStyles,
    Grid,
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Button,
    Typography,
    Radio,
    FormControlLabel,
    FormControl,
    RadioGroup
} from '@material-ui/core';
import {withTranslation as withNamespaces, WithTranslation as WithNamespaces} from 'react-i18next';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {RootState} from '../../../store';
import {IClaimantsState, ClaimantStatus, IClaimant} from '../../../store/claimants/claimants.types';
import {IAuthState} from '../../../store/auth/auth.types';
import {selectClaimantId as selectClaimantIdAction, updateClaimant as updateClaimantAction} from '../../../store/claimants/claimants.actions';
import {TextArea} from '../../../components/TextArea';
import {Form, Formik, Field, FormikProps, FormikValues, FormikErrors} from 'formik';
import * as Yup from 'yup';
import {formikSubmitHandler, ConvertValidationErrorsFunction} from '../../../utils';

import {styles, IClassesProperty} from '../styles';
import {IAccountState, AppRoles} from '../../../store/account/account.types';
import {patchClaimant} from '../../../libs/api/user/user';
import Helmet from 'react-helmet';
import {MetaMaskSection} from './MetaMaskSection';
import {setFaq as setFaqAction} from '../../../store/faq/faq.actions';
import {IFaqState} from '../../../store/faq/faq.types';
import {showSnackBar as showSnackBarAction} from '../../../store/snackbar/snackbar.actions';
import {ISnackBarState} from '../../../store/snackbar/snackbar.types';

export interface INoTokenFormValues {
    reason: string;
    sendTokensChoice: string;
}

export interface ISendTokensStepProps extends WithNamespaces {
    classes: IClassesProperty;
    account: IAccountState;
    auth: IAuthState;
    claimants: IClaimantsState;
    updateClaimant(claimant: IClaimant): void;
    selectClaimantId(claimantId: string | null): void;
    setFaq(type: IFaqState): void;
    showSnackBar(payload: ISnackBarState): void;
}

export interface ISendTokensStepState {
    noTokensDialogOpen: boolean;
    tokenBalance: string;
    depositBalance: string;
    sendingTokens: boolean;
    symbol: string;
    sendTokensChoice: string;
    submitting: boolean;
    enableMetaMaskCheck: boolean;
}

class SendTokensStepComponent extends React.Component<ISendTokensStepProps, ISendTokensStepState> {
    _isMounted = false;

    state = {
        noTokensDialogOpen: false,
        tokenBalance: '0',
        depositBalance: '0',
        sendingTokens: false,
        symbol: '-',
        sendTokensChoice: 'undefined',
        submitting: false,
        enableMetaMaskCheck: false
    };

    reason = '';

    validationSchema = Yup.object().shape({});

    componentDidMount = async () => {
        this._isMounted = true;

        const claimant = this.getClaimant();
        const {setFaq} = this.props;

        setFaq({type: ClaimantStatus.SEND_TOKENS});

        // tslint:disable-next-line: early-exit
        if (claimant.sendTokensChoice) {
            this.setState({sendTokensChoice: claimant.sendTokensChoice});
            if (claimant.sendTokensChoice === 'metamask') {
                this.setState({enableMetaMaskCheck: true});
            }
            if (claimant.sendTokensChoice === 'noTokens') {
                this.setState({noTokensDialogOpen: true});
                this.reason = claimant.reason;
            }
        }
    };

    componentWillUnmount = () => {
        this._isMounted = false;
    };

    setSubmitting = (submitting = true) => {
        if (this._isMounted) {
            this.setState({submitting});
        }
    };

    getExplanationText = () => {
        const {t} = this.props;

        if (this.getRole() === AppRoles.CLAIMANT_CLAIMANT) {
            return `${t('SendTokensStep.claimant.reasonLabel')}.`;
        }
        return `${t('SendTokensStep.legalRep.reasonLabel')}.`;
    };

    getInitialValuesFromClaimantDialog = () => {
        const claimant = this.getClaimant();

        return {
            reason: claimant.reason || '',
            depositWalletAddress: this.getDepositAddress() || '',
            sendTokensChoice: this.state.sendTokensChoice || 'undefined'
        };
    };

    getRole = () => {
        const {account, t} = this.props;

        if (!account.properties) {
            throw new Error(t('Roles.invalid').toString());
        }

        return account.properties.role;
    };

    getDepositAddress() {
        const claimant = this.getClaimant();

        if (!claimant.generatedWallets || claimant.generatedWallets.length === 0) {
            return null;
        }

        return claimant.generatedWallets[0].ethereumAddress;
    }

    showError = (error: string) => {
        this.props.showSnackBar({message: error, variant: 'error'});
    };

    handleNoTokenSubmit = async (values: FormikValues) => {
        const {auth, updateClaimant, t} = this.props;

        try {
            const role = this.getRole();
            const claimant = this.getClaimant();

            const _values: FormikValues = {
                ...values,
                // sendMail: true,
                hideSendTokenStep: true,
                status: ClaimantStatus.REVIEW,
                sendTokensChoice: this.state.sendTokensChoice
            };

            this.setSubmitting();

            if (role === AppRoles.CLAIMANT_CLAIMANT) {
                const updatedClaimant = await patchClaimant(_values, 'send-no-tokens', null, auth.token);
                updateClaimant(updatedClaimant);
            } else if (role === AppRoles.CLAIMANT_LEGAL_REPRESENTATIVE) {
                const updatedClaimant = await patchClaimant(_values, 'send-no-tokens', claimant.id, auth.token);
                updateClaimant(updatedClaimant);
            }
            this.setSubmitting(false);
        } catch (error) {
            console.error(error);
            this.showError(t('Form.failed').toString());
        }
    };

    handleBackClick = async () => {
        const {auth, updateClaimant, t} = this.props;

        try {
            const role = this.getRole();
            const claimant = this.getClaimant();

            const _values = {
                reason: this.reason,
                status: ClaimantStatus.SIGN_CLAIMS_FORM,
                sendTokensChoice: this.state.sendTokensChoice
            };

            this.setSubmitting();

            if (role === AppRoles.CLAIMANT_CLAIMANT) {
                try {
                    const updatedClaimant = await patchClaimant(_values, 'send-tokens', null, auth.token);
                    updateClaimant(updatedClaimant);
                } catch (error) {
                    console.error(error);
                    this.showError(t('Form.failed').toString());
                }
            } else if (role === AppRoles.CLAIMANT_LEGAL_REPRESENTATIVE) {
                try {
                    const updatedClaimant = await patchClaimant(_values, 'send-tokens', claimant.id, auth.token);
                    updateClaimant(updatedClaimant);
                } catch (error) {
                    console.error(error);
                    this.showError(t('Form.failed').toString());
                }
            }
            this.setSubmitting(false);
        } catch (error) {
            console.error(error);
            this.showError(t('Form.failed').toString());
        }
    };

    handleComplete = async () => {
        const {auth, updateClaimant, t} = this.props;

        try {
            const role = this.getRole();
            const claimant = this.getClaimant();

            const _values = {
                reason: '',
                // sendMail: true,
                hideSendTokenStep: true,
                status: ClaimantStatus.REVIEW,
                sendTokensChoice: this.state.sendTokensChoice
            };

            this.setSubmitting();

            if (role === AppRoles.CLAIMANT_CLAIMANT) {
                try {
                    const updatedClaimant = await patchClaimant(_values, 'send-tokens', null, auth.token);
                    updateClaimant(updatedClaimant);
                } catch (error) {
                    console.error(error);
                    this.showError(t('Form.failed').toString());
                }
            } else if (role === AppRoles.CLAIMANT_LEGAL_REPRESENTATIVE) {
                try {
                    const updatedClaimant = await patchClaimant(_values, 'send-tokens', claimant.id, auth.token);
                    updateClaimant(updatedClaimant);
                } catch (error) {
                    console.error(error);
                    this.showError(t('Form.failed').toString());
                }
            }
            this.setSubmitting(false);
        } catch (error) {
            console.error(error);
            this.showError(t('Form.failed').toString());
        }
    };

    getClaimant = () => {
        const {claimants} = this.props;
        return claimants.selectedClaimantId ? claimants.allClaimants[claimants.selectedClaimantId] : claimants.allClaimants[claimants.byId[0]];
    };

    validate = (values: INoTokenFormValues) => {
        const errors: FormikErrors<INoTokenFormValues> = {};

        if (values.reason.length === 0 || values.reason.length >= 255) {
            errors.reason = this.getExplanationText();
        }

        return errors;
    };

    stripFirstWord = (str: string) => {
        return str.substr(str.indexOf(' ') + 1);
    };

    convertValidationErrors: ConvertValidationErrorsFunction<FormikValues> = (validationErrors) => {
        return validationErrors.reduce<any>((result, validationError) => {
            const constrainKeys = Object.keys(validationError.constraints);

            if (constrainKeys.length > 1) {
                result[validationError.property] = (
                    <React.Fragment>
                        <ul style={{paddingLeft: 15, marginTop: 0}}>
                            {constrainKeys.map((constrainKey) => (
                                <li key={constrainKey}>{this.stripFirstWord(validationError.constraints[constrainKey])}.</li>
                            ))}
                        </ul>
                    </React.Fragment>
                );
            } else if (constrainKeys.length === 1) {
                result[validationError.property] = `${this.stripFirstWord(validationError.constraints[constrainKeys[0]])}.`;
            }

            return result;
        }, {});
    };

    updateReasonState = (props: FormikProps<INoTokenFormValues>) => {
        this.reason = props.values.reason;
    };

    NoTokensForm = (props: FormikProps<INoTokenFormValues>) => {
        if (!this.state.noTokensDialogOpen) {
            return null;
        }

        return (
            <Form>
                <CardContent>
                    <Field
                        onChange={this.updateReasonState(props)}
                        name="reason"
                        label={this.getExplanationText()}
                        err={this.getExplanationText()}
                        component={TextArea}
                    />
                </CardContent>
                <CardActions>
                    <Grid container justify="space-between">
                        <Grid item>
                            <Button onClick={this.handleBackClick} disabled={this.state.submitting} type="button" color="default" variant="contained">
                                {this.props.t('Navigation.back')}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button disabled={this.state.submitting || !props.isValid} type="submit" color="primary" variant="contained">
                                {this.props.t('Navigation.finish')}
                            </Button>
                        </Grid>
                    </Grid>
                </CardActions>
            </Form>
        );
    };

    renderDepositAddressSection(noTokens: boolean) {
        const {t} = this.props;

        return (
            <React.Fragment>
                <CardContent>
                    {this.state.sendTokensChoice !== 'noTokens' ? (
                        this.getRole() === AppRoles.CLAIMANT_LEGAL_REPRESENTATIVE ? (
                            <Typography paragraph>{t('SendTokensStep.legalRep.different')}</Typography>
                        ) : (
                            <Typography paragraph>{t('SendTokensStep.claimant.different')}</Typography>
                        )
                    ) : this.getRole() === AppRoles.CLAIMANT_LEGAL_REPRESENTATIVE ? (
                        <Typography paragraph>{t('SendTokensStep.legalRep.noAccessToTokens')}</Typography>
                    ) : (
                        <Typography paragraph>{t('SendTokensStep.claimant.noAccessToTokens')}</Typography>
                    )}

                    <Typography paragraph>
                        <b>{t('SendTokensStep.remark1')}:</b> {t('SendTokensStep.remark2')}
                    </Typography>

                    <Typography variant="h6" style={{textAlign: 'center', marginTop: 30}}>
                        {t('SendTokensStep.depositAddress')}: {this.getDepositAddress() || t('SendTokensStep.noDepositAddress')}
                    </Typography>
                </CardContent>

                {noTokens ? (
                    <Formik
                        initialValues={this.getInitialValuesFromClaimantDialog()}
                        validate={this.validate}
                        validationSchema={this.validationSchema}
                        onSubmit={formikSubmitHandler(this.handleNoTokenSubmit, {convertValidationErrors: this.convertValidationErrors})}
                        enableReinitialize={true}
                    >
                        {this.NoTokensForm}
                    </Formik>
                ) : (
                    <CardActions>
                        <Grid container justify="space-between">
                            <Grid item>
                                <Button onClick={this.handleBackClick} disabled={this.state.submitting} type="button" color="default" variant="contained">
                                    {t('Navigation.back')}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button disabled={this.state.submitting} onClick={this.handleComplete} color="primary" variant="contained">
                                    {t('Navigation.finish')}
                                </Button>
                            </Grid>
                        </Grid>
                    </CardActions>
                )}
            </React.Fragment>
        );
    }

    handleRadioChange = (event: React.ChangeEvent<unknown>) => {
        this.setState({
            sendTokensChoice: (event.target as HTMLInputElement).value,
            enableMetaMaskCheck: (event.target as HTMLInputElement).value === 'metamask',
            noTokensDialogOpen: (event.target as HTMLInputElement).value !== 'metamask'
        });
    };

    renderRadioForm() {
        const {t} = this.props;
        const role = this.getRole();

        const metamaskLabel = role === AppRoles.CLAIMANT_CLAIMANT ? t('SendTokensStep.claimant.radio.metamask') : t('SendTokensStep.legalRep.radio.metamask');
        const differentLabel =
            role === AppRoles.CLAIMANT_CLAIMANT ? t('SendTokensStep.claimant.radio.different') : t('SendTokensStep.legalRep.radio.different');
        const noTokensLabel = role === AppRoles.CLAIMANT_CLAIMANT ? t('SendTokensStep.claimant.radio.noTokens') : t('SendTokensStep.legalRep.radio.noTokens');

        return (
            <React.Fragment>
                <FormControl style={{margin: 20}}>
                    <RadioGroup aria-label="gender" name="sendTokensChoice" value={this.state.sendTokensChoice} onChange={this.handleRadioChange}>
                        {role === AppRoles.CLAIMANT_CLAIMANT ? (
                            <FormControlLabel
                                value="metamask"
                                control={<Radio checked={this.state.sendTokensChoice === 'metamask'} />}
                                label={`${metamaskLabel}.`}
                            />
                        ) : null}

                        <FormControlLabel
                            value="different"
                            control={<Radio checked={this.state.sendTokensChoice === 'different'} />}
                            label={`${differentLabel}.`}
                        />
                        <FormControlLabel
                            value="noTokens"
                            control={<Radio checked={this.state.sendTokensChoice === 'noTokens'} />}
                            label={`${noTokensLabel}.`}
                        />
                    </RadioGroup>
                </FormControl>
                {this.state.sendTokensChoice === 'undefined' ? (
                    <CardActions>
                        <Grid container justify="space-between">
                            <Grid item>
                                <Button onClick={this.handleBackClick} disabled={this.state.submitting} type="button" color="default" variant="contained">
                                    {t('Navigation.back')}
                                </Button>
                            </Grid>
                        </Grid>
                    </CardActions>
                ) : null}
            </React.Fragment>
        );
    }

    renderContent() {
        switch (this.state.sendTokensChoice) {
            case 'metamask':
                const depositAddress = this.getDepositAddress();

                if (!depositAddress) {
                    throw new Error(this.props.t('SendTokensStep.invalidDepositAddress').toString());
                }

                return (
                    <MetaMaskSection
                        enableMetaMaskCheck={this.state.enableMetaMaskCheck}
                        submitting={this.state.submitting}
                        depositWalletAddress={depositAddress}
                        handleBackClick={this.handleBackClick}
                        handleComplete={this.handleComplete}
                    />
                );
            case 'different':
                return this.renderDepositAddressSection(false);
            case 'noTokens':
                return this.renderDepositAddressSection(true);
            default:
                return null;
        }
    }

    render() {
        const {classes, t} = this.props;

        return (
            <React.Fragment>
                <Helmet>
                    <title>{t('SendTokensStep.title')}</title>
                </Helmet>

                <Card className={classes.card}>
                    <CardHeader
                        classes={{
                            root: classes.cardHeader,
                            title: classes.cardHeaderTitle
                        }}
                        title={t('SendTokensStep.title')}
                        style={{textAlign: 'center'}}
                    />
                    {this.renderRadioForm()}
                    {this.renderContent()}
                </Card>
            </React.Fragment>
        );
    }
}

const StyledSendTokensStepComponent = withStyles(styles)(SendTokensStepComponent);

const I18nSendTokensStepComponent = withNamespaces()(StyledSendTokensStepComponent);

const mapStateToProps = ({auth, account, claimants}: RootState) => ({
    auth,
    account,
    claimants
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    updateClaimant: (claimant: IClaimant) => dispatch(updateClaimantAction(claimant)),
    selectClaimantId: (claimantId: string) => dispatch(selectClaimantIdAction(claimantId)),
    showSnackBar: (payload: ISnackBarState) => dispatch(showSnackBarAction(payload)),
    setFaq: (type: IFaqState) => dispatch(setFaqAction(type))
});

export const SendTokensStep = connect(mapStateToProps, mapDispatchToProps)(I18nSendTokensStepComponent);
